<div class="pre-auth-container">    
    <div class="pre-auth-header">
        <h4>Go Live</h4>
    </div>
    <mat-divider></mat-divider>
    <div class="pre-auth-content">
        <p>By clicking the 'Go Live' button you will redirect to the payment page. You will be charged an amount of 0.01€ as authentication. The amount will be refunded and your account will be Live.</p>
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="goLive()" cdkFocusInitial>Go Live</button>
    </div>
</div>