import { Component, OnInit, OnDestroy } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { DataService } from './services/data.service'
import { ProductsService } from './services/products.service'
import { CategoriesService } from './services/categories.service'
import { TaxesService } from './services/taxes.service'
import { StoresService } from './services/stores.service'
import { DevicesService } from './services/devices.service'
import { EmployeesService } from './services/employees.service'
import { ExtensionsService } from './services/extensions.service'
import { CurrenciesService } from './services/currencies.service'
import { InvoiceService } from './services/invoices.service'
import { SubscriptionService } from './services/subscriptions.service'
import { AddonsService } from './services/addons.service'
import { AuthService } from './services/auth.service'
import { User } from './model/user.model'
import { CustomersService } from './services/customers.service'
import { CustomerGroupsService } from './services/customer-groups.service'
import { TranslateService } from './services/translate.service'
import { MetadataService } from './services/metadata.service'
import { CountrSDKService } from './services/countr-sdk.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { EventService } from './services/event.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private userSubscription: any
  private runningFetch: boolean

  constructor(
    private titleService: Title,
    private data: DataService,
    private auth: AuthService,
    private products: ProductsService,
    private categories: CategoriesService,
    private taxes: TaxesService,
    private stores: StoresService,
    private devices: DevicesService,
    private employees: EmployeesService,
    private customers: CustomersService,
    private customerGroups: CustomerGroupsService,
    private extensions: ExtensionsService,
    private invoices: InvoiceService,
    private subscriptions: SubscriptionService,
    private addons: AddonsService,
    private translate: TranslateService,
    private payment_method: MetadataService,
    private countrSDK: CountrSDKService,
    private snackBar: MatSnackBar,
    private event: EventService,
    private currencies: CurrenciesService
  ) {
    this.runningFetch = false
  }

  ngOnInit() {
    this.data.initDataService()
    this.translate.initTranslateService()

    if (this.auth.isAuth()) {
      if (!this.runningFetch) {
        this.fetchServices()
        this.initNotificationListener()
      }
    } else {
      this.userSubscription = this.auth.getUserEvent().subscribe((user: User) => {
        if (user) {
          if (!this.runningFetch) {
            this.fetchServices()
            this.initNotificationListener()
          }
        }
      })
    }
  }

  ngOnDestroy() {
    this.removeNotificationListener()
  }

  fetchServices() {
    this.runningFetch = true
    // Fetch all api that we need here
    this.products.fetchProducts()
    this.products.findAllProductsCount()
    this.categories.fetchCategories()
    this.taxes.fetchTaxes()
    this.stores.fetchStores()
    this.devices.fetchDevices()
    this.employees.fetchEmployees()
    this.customers.fetchCustomers()
    this.customerGroups.fetchCustomerGroups()
    this.extensions.fetchExtensions()
    this.extensions.fetchGetAllExtension()
    this.invoices.fetchInvoices()
    this.subscriptions.fetchSubscriptions()
    this.addons.fetchAddons()
    this.payment_method.fetchMetadata()
    this.currencies.fetchCurrencies()
  }

  async initNotificationListener() {
    console.log('initNotificationListener')

    const countr = await this.countrSDK.getCountrSdk()
    const access = this.auth.getToken()
    const refresh = this.auth.getRefreshToken()
    countr.setToken({
      access_token: access,
      refresh_token: refresh
    })

    const user = this.auth.getUser()

    // Setting <head><title></title></head> with account name
    const title = user.username.split('@')[0] + ' - Countr Dashboard'
    this.titleService.setTitle(title)

    // Register merchant listener notification
    countr.ws.on(`m${user._id}:notification`, data => {
      console.log(
        '🚀 ~ file: app.component.ts ~ line 123 ~ AppComponent ~ countr.ws.on ~ data',
        data
      )
      if (data && data.message) {
        if (data.message.source) {
          console.log(
            '🚀 ~ file: app.component.ts ~ line 126 ~ AppComponent ~ countr.ws.on ~ data.message.source',
            data.message.source
          )
          const type = data.message.source

          if (type === 'export' || type === 'importer') {
            console.log(
              '🚀 ~ file: app.component.ts ~ line 130 ~ AppComponent ~ countr.ws.on ~ type',
              type
            )
            const translated = this.translate.data[data.message.i18n]
            let msg =
              data.message.data.htmlerrors ||
              (translated
                ? translated.concat(' ' + data.message.data[type === 'export' ? 'link' : 'total'])
                : data.message.data[type === 'export' ? 'link' : 'total'])

            const action = type === 'export' ? 'Download' : 'Close'

            if (type === 'importer') {
              msg = `${this.translate.data['import_file_finished']} ${data.message.data['total']}`
            }

            const toast = this.snackBar.open(msg, action, { duration: 250000 })

            type === 'export' &&
              toast.onAction().subscribe(() => {
                this.downloadFile(data.message.data['link'])
              })
          } else if (type === 'stock-importer') {
            const translated = this.translate.data[data.message.i18n]
            const action = 'Close'
            const toast = this.snackBar.open(translated, action, { duration: 250000 })
          }
        } else {
          this.snackBar.open(data.message, 'Close', { duration: 5000 })
        }
      }
    })
  }

  downloadFile(data) {
    window.open(data, '_blank')
  }

  async removeNotificationListener() {
    const countr = await this.countrSDK.getCountrSdk()
    const user = this.auth.getUser()

    // Removing merchant listener notification
    countr.ws.off(`m${user._id}:notification`, () => {})
  }
}
