import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Scope, Voucher } from './../model/voucher.model';

@Injectable()
export class VouchersService {
  private vouchers: Voucher[]
  private count: number

  constructor(private request: RequestService) {}

  fetchVouchers(): Promise<Voucher[]> {
    return this.request.getResource('coupons', { limit: 100 }).then((vouchers: Voucher[]) => {
      this.vouchers = vouchers
      this.count = vouchers.length
      return this.vouchers
    })
  }

  getVouchers(): Voucher[] {
    return this.vouchers
  }

  getVoucher(id: string): Promise<Voucher> {
    return this.request.getResource(`coupons/${id}`) as Promise<Voucher>
  }

  setVoucher(voucher: Voucher): Promise<any> {
    return this.request.postResource('coupons', voucher)
  }

  deleteVoucher(id: string) {
    return this.request.deleteResource('coupons/' + id)
  }

  sendVoucherEmail(id: string, email: string) {
    return this.request.postResource(`coupons/${id}/send`, {value: email, type: 'email'})
  }

  createVoucher(): Voucher {
    return {
      __t: 'Coupon',
      categories: [],
      products: [],
      variants: [],
      activity: [],
      discount: {},
      identifiers: [],
      is_active: true,
      scope: Scope.CART,
      start_date: new Date(),
      expiry_date: new Date(),
      title: '',
      total_uses: 1,
      uses_remaining: 1
    }
  }
}
