import { Component, OnInit, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestService } from './../../../../services/request.service'

@Component({
    selector: 'go-live-pre-auth-modal',
    templateUrl: 'go-live-pre-auth-modal.component.html'
  })

  export class GoLivePreAuthModalComponent implements OnInit {
    public isRequested: boolean
    constructor(
        public dialogRef: MatDialogRef<GoLivePreAuthModalComponent>,
        private request: RequestService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
      this.isRequested = false 
    }

    ngOnInit() {}
    goLive() {
      let extension = this.data
      this.request
      .postResource('me/molliepaylive', {
        redirectUrl: location.toString() + '/?checkPayment=true',
        extension: extension
      })
      .then((payment: any) => {
        if (payment && payment['links'] != undefined) {
          setTimeout(() => location.assign(payment.links.paymentUrl), 1000)
        } else {
          location.reload()
        }
      })
    }

    onNoClick(): void {
      this.dialogRef.close()
    }
  }
