import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { AuthService } from './auth.service'
import { Report } from './../model/report.model'

@Injectable()
export class ReportsService {
  private report: Report

  constructor(private request: RequestService, private authService: AuthService) {}
}
