<h1 mat-dialog-title>Select the package that suits you and <span class="color">GET STARTED</span> selling!</h1>
<div mat-dialog-content>
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="5px" fxLayoutAlign="space-around center">
        <div fxFlex="30" *ngFor="let extension of extensions">
            <div class="pricing">
                <mat-card>
                    <div class="pricing featured">
                    <div class="listing-badges" *ngIf="extension.slug !== 'live-advanced-transaction'">
                        <span class="featured">* €{{ extension.price.month }}</span>
                    </div>
                    <mat-card-header>
                        <mat-card-title>
                            {{ extension.name }}
                        </mat-card-title>
                        <mat-card-subtitle>
                            {{ extension.infos.short }}
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="content">
                            <h4>Description:</h4>
                            <p>{{ extension.infos.description }}</p>
                            <span class="small-font-size">* €{{ extension.price.month }} per Month and per Location!</span>
                        </div>
                    </mat-card-content>
                    <mat-card-actions>
                        <div class="get-started-button"><button class="btn btn-outline pricing-btn" (click)="setSelectedExtension(extension)">Get Started</button></div>
                    </mat-card-actions>
                </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()">Cancel</button>
</div>