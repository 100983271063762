<mat-card class="period-selection">
  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center" class="mat-form-field title">
    <div fxFlex.gt-xs="50" *ngIf="outstandingPayment">
      <mat-card-title>{{ 'select_period' | translate }}</mat-card-title>
    </div>
    <div fxFlex.gt-xs="50">
      <mat-chip-list>
        <mat-chip [selected]="period === 'day'" class="clickable" (click)="changePeriod('day')">{{
          'day' | translate
        }}</mat-chip>
        <mat-chip
          [selected]="period === 'week'"
          class="clickable"
          (click)="changePeriod('week')"
          *ngIf="!isBasicAccount && !outstandingPayment"
          >{{ 'week' | translate }}</mat-chip
        >
        <mat-chip
          [selected]="period === 'month'"
          class="clickable"
          (click)="changePeriod('month')"
          *ngIf="!isBasicAccount && !outstandingPayment"
          >{{ 'month' | translate }}</mat-chip
        >
        <!-- <mat-chip [selected]="period === 'quarter'" class="clickable" (click)="changePeriod('quarter')">Quarter</mat-chip> -->
        <!-- <mat-chip [selected]="period === 'year'" class="clickable" (click)="changePeriod('year')">Year</mat-chip> -->
        <mat-chip
          [selected]="period === 'specific'"
          class="clickable"
          (click)="changePeriod('specific')"
          *ngIf="!isBasicAccount && !outstandingPayment"
          >{{ 'specific' | translate }}</mat-chip
        >
      </mat-chip-list>
    </div>
  </div>
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
      <div fxFlex.gt-xs="50">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'date_start' | translate }}"
            [matDatepicker]="startPicker"
            [max]="maxDate"
            ngModel
            name="start-date"
            (dateInput)="changeInputDate('start', $event)"
            [value]="startDate"
            (click)="startPicker.open()"
            readonly
          />
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex.gt-xs="50">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'date_end' | translate }}"
            [matDatepicker]="endPicker"
            [max]="maxDate"
            ngModel
            name="end-date"
            (dateInput)="changeInputDate('end', $event)"
            [value]="endDate"
            [disabled]="period !== 'specific'"
            (click)="endPicker.open()"
            readonly
          />
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutWrap="wrap"
      fxLayoutGap="15px"
      fxLayoutAlign="space-between center"
      *ngIf="showTime && period === 'specific'"
      class="m-b-10"
    >
      <div fxFlex.gt-xs="48" *ngIf="!disableStartTime">
        <ngx-timepicker-field
          [format]="24"
          [(ngModel)]="startTime"
          (ngModelChange)="onChangeTime('start', $event)"
          class="timepicker"
          [disabled]="disableStartTime"
        >
        </ngx-timepicker-field>
      </div>
      <div fxFlex.gt-xs="48" *ngIf="!disableEndTime">
        <ngx-timepicker-field
          [format]="24"
          [(ngModel)]="endTime"
          (ngModelChange)="onChangeTime('end', $event)"
          class="timepicker"
          [disabled]="disableEndTime"
        >
        </ngx-timepicker-field>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutWrap="wrap"
      fxLayoutGap="15px"
      fxLayoutAlign="space-between center"
      *ngIf="showTimeZone"
    >
      <div class="timezone-selection" fxFlex.gt-xs="48">
        <mat-form-field>
          <mat-select
            name="timeZone"
            [ngModel]="selectedTz"
            (ngModelChange)="timeZoneChanged($event)"
            placeholder="Select timezone"
          >
            <!-- <input matInput type="text" (keyup)="filterList($event.target.value)" autocomplete="false"> -->
            <mat-option *ngFor="let tz of tzNames" [value]="tz">
              {{ tz }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-form-field class="graph-type" *ngIf="hasGraphs && period === 'specific' && insightsType === 'SALES'">
      <mat-label>{{ 'select_graphs_type' | translate }}</mat-label>
      <mat-select [(value)]="graphType" (selectionChange)="handleGraphTypeChange($event)">
        <mat-option
          *ngFor="let type of reportType"
          [value]="type.value"
          [disabled]="!handleGraphTypeSelection(type.months, type.max)"
        >
          {{ type.viewValue | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
    <mat-checkbox *ngIf="period === 'specific'" [(ngModel)]="showTime" (change)="hasSpecificTime($event)">{{
      'report_spec_time' | translate
    }}</mat-checkbox>
    <br />
    <mat-checkbox [(ngModel)]="showTimeZone" (change)="hasSpecificTimeZone($event)">{{
      'report_spec_zone' | translate
    }}</mat-checkbox>
    ({{ 'current_timezone' | translate }} <b>{{ currentTimezone }}</b
    >)
  </mat-card-content>
</mat-card>
