<div fxLayout="row" fxLayoutWrap="wrap">
  <div fxFlex.gt-md="100">
    <h4 mat-dialog-title>{{ 'create_new_rep' | translate }}</h4>
  </div>
</div>
<div mat-dialog-content>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'select' | translate }} {{ 'period' | translate }} </mat-panel-title>
      </mat-expansion-panel-header>
      <period-selection
        (selectPeriod)="changeReportPeriod($event)"
        (changeDate)="changeReportDate($event)"
      ></period-selection>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'select' | translate }} {{ 'device' | translate }} </mat-panel-title>
      </mat-expansion-panel-header>
      <app-device-selection
        (sendSelectedDevice)="changeReportDevice($event)"
      ></app-device-selection>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'select' | translate }} {{ 'employee' | translate }} </mat-panel-title>
      </mat-expansion-panel-header>
      <app-employee-selection
        (sendSelectedEmployee)="changeReportEmployee($event)"
      ></app-employee-selection>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'select_store' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-select-store (sendSelectedStore)="changeSelectedStore($event)"></app-select-store>
    </mat-expansion-panel>
  </mat-accordion>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{ 'no_thanks' | translate }}</button>
    <button mat-button (click)="confirm()" cdkFocusInitial>Ok</button>
  </div>
</div>
