<div class="save-payment-container">
  <div class="save-payment-title">
    <h1 mat-dialog-title>
      <font class="cap">Save payment method</font>
    </h1>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="save-payment-content">
    <mat-checkbox (change)="changePaymentSaved($event)"
      >Do you want to save your preferred payment option for future transactions?</mat-checkbox
    >
  </div>
  <mat-divider></mat-divider>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">{{ 'cancel' | translate }}</button>
      <button mat-button (click)="goToPayment()" cdkFocusInitial>Confirm</button>
    </div>
  </div>
</div>
