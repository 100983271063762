import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { CustomerGroup } from './../model/customerGroup.model'

@Injectable()
export class CustomerGroupsService {
  private customerGroups: CustomerGroup[]
  private count: number
  private allCustomerGroupsCount: any

  constructor(private request: RequestService) {}

  fetchCustomerGroups(): void {
    this.request.getResource('customergroups', { limit: 100 }).then((groups: CustomerGroup[]) => {
      this.customerGroups = groups
      this.count = groups.length
    })
  }

  getCustomerGroupsCount(): Promise<number> {
    return this.request.getResource('customergroups/count').then((count: number) => {
      this.allCustomerGroupsCount = count
      return count
    })
  }

  getCustomerGroups(): CustomerGroup[] {
    return Object.assign([], this.customerGroups)
  }

  getAllCustomerGroupsCount(): number {
    return this.allCustomerGroupsCount
  }

  async getCustomerGroup(id: string) {
    return this.request.getResource('customergroups/' + id)
  }

  setCustomerGroup(group: CustomerGroup): Promise<CustomerGroup> {
    // let index = this.customers.findIndex(emp => emp._id === customer._id)
    if (group && group._id) {
      return this.request
        .patchResource('customergroups/' + group._id, group)
        .then((updated: CustomerGroup) => {
          // this.customers[index] = updated
          return Promise.resolve(updated)
        })
    } else {
      return this.request.postResource('customergroups', group).then((updated: CustomerGroup) => {
        this.customerGroups = [...[updated], ...this.customerGroups]
        return Promise.resolve(updated)
      })
    }
  }

  deleteCustomerGroup(id: string): Promise<CustomerGroup> {
    const index = this.customerGroups.findIndex(dv => dv._id === id)
    this.customerGroups.splice(index, 1)
    return this.request.deleteResource('customergroups/' + id).then((group: CustomerGroup) => {
      return Promise.resolve(group)
    })
  }
}
