import { Component, OnInit, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { UtilsService } from '../../../services/utils.service'

import { log } from 'util'

@Component({
  selector: 'app-dialog-create-report',
  templateUrl: './dialog-create-report.component.html',
  styleUrls: ['./dialog-create-report.component.css']
})
export class DialogCreateReportComponent implements OnInit {
  public panelOpenState: boolean
  constructor(
    public dialogRef: MatDialogRef<DialogCreateReportComponent>,
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.panelOpenState = false
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close()
  }

  changeReportPeriod(period: string) {
    this.data.period = period
  }

  changeReportDate(event) {
    if (event && event.start && this.data.period !== 'specific') {
      this.data.dateObj.start = event.start
    }
    if (event && event.end && event.start && this.data.period === 'specific') {
      this.data.dateObj.start_date = event.start
      this.data.dateObj.end_date = event.end
    }
  }

  changeReportDevice(device) {
    this.data.device = this.utils.handleListUpdate(device.device._id, this.data.device, true)
  }

  changeReportEmployee(employee) {
    this.data.employee = this.utils.handleListUpdate(
      employee.employee._id,
      this.data.employee,
      true
    )
  }

  changeSelectedStore(store) {
    this.data.store = this.utils.handleListUpdate(store.store._id, this.data.store, true)
  }

  setArrays() {
    if (this.data.store === undefined) {
      this.data.store = []
    }
    if (this.data.device === undefined) {
      this.data.device = []
    }
    if (this.data.employee === undefined) {
      this.data.employee = []
    }
  }

  confirm(): void {
    this.setArrays()
    if (this.data) {
      this.dialogRef.close(this.data)
    } else {
      this.dialogRef.close(true)
    }
  }
}
