<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'SALES'">
  <mat-accordion class="revenue" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'revenue' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_revenue' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="revenue">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'revenue' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let data" [ngClass]="'customWidthClass'">
            <h5 class="m-0">{{ data.name | translate}}</h5>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef>{{ 'amount' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let data">
            <h5 class="m-0">{{ data.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceRevenueTocompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(data, dataSourceRevenueTocompare, 'amount', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(data, dataSourceRevenueTocompare, 'amount', 'name')
              }}</span
            >
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'SALES'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'transactions' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_transaction' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table #table [dataSource]="dataSourceTransaction">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let transaction" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              {{ transaction.name | translate}}
              <span *ngIf="transaction.methods" style="font-size: 10px">
                ({{ 'click_details' | translate }})
              </span>
            </h5>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ 'total' | translate }}:</b>
            </h5>
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef>{{ 'amount' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let transaction">
            <h5 class="m-0">{{ transaction.amount | currency: currency }}</h5>
            <h5 class="m-0" *ngIf="transaction.methods">
              {{ transaction.total[0].totalAmount | currency: currency }}
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(transaction, dataSourceTransactionToCompare, 'amount', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  transaction,
                  dataSourceTransactionToCompare,
                  'amount',
                  'name'
                )
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalTransactions().amount | currency: currency }}</b>
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalTransactions().amount,
                  totalTransactions().amount
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(
                  compareService.getTotalTransactions().amount,
                  totalTransactions().amount
                )
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef>{{ 'number' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let transaction">
            <h5 class="m-0">{{ transaction.number }}</h5>
            <h5 class="m-0" *ngIf="transaction.methods">{{ transaction.total[0].totalNumber }}</h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(transaction, dataSourceTransactionToCompare, 'number', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  transaction,
                  dataSourceTransactionToCompare,
                  'number',
                  'name'
                )
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalTransactions().number }}</b>
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalTransactions().number,
                  totalTransactions().number
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(
                  compareService.getTotalTransactions().number,
                  totalTransactions().number
                )
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let detail">
            <!-- {{detail.element.methods[0].name}} -->
            <div class="method-details">
              <ul *ngFor="let method of detail.element.methods">
                <li>
                  {{ 'method' | translate }}: <b>{{ method.name }}</b>
                </li>
                <li>
                  {{ 'number' | translate }}: <b>{{ method.number }}</b>
                </li>
                <li>
                  {{ 'amount' | translate }}:
                  <b>{{ method.amount | currency: currency }}</b>
                </li>
              </ul>
            </div>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row clickable"
          [class.expanded]="expandedElement == row"
          (click)="showRowDetails(row)"
        ></mat-row>
        <mat-row
          *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
          [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'"
          style="overflow: hidden"
        >
        </mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumnsReports"></mat-footer-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'SALES'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'payments' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_payments' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourcePayment"
        matSort
        #payments="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let payment" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              {{ payment.name | translate}}
              <span *ngIf="payment.methods" style="font-size: 10px">
                ({{ 'click_details' | translate }})
              </span>
            </h5>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ 'total' | translate }}:</b>
            </h5>
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let payment">
            <h5 class="m-0">{{ payment.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourcePaymentToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(payment, dataSourcePaymentToCompare, 'amount', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(payment, dataSourcePaymentToCompare, 'amount', 'name')
              }}</span
            >
            <!-- <h5 class="m-0" *ngIf="payment.methods">
              {{ payment.total[0].totalAmount | currency: currency }}
            </h5> -->
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalPayments().amount | currency: currency }}</b>
            </h5>
            <span
              *ngIf="dataSourcePaymentToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalPayments().amount,
                  totalPayments().amount
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(compareService.getTotalPayments().amount, totalPayments().amount)
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let payment">
            <h5 class="m-0">{{ payment.number }}</h5>
            <span
              *ngIf="dataSourcePaymentToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(payment, dataSourcePaymentToCompare, 'number', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(payment, dataSourcePaymentToCompare, 'number', 'name')
              }}</span
            >
            <!-- <h5 class="m-0" *ngIf="payment.methods">{{ payment.total[0].totalNumber }}</h5> -->
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalPayments().number }}</b>
            </h5>
            <span
              *ngIf="dataSourcePaymentToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalPayments().number,
                  totalPayments().number
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(compareService.getTotalPayments().number, totalPayments().number)
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl2"
        ></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumnsReports"></mat-footer-row>
      </mat-table>

      <ng-template #tpl2 let-element>
        <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
          <div class="method-details">
            <ul *ngFor="let method of element.methods">
              <li>
                {{ 'method' | translate }}: <b>{{ method.name | translate}}</b>
              </li>
              <li>
                {{ 'number' | translate }}: <b>{{ method.number }}</b>
              </li>
              <li>
                {{ 'amount' | translate }}:
                <b>{{ method.amount | currency: currency }}</b>
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'SALES'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'refunds' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_refunds' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table #table [dataSource]="dataSourceRefund">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let refund" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">{{ refund.name | translate}}</h5>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ 'total' | translate }}:</b>
            </h5>
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef>{{ 'amount' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let refund">
            <h5 class="m-0">{{ refund.amount | currency: currency }}</h5>
            <!-- I use the transactions array because teh refunds returs empty sometimes causing problems -->
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(refund, dataSourceRefundToCompare, 'amount', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(refund, dataSourceRefundToCompare, 'amount', 'name')
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalRefunds().amount | currency: currency }}</b>
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalRefunds().amount,
                  totalRefunds().amount
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(compareService.getTotalRefunds().amount, totalRefunds().amount)
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef>{{ 'number' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let refund">
            <h5 class="m-0">{{ refund.number }}</h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(refund, dataSourceRefundToCompare, 'number', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(refund, dataSourceRefundToCompare, 'number', 'name')
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalRefunds().number }}</b>
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalRefunds().number,
                  totalRefunds().number
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(compareService.getTotalRefunds().number, totalRefunds().number)
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsReports"></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumnsReports"></mat-footer-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'SALES'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'discount_percentage' | translate }}</mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_discount_percentage' | translate }} {{ 'number_of_discounts' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceDiscount"
        matSort
        #discount="matSort"
        matSortDirection="desc"
        matSortDisableClear
        >>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let discount" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">{{ discount.name | translate}}</h5>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ 'total' | translate }}:</b>
            </h5>
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let discount">
            <h5 class="m-0">{{ discount.amount | currency: currency }}</h5>
            <!-- I use the transactions array because teh refunds returs empty sometimes causing problems -->
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(discount, dataSourceDiscountToCompare, 'amount', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  discount,
                  dataSourceDiscountToCompare,
                  'amount',
                  'name'
                )
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalDiscount.amount | currency: currency }}</b>
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalDiscount().amount,
                  totalDiscount.amount
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(compareService.getTotalDiscount().amount, totalDiscount.amount)
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let discount">
            <h5 class="m-0">{{ discount.number }}</h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(discount, dataSourceDiscountToCompare, 'number', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  discount,
                  dataSourceDiscountToCompare,
                  'number',
                  'name'
                )
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalDiscount.number }}</b>
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalDiscount().number,
                  totalDiscount.number
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(compareService.getTotalDiscount().number, totalDiscount.number)
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsReports"></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumnsReports"></mat-footer-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'SALES'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'discount_numeric' | translate }}</mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_discount_numeric' | translate }} {{ 'number_of_discounts' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceDiscountNumeric"
        matSort
        #discount="matSort"
        matSortDirection="desc"
        matSortDisableClear
        >>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let discount" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">{{ discount.name | translate}}</h5>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ 'total' | translate }}:</b>
            </h5>
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let discount">
            <h5 class="m-0">{{ discount.amount | currency: currency }}</h5>
            <!-- I use the transactions array because teh refunds returs empty sometimes causing problems -->
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(discount, dataSourceDiscountToCompareNumeric, 'amount', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  discount,
                  dataSourceDiscountToCompareNumeric,
                  'amount',
                  'name'
                )
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalDiscountNumeric.amount | currency: currency }}</b>
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalDiscountNumeric().amount,
                  totalDiscountNumeric.amount
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(
                  compareService.getTotalDiscountNumeric().amount,
                  totalDiscountNumeric.amount
                )
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let discount">
            <h5 class="m-0">{{ discount.number }}</h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(discount, dataSourceDiscountToCompareNumeric, 'number', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  discount,
                  dataSourceDiscountToCompareNumeric,
                  'number',
                  'name'
                )
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalDiscountNumeric.number }}</b>
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalDiscountNumeric().number,
                  totalDiscountNumeric.number
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(
                  compareService.getTotalDiscountNumeric().number,
                  totalDiscountNumeric.number
                )
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsReports"></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumnsReports"></mat-footer-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'SALES'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'taxes' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_taxes' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table #table [dataSource]="dataSourceTax">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let tax" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">{{ tax.name | translate}}</h5>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ 'total' | translate }}:</b>
            </h5>
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef>{{
            'total_taxed_amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let tax">
            <h5 class="m-0">{{ tax.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceTaxToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(tax, dataSourceTaxToCompare, 'amount', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(tax, dataSourceTaxToCompare, 'amount', 'name')
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              {{ totalTaxes().taxes | currency: currency }}
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(compareService.getTotalTaxes().taxes, totalTaxes().taxes).includes(
                  '-'
                )
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{ compareTotals(compareService.getTotalTaxes().taxes, totalTaxes().taxes) }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef>{{
            'total_price_product' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let tax">
            <h5 class="m-0">{{ tax.price | currency: currency }}</h5>
            <span
              *ngIf="dataSourceTaxToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(tax, dataSourceTaxToCompare, 'price', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(tax, dataSourceTaxToCompare, 'price', 'name')
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              {{ totalTaxes().amount | currency: currency }}
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(compareService.getTotalTaxes().amount, totalTaxes().amount).includes(
                  '-'
                )
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{ compareTotals(compareService.getTotalTaxes().amount, totalTaxes().amount) }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsTaxes"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTaxes"></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumnsTaxes"></mat-footer-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card
  fxLayout="row"
  fxLayoutWrap="wrap"
  *ngIf="selectedInsightsType === 'SALES' && !this.employeeReport && !this.customerReport"
>
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'reps_waste' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_reps_waste' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceRespAndWaste"
        matSort
        #repsWaste="matSort"
        matSortActive="name"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let reason" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <span *ngIf="reason.name === '<empty>'">Unknown</span>
              <span *ngIf="reason.name !== '<empty>'">
                <b>{{ reason.name | translate }}</b
                ><span style="font-size: 10px"> ({{ 'click_details' | translate }}) </span></span
              >
            </h5>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ 'total' | translate }}:</b>
            </h5>
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let reason">
            <h5 class="m-0">{{ reason.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceRespAndWasteToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(reason, dataSourceRespAndWasteToCompare, 'amount', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  reason,
                  dataSourceRespAndWasteToCompare,
                  'amount',
                  'name'
                )
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalRepsWaste().amount | currency: currency }}</b>
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalRepsAndWaste().amount,
                  totalRepsWaste().amount
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(compareService.getTotalRepsAndWaste().amount, totalRepsWaste().amount)
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let reason">
            <h5 class="m-0">{{ reason.number }}</h5>
            <span
              *ngIf="dataSourceRespAndWasteToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(reason, dataSourceRespAndWasteToCompare, 'number', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  reason,
                  dataSourceRespAndWasteToCompare,
                  'number',
                  'name'
                )
              }}</span
            >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <h5 class="m-0 cap">
              <b>{{ totalRepsWaste().number }}</b>
            </h5>
            <span
              *ngIf="dataSourceTransactionToCompare.length > 0"
              [ngClass]="
                compareTotals(
                  compareService.getTotalRepsAndWaste().number,
                  totalRepsWaste().number
                ).includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareTotals(compareService.getTotalRepsAndWaste().number, totalRepsWaste().number)
              }}</span
            >
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl"
        ></mat-row>
        <mat-footer-row *matFooterRowDef="displayedColumnsReports"></mat-footer-row>
      </mat-table>

      <ng-template #tpl let-element>
        <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
          <div class="method-details">
            <table class="table">
              <tbody *ngFor="let product of element.items">
                <tr>
                  <td>
                    <b>{{ product.name }}</b>
                  </td>
                  <td>{{ product.number }}</td>
                  <td>{{ product.amount | currency: currency }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'SALES'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'channels' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_channels' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceChannel"
        matSort
        #channels="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let channel" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="channel.name === '<empty>'">Unknown</b>
              <b *ngIf="channel.name !== '<empty>'">{{ channel.name | translate}}</b>
              <span *ngIf="channel.stores" style="font-size: 10px">
                ({{ 'click_details' | translate }})
              </span>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let channel">
            <h5 class="m-0">{{ channel.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceChannelToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(channel, dataSourceChannelToCompare, 'amount', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(channel, dataSourceChannelToCompare, 'amount', 'name')
              }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let channel">
            <h5 class="m-0">{{ channel.number }}</h5>
            <span
              *ngIf="dataSourceChannelToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(channel, dataSourceChannelToCompare, 'number', 'name')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(channel, dataSourceChannelToCompare, 'number', 'name')
              }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl3"
        ></mat-row>
      </mat-table>

      <ng-template #tpl3 let-element>
        <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
          <div class="method-details">
            <table class="table">
              <tbody *ngFor="let store of element.stores">
                <tr>
                  <td>
                    <b>{{ store.name }}</b> ({{ 'store' | translate }})
                  </td>
                  <td>{{ store.number }}</td>
                  <td>{{ store.amount | currency: currency }}</td>
                </tr>
                <tr *ngFor="let delivery of store.deliveries">
                  <td style="color: #aaaaaa">
                    - {{ delivery.name }}
                    <!-- ({{ 'channel' | translate }}) -->
                  </td>
                  <td style="color: #aaaaaa">{{ delivery.number }}</td>
                  <td style="color: #aaaaaa">
                    {{ delivery.amount | currency: currency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>
