<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [ngClass]="{ dark: darkTheme }">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar class="topbar countr-dark-color">
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <button
      mat-icon-button
      (click)="snav.toggle()"
      value="sidebarclosed"
      class="white-icon"
      *ngIf="showMenu"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <div class="navbar-header">
      <a class="navbar-brand" href="{{ url }}">
        <!-- Logo icon --><b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <!-- <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo"> -->
          <!-- Light Logo icon -->
          <!-- <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo">-->
          <img src="assets/images/countr_white.png" alt="homepage" class="countr-logo" />
        </b>
      </a>
    </div>
    <div
      class="bar-div"
      *ngIf="!this.isBelgium && userPos && showMenu && getUser.__t !== 'Altuser'"
    >
      <a href="https://{{ userPos }}.countr.app" target="_blank">
        <button mat-button class="bar-button">Web POS</button>
      </a>
    </div>
    <div class="bar-div" *ngIf="!showMenu && router.url && router.url === '/auth/login'">
      <a [routerLink]="['/auth/sign-up']">
        <button mat-button class="bar-button">Sign up</button>
      </a>
    </div>

    <div class="bar-div" *ngIf="!showMenu && router.url && router.url === '/auth/sign-up'">
      <a [routerLink]="['/auth/login']">
        <button mat-button class="bar-button">Sign in</button>
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->
    <!-- <button mat-icon-button class="srh-btn white-icon" (click)="showSearch()" *ngIf="showMenu">
      <mat-icon>search</mat-icon>
    </button>
    <form id="app-search" class="app-search">
      <input type="text" class="form-control" placeholder="Search &amp; enter" />
      <a class="cl-srh-btn clickable" (click)="showSearch()"><i class="ti-close"></i></a>
    </form> -->
    <span fxFlex></span>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->
    <!-- app-header contains the message, profile and notifications -->
    <app-header *ngIf="showMenu"></app-header>
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
  >
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav
      #snav
      id="snav"
      class="pl-xs"
      [ngClass]="{ 'dark-sidebar': darkTheme }"
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0"
      [opened]="showMenu"
      [disableClose]="mobileQuery.matches"
      (open)="sidebarOpened = true"
      (close)="sidebarOpened = false"
    >
      <app-sidebar></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper">
      <div class="page-content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
      </div>
      <div class="pop-up-box" *ngIf="showMenu && getUser.extras && getUser.extras.support_message">
        <pop-up-box></pop-up-box>
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>

  <!-- <div class="trial-pop-up" *ngIf="!isAltuser">
    <iframe
      *ngIf="customerSupportOpen"
      width="500vw"
      height="750vh"
      [src]="customerSupportUrl | safe"
    ></iframe>
    <button
      mat-fab
      class="md-fab md-fab-bottom-right"
      style="background-color: #34495e"
      (click)="handleIframe()"
    >
      <mat-icon *ngIf="!customerSupportOpen">chat</mat-icon>
      <mat-icon *ngIf="customerSupportOpen">close</mat-icon>
    </button>
  </div> -->
</div>
