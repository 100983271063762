import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { DateAdapter } from '@angular/material/core'
import { ExtensionsService } from './../../services/extensions.service'
import { Invoice } from './../../model/invoice.model'
import { InvoiceService } from './../../services/invoices.service'
import { RequestService } from './../../services/request.service'
import { CompareReportService } from './../../services/compare-reports.service'
import { timeZones } from './../../../assets/js/timezones'

interface ReportType {
  value: string
  viewValue: string
  months: number
  max: number
}

@Component({
  selector: 'period-selection',
  templateUrl: 'period-selection.component.html',
  styleUrls: ['period-selection.component.scss']
})
export class PeriodSelectionComponent implements OnInit {
  @Output() private selectPeriod = new EventEmitter<string>()
  @Output() private changeDate = new EventEmitter<Object>()
  @Output() private changeTime = new EventEmitter<Object>()
  @Output() private hasTime = new EventEmitter<boolean>()
  @Output() private hasTimeZone = new EventEmitter<boolean>()
  @Output() private timezone = new EventEmitter<boolean>()
  @Input() public disableStartTime: boolean
  @Input() public disableEndTime: boolean
  @Input() public hasGraphs: boolean
  @Input() public insightsType: string

  public period: string
  public maxDate: Date
  public startDate: Date
  public endDate: Date
  public isBasicAccount: boolean
  public startTime: string
  public endTime: string
  public showTime: boolean
  public showTimeZone: boolean
  private invoices: Invoice[]
  public outstandingPayment: boolean
  private payment: any
  private user: any
  private newDateInvoice: Date
  public tzNames: any[]
  public selectedTz: string
  public currentTimezone: string
  public graphType: string

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private extensions: ExtensionsService,
    private invoiceService: InvoiceService,
    private request: RequestService,
    private compareReports: CompareReportService
  ) {
    this.dateAdapter.setLocale('gb')
    this.period = 'day'
    this.graphType = 'day'
    this.maxDate = new Date()
    this.startDate = new Date()
    this.isBasicAccount = true
    this.showTime = false
    this.showTimeZone = false
    this.outstandingPayment = false
    this.tzNames = timeZones
    this.currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  reportType: ReportType[] = [
    { value: 'day', viewValue: 'day', months: -1, max: 3 },
    { value: 'week', viewValue: 'week', months: 0, max: 0 },
    { value: 'month', viewValue: 'month', months: 1, max: 0 },
    { value: 'quarter', viewValue: 'quarter', months: 3, max: 0 },
    { value: 'year', viewValue: 'year', months: 12, max: 0 }
  ]

  ngOnInit() {
    this.compareReports.setReportGraphType(this.graphType)
    this.compareReports.setSpecificReport(false)
    this.invoices = this.invoiceService.getInvoices()
    setTimeout(() => {
      if (this.extensions.userHasExtension('live-basic')) {
        this.isBasicAccount = true
      } else {
        this.isBasicAccount = false
      }
    }, 1300)
    this.getOutstandingInvoice()
  }

  getOutstandingInvoice() {
    this.request.getResource('me/invoices/awaiting').then(payment => {
      if (payment) {
        this.payment = payment
        this.checkInvoice()
      }
    })
  }

  checkInvoice() {
    if (
      (this.payment && this.payment.complete) ||
      this.addDays(
        this.payment.invoice_date || this.payment.created_at,
        this.payment.payment_term_days || 14
      )
    ) {
      this.outstandingPayment = false
    } else {
      this.outstandingPayment = true
    }
  }

  addDays(date, days) {
    const result = new Date(date)
    const today = new Date()
    result.setDate(result.getDate() + days)
    if (result.getTime() > today.getTime()) {
      return true
    } else {
      return false
    }
  }

  changePeriod(period: string) {
    this.period = period
    this.selectPeriod.emit(this.period)
    this.compareReports.setSpecificReport(true)
    if (period !== 'specific') {
      this.showTime = false
      this.hasTime.emit(this.showTime)
      this.compareReports.setSpecificReport(false)
    }
  }

  changeInputDate(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type === 'start') {
      this.startDate = event.value
      this.startDate.setHours(0, 0, 0, 1)
    } else if (type === 'end') {
      this.endDate = event.value
      this.endDate.setHours(23, 59, 59, 999)
    }

    this.changeDate.emit({ start: this.startDate, end: this.endDate })
    // If month are 3 or more select weekly graph and disable day option
    const months = this.handleMonthsDiff()
    if (months >= 3) {
      this.graphType = 'week'
      this.compareReports.setReportGraphType('week')
    }
  }

  hasSpecificTime(event) {
    this.hasTime.emit(event.checked)
  }

  hasSpecificTimeZone(event) {
    this.hasTimeZone.emit(event.checked)
  }

  onChangeTime(type: string, time: string) {
    if (type === 'start') {
      this.startTime = time
    } else {
      this.endTime = time
    }

    this.changeTime.emit({ startTime: this.startTime, endTime: this.endTime })
  }

  timeZoneChanged(event) {
    this.timezone.emit(event)
  }

  handleMonthsDiff() {
    if (this.startDate && this.endDate) {
      const date1 = new Date(this.startDate)
      const date2 = new Date(this.endDate)
      const years = date2.getFullYear() - date1.getFullYear()
      const months = years * 12 + (date2.getMonth() - date1.getMonth())

      return months
    } else {
      return 0
    }
  }

  handleGraphTypeSelection(value, max) {
    const months = this.handleMonthsDiff()

    if (months > value) {
      if (max === 0 || max > months) {
        return true
      } else if (max < months) {
        return false
      }
    } else {
      return false
    }
  }

  handleGraphTypeChange(type: any) {
    this.compareReports.setReportGraphType(type.value)
  }
}
