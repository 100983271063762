import { Injectable } from '@angular/core'
import { RequestService } from './request.service'

@Injectable()
export class MetadataService {
    private payment_method: any[]

    constructor(private request: RequestService) {}

    fetchMetadata() {
        this.request.getResource('me/metadata').then((metada: any[]) => {
          this.payment_method = metada
        })
    }

    getPaymentMethod(): any[] {
        return Object.assign([], this.payment_method)
    }

    deletePaymentMethod(method: any): Promise<any> {
        return this.request.patchResource('me/metadata', method[0]).then((payment) => {
            return Promise.resolve(payment)
        })
    }
}
