import { Injectable, Output, EventEmitter, Directive } from '@angular/core'

@Directive()
@Injectable()
export class DataService {
  private theme: string
  private searchTerm = ''
  private searchCategory: any = {}
  private productsCategory: any = []

  @Output()
  private changeTheme = new EventEmitter<string>()

  constructor() {
    this.theme = 'light'
  }

  initDataService() {
    this.checkTheme()
  }

  checkTheme() {
    const thema = localStorage.getItem('countr-dashboard:theme')
    this.theme = !!thema ? thema : 'light'
  }

  getTheme() {
    return this.theme
  }

  isDarkMode() {
    return this.theme === 'dark'
  }

  setTheme() {
    this.theme = this.theme === 'light' ? 'dark' : 'light'
    localStorage.setItem('countr-dashboard:theme', this.theme)
    this.changeTheme.emit(this.theme)
  }

  getChangeTheme() {
    return this.changeTheme
  }

  getSearchTerm(): string {
    return this.searchTerm
  }

  setSearchTerm(term: string) {
    this.searchTerm = term
  }

  getSearchCategory(): any {
    return this.searchCategory
  }

  setSearchCategory(category: any) {
    this.searchCategory = category
  }

  getProductsCategory(): any {
    return this.productsCategory
  }

  setProductsCategory(products: any[]) {
    this.productsCategory = products
  }
}
