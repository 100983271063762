// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  version: '1.4.13',
  production: false,
  local: false,
  client_id: 'client-f5189179da1eeff475cf9fcd233e155824fd497a',
  client_secret: 'secret-5248dc339a5812d07e1fcee9a7845fc6fd713a5c',
  grant_type: 'password',
  api_domain: 'api-test.countr.rest/v2',
  api_server: 'https://api-test.countr.rest/v2/',
  // api_server: 'http://localhost:3030/v2/',
  app_server: 'https://applications-staging.countrhq.com',
  SegmentSourceKey: 'gc3tkjvp44',
  chat_url: 'https://countr-customer-support-stg.herokuapp.com/',
  cloudinary_cloud_name: 'countr-prod',
  cloudinary_api_key: '139724634935931',
  cloudinary_upload_preset: 'fvrjpdy2',
  // payments_service_url: 'https://master.d1sijuwj887bns.amplifyapp.com/', //prod
  // payments_service_url: 'https://master.d1nqvkhvfxm2in.amplifyapp.com/', //dev
  payments_service_url: 'https://staging.d39dq986dv1gd3.amplifyapp.com/' //staging
  // payments_service_url: 'http://localhost:3001/',
}
