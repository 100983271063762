import { Injectable } from '@angular/core'
import { RequestService } from './request.service'

@Injectable()
export class CurrenciesService {
  private currencies: any[]

  constructor(private request: RequestService) {}

  fetchCurrencies(): Promise<any[]> {
    return this.request.getResource('currencies').then((currencies: any[]) => {
      this.currencies = currencies
      return this.currencies
    })
  }

  getcurrencies(): any[] {
    return this.currencies
  }
}
