import { Component, Input, OnChanges, ViewChild } from '@angular/core'
import { BaseChartDirective } from 'ng2-charts'
import { RequestService } from '../../services/request.service'
import { Product } from './../../model/transaction.model'

@Component({
  selector: 'top-sales',
  templateUrl: 'top-sales.component.html',
  styleUrls: ['top-sales.component.scss']
})
export class TopSalesComponent implements OnChanges {
  @Input()
  topsales
  @Input()
  currency: string
  @Input()
  bottom: boolean
  @ViewChild(BaseChartDirective)
  chart: BaseChartDirective

  private productList
  public showGraph: boolean
  private showProductTile: boolean
  private productTile: any

  public pieChartData: number[]
  public pieChartLabels: string[]
  public pieChartBackground: object[]
  public pieChartOptions: any = {
    maintainAspectRatio: true,
    hover: {
      onHover: (event, active) => {
        if (active && active.length) {
          this.productTile = this.productList[active[0]._index]
          this.showProductTile = true
        } else {
          this.showProductTile = false
        }
      }
    }
  }

  constructor(private request: RequestService) {
    this.showGraph = false
    this.showProductTile = false
    this.productTile = {}
    this.productList = []
    this.pieChartData = []
    this.pieChartLabels = []
    this.pieChartBackground = [
      {
        backgroundColor: ['#3696d6', '#f07452', '#369944', '#ebac30', '#000000b3']
      }
    ]
  }

  ngOnChanges() {
    if (
      this.topsales &&
      Object.keys(this.topsales).length > 0 &&
      Object.keys(this.topsales.products.details).length > 0
    ) {
      this.showGraph = true
      setTimeout(() => {
        this.updateChart()
      }, 100)
    } else {
      this.showGraph = false
    }
  }

  updateChart() {
    this.pieChartData = []
    this.pieChartLabels = []
    this.productList = []
    Object.keys(this.topsales.products.details)
      .splice(0, 5)
      .forEach(key => {
        this.productList.push({
          _id: key,
          name: this.topsales.products.details[key].object.name,
          amount: this.topsales.products.details[key].amount
        })
        this.pieChartData.push(this.topsales.products.details[key].amount)
        this.pieChartLabels.push(this.topsales.products.details[key].object.name)
        // Changing charts obj label - if we dont do it, it doesnt updated the labels
        this.chart.chart.data.labels = this.pieChartLabels
      })
  }

  getProductsImages() {
    this.productList.forEach((product, index) => {
      this.request.getResource('products/' + product._id).then((p: Product) => {
        this.productList[index].image = p.image
        this.productList[index].categoryColor = p.categories[0].color
      })
    })
  }

  chartClicked(e: any): void {}
}
