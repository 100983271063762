import { NgModule, LOCALE_ID } from '@angular/core'

import { MaterialModule } from '../material-module'
import { CommonModule } from '@angular/common'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ChartsModule } from 'ng2-charts'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MenuItems } from './menu-items/menu-items'
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion'
import { StoreSelectionComponent } from '../overview/overview-components/store-selection.component'
import { PeriodSelectionComponent } from '../common-components/period-selection/period-selection.component'
import { ProductTileComponent } from '../common-components/product-tile/product-tile.component'
import { DeviceSelectionComponent } from '../common-components/device-selection/device-selection.component'
import { EmployeeSelectionComponent } from '../common-components/employee-selection/employee-selection.component'
import { ProductSellersComponent } from '../common-components/product-sellers/product-sellers.component'
import { BottomProductsSellersComponent } from '../common-components/bottom-products-sellers/bottom-products-sellers.component'
import { NotSoldProductsComponent } from '../common-components/not-sold-products/not-sold-products.component'
import { ResourceTableComponent } from '../common-components/resource-table/resource-table.component'
import { ResourceDialogComponent } from '../common-components/resource-table/resource-dialog.component'
import { SelectStoreComponent } from '../common-components/select-store/select-store.component'
import { PaymentInfoModalComponent } from '../common-components/payment-info-modal/payment-info-modal.component'
import { SavePaymentMethodComponent } from '../common-components/save-payment-method/save-payment-method.component'
import { ReportGraphComponent } from '../overview/overview-components/report-graph.component'
import { TopSalesComponent } from '../overview/overview-components/top-sales.component'
import { SalesPeriodComponent } from '../overview/overview-components/sales-period.component'
import { ReportCompareComponent } from '../overview/overview-components/report-compare.component'
import { CompareReportsComponent } from '../common-components/compare-reports/compare-reports.component'
import { DialogCreateReportComponent } from '../common-components/compare-reports/dialog-create-report/dialog-create-report.component'
import { DataTableSalesComponent } from '../sales-history/reports/data-table/data-table-sales/data-table-sales.component'
import { DataTableProductComponent } from '../sales-history/reports/data-table/data-table-product/data-table-product.component'
import { DataTableStoreComponent } from '../sales-history/reports/data-table/data-table-store/data-table-store.component'
import { SubscribeExtensionModalComponent } from '../common-components/subscribe-extension-modal/subscribe-extension-modal.component'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { TranslatePipe } from '../pipes/translate.pipe'
import { JoinPipe } from '../pipes/join.pipe'
import { OrderByName } from '../pipes/orderby-name.pipe'
import { SortByPipe } from '../pipes/sortby.pipe'
import { ReplacePipe } from '../pipes/replace.pipe'
import { SafePipe } from '../pipes/safe.pipe'
import { CdkDetailRowDirective } from '../sales-history/reports/data-table/cdk-detail-row.directive'
import { AutoCompleteFilter } from '../common-components/auto-complete-filter/auto-complete-filter.component'
import { AutoCompleteMultipleFilter } from './../common-components/auto-complete-filter/auto-complete-multiple-filter.component'
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core'
import {
  AppDateAdapter,
  APP_DATE_FORMATS
} from '../common-components/period-selection/format-datepicker'
// import { ReplacePipe } from '../pipes/replace.pipe'
import { ConfirmationComponent } from './../common-components/confirmation/confirmation.component'
import { CustomDateAdapter } from '../services/custom-date-adapter.service'
import { registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/nl'
registerLocaleData(localeFr)
@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    TranslatePipe,
    JoinPipe,
    OrderByName,
    SortByPipe,
    ReplacePipe,
    SafePipe,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    PeriodSelectionComponent,
    ProductTileComponent,
    DeviceSelectionComponent,
    EmployeeSelectionComponent,
    StoreSelectionComponent,
    ProductSellersComponent,
    BottomProductsSellersComponent,
    NotSoldProductsComponent,
    ResourceTableComponent,
    ResourceDialogComponent,
    SelectStoreComponent,
    PaymentInfoModalComponent,
    SavePaymentMethodComponent,
    ReportGraphComponent,
    TopSalesComponent,
    SalesPeriodComponent,
    ReportCompareComponent,
    CompareReportsComponent,
    DialogCreateReportComponent,
    DataTableSalesComponent,
    DataTableProductComponent,
    DataTableStoreComponent,
    SubscribeExtensionModalComponent,
    CdkDetailRowDirective,
    AutoCompleteFilter,
    AutoCompleteMultipleFilter,
    ConfirmationComponent
  ],
  exports: [
    TranslatePipe,
    JoinPipe,
    OrderByName,
    SortByPipe,
    ReplacePipe,
    SafePipe,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    PeriodSelectionComponent,
    ProductTileComponent,
    DeviceSelectionComponent,
    EmployeeSelectionComponent,
    StoreSelectionComponent,
    ProductSellersComponent,
    BottomProductsSellersComponent,
    NotSoldProductsComponent,
    ResourceTableComponent,
    ResourceDialogComponent,
    SelectStoreComponent,
    PaymentInfoModalComponent,
    SavePaymentMethodComponent,
    ReportGraphComponent,
    TopSalesComponent,
    SalesPeriodComponent,
    ReportCompareComponent,
    CompareReportsComponent,
    DialogCreateReportComponent,
    DataTableSalesComponent,
    DataTableProductComponent,
    DataTableStoreComponent,
    AutoCompleteFilter,
    AutoCompleteMultipleFilter,
    ConfirmationComponent
  ],
  providers: [
    MenuItems,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: LOCALE_ID, useValue: 'nl' }
  ],
  entryComponents: [
    ResourceDialogComponent,
    DialogCreateReportComponent,
    PaymentInfoModalComponent,
    SavePaymentMethodComponent,
    SubscribeExtensionModalComponent,
    ConfirmationComponent
  ]
})
export class SharedModule {}
