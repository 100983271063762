export const EUCOUNTRIES = [
  {
    name: 'Austria (Österreich)',
    code: 'at'
  },
  {
    name: 'Belgium (België)',
    code: 'be'
  },
  {
    name: 'Bulgaria (България)',
    code: 'bg'
  },
  {
    name: 'Croatia (Hrvatska)',
    code: 'hr'
  },
  {
    name: 'Cyprus (Κύπρος)',
    code: 'cy'
  },
  {
    name: 'Czech Republic (Česká republika)',
    code: 'cz'
  },
  {
    name: 'Denmark (Danmark)',
    code: 'dk'
  },
  {
    name: 'Estonia (Eesti)',
    code: 'ee'
  },
  {
    name: 'Finland (Suomi)',
    code: 'fi'
  },
  {
    name: 'France',
    code: 'fr'
  },
  {
    name: 'Germany (Deutschland)',
    code: 'de'
  },
  {
    name: 'Greece (Ελλάδα)',
    code: 'gr'
  },
  {
    name: 'Hungary (Magyarország)',
    code: 'hu'
  },
  {
    name: 'Ireland',
    code: 'ie'
  },
  {
    name: 'Italy (Italia)',
    code: 'it'
  },
  {
    name: 'Latvia (Latvija)',
    code: 'lv'
  },
  {
    name: 'Lithuania (Lietuva)',
    code: 'lt'
  },
  {
    name: 'Luxembourg',
    code: 'lu'
  },
  {
    name: 'Malta',
    code: 'mt'
  },
  {
    name: 'Netherlands (Nederland)',
    code: 'nl'
  },
  {
    name: 'Poland (Polska)',
    code: 'pl'
  },
  {
    name: 'Portugal',
    code: 'pt'
  },
  {
    name: 'Romania (România)',
    code: 'ro'
  },
  {
    name: 'Slovakia (Slovensko)',
    code: 'sk'
  },
  {
    name: 'Slovenia (Slovenija)',
    code: 'si'
  },
  {
    name: 'Spain (España)',
    code: 'es'
  },
  {
    name: 'Sweden (Sverige)',
    code: 'se'
  }
]
