<mat-form-field>
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let op of optionsSelected"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(op)"
      [disabled]="disable"
    >
      {{ op[key] }}
      <mat-icon matChipRemove *ngIf="removable && !disable">cancel</mat-icon>
    </mat-chip>
    <input
      [placeholder]="title"
      #optionInput
      [formControl]="optionsCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option
      *ngFor="let op of filteredOptions | async | orderByName"
      [value]="op"
    >
      {{ op[key] }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>