import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Invoice } from './../model/invoice.model'

@Injectable()
export class InvoiceService {
  private invoices: Invoice[]

  constructor(private request: RequestService) {}

  async fetchInvoices() {
    await this.request.getResource('me/invoices').then((invoices: Invoice[]) => {
      this.invoices = invoices
    })
  }
  getSpecificInvoice(invoice): void {
    this.request
      .postResource(`me/invoices/${invoice}/pay`, {
        redirectUrl: location.toString() + '/?checkPayment=true'
      })
      .then((payment: any) => {
        if (payment && payment['links'] != undefined) {
          setTimeout(() => location.assign(payment.links.paymentUrl), 1000)
        } else {
          location.reload()
        }
      })
  }
  getInvoices(): Invoice[] {
    return Object.assign([], this.invoices)
  }

  setCurringType(payment_details) {
    this.request.patchResource('me/metadata', { payment_details }).then(
      updated => {
        return updated
      },
      error => {
        console.log(error)
      }
    )
  }
}
