import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Employee } from './../model/employee.model'

@Injectable()
export class EmployeesService {
  private employees: Employee[]
  private count: number

  constructor(private request: RequestService) {}

  // Need to change back the limit to 100

  fetchEmployees(): void {
    this.request.getResource('employees', { limit: 500 }).then((employees: Employee[]) => {
      this.employees = employees
      this.count = employees.length
    })
  }

  getEmployees(): Employee[] {
    return Object.assign([], this.employees)
  }

  getEmployeesCount(): number {
    return this.count
  }

  async getEmployee(id: string) {
    // const employee = { ...this.employees.find(employee => employee._id === id) }

    // if (employee) {
    //   return employee
    // } else {
    const emp = await this.request.getResource('employees/' + id)
    return emp
    // }
  }

  setEmployee(employee: Employee): Promise<Employee> {
    if (!!employee && !!employee._id) {
      return this.request
        .patchResource('employees/' + employee._id, employee)
        .then((updated: Employee) => {
          const index = this.employees.findIndex(emp => emp._id === employee._id)
          if (index >= 0) {
            this.employees[index] = updated
          } else {
            this.employees = [...[updated], ...this.employees]
          }
          return Promise.resolve(updated)
        })
    } else {
      return this.request.postResource('employees', employee).then((updated: Employee) => {
        this.employees = [...[updated], ...this.employees]
        return Promise.resolve(updated)
      })
    }
  }

  deleteEmployee(id: string): Promise<Employee> {
    const index = this.employees.findIndex(dv => dv._id === id)
    this.employees.splice(index, 1)
    return this.request.deleteResource('employees/' + id).then((employee: Employee) => {
      return Promise.resolve(employee)
    })
  }
}
