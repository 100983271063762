<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'PRODUCT'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'categories' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_categories' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceCategory"
        matSort
        #categories="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{ 'name' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let category" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b>{{ category.name }}</b>
              <span *ngIf="category.products" style="font-size: 10px"> ({{ 'click_details' | translate }}) </span>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'amount' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let category">
            <h5 class="m-0">{{ category.amount | currency: currency }}</h5>
            <span
              *ngIf="dataCategoriesToCompare.length > 0"
              [ngClass]="
                compareService.handleCompare(category, dataCategoriesToCompare, 'amount', 'name').includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{ compareService.handleCompare(category, dataCategoriesToCompare, 'amount', 'name') }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'number' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let category">
            <h5 class="m-0">{{ category.number }}</h5>
            <span
              *ngIf="dataCategoriesToCompare.length > 0"
              [ngClass]="
                compareService.handleCompare(category, dataCategoriesToCompare, 'amount', 'name').includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{ compareService.handleCompare(category, dataCategoriesToCompare, 'number', 'name') }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl"
        ></mat-row>
      </mat-table>

      <ng-template #tpl let-element>
        <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
          <div class="method-details">
            <table class="table">
              <tbody *ngFor="let product of element.products">
                <tr>
                  <td>
                    <b>{{ product.name }}</b> ({{ 'product' | translate }})
                  </td>
                  <td>{{ product.number }}</td>
                  <td>{{ product.amount | currency: currency }}</td>
                </tr>
                <tr *ngFor="let variant of product.variants">
                  <td style="color: #aaaaaa">- {{ variant.name }} ({{ 'variant' | translate }})</td>
                  <td style="color: #aaaaaa">{{ variant.number }}</td>
                  <td style="color: #aaaaaa">
                    {{ variant.amount | currency: currency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'PRODUCT'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'brands' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_brands' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceBrand"
        matSort
        #brands="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{ 'name' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let brand" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="brand.name === '<empty>'">Not branded</b>
              <b *ngIf="brand.name !== '<empty>'">{{ brand.name }}</b>
              <span *ngIf="brand.products" style="font-size: 10px"> ({{ 'click_details' | translate }}) </span>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'amount' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let brand">
            <h5 class="m-0">{{ brand.amount | currency: currency }}</h5>
            <span
              *ngIf="dataBrandsToCompare.length > 0"
              [ngClass]="
                compareService.handleCompare(brand, dataCategoriesToCompare, 'amount', 'name').includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{ compareService.handleCompare(brand, dataBrandsToCompare, 'amount', 'name') }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'number' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let brand">
            <h5 class="m-0">{{ brand.number }}</h5>
            <span
              *ngIf="dataBrandsToCompare.length > 0"
              [ngClass]="
                compareService.handleCompare(brand, dataCategoriesToCompare, 'amount', 'name').includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{ compareService.handleCompare(brand, dataBrandsToCompare, 'number', 'name') }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl"
        ></mat-row>
      </mat-table>

      <ng-template #tpl let-element>
        <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
          <div class="method-details">
            <table class="table">
              <tbody *ngFor="let product of element.products">
                <tr>
                  <td>
                    <b>{{ product.name }}</b> ({{ 'product' | translate }})
                  </td>
                  <td>{{ product.number }}</td>
                  <td>{{ product.amount | currency: currency }}</td>
                </tr>
                <tr *ngFor="let variant of product.variants">
                  <td style="color: #aaaaaa">- {{ variant.name }} ({{ 'variant' | translate }})</td>
                  <td style="color: #aaaaaa">{{ variant.number }}</td>
                  <td style="color: #aaaaaa">
                    {{ variant.amount | currency: currency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'PRODUCT'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'discount_percentage' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_discount_percentage' | translate }} {{ 'number_of_discounts' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceDiscount"
        matSort
        #discount="matSort"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{ 'name' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let discount" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="discount.name === '<empty>'">Not branded</b>
              <b *ngIf="discount.name !== '<empty>'">{{ discount.name }}</b>
              <span *ngIf="discount.products" style="font-size: 10px"> ({{ 'click_details' | translate }}) </span>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'amount' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let discount">
            <h5 class="m-0">{{ discount.amount | currency: currency }}</h5>
            <span
              *ngIf="dataCategoriesToCompare.length > 0"
              [ngClass]="
                compareService.handleCompare(discount, dataDiscountToCompare, 'amount', 'name').includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{ compareService.handleCompare(discount, dataDiscountToCompare, 'amount', 'name') }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'number' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let discount">
            <h5 class="m-0">{{ discount.number }}</h5>
            <span
              *ngIf="dataCategoriesToCompare.length > 0"
              [ngClass]="
                compareService.handleCompare(discount, dataDiscountToCompare, 'amount', 'name').includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{ compareService.handleCompare(discount, dataDiscountToCompare, 'number', 'name') }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl"
        ></mat-row>
      </mat-table>

      <ng-template #tpl let-element>
        <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
          <div class="method-details">
            <table class="table">
              <tbody *ngFor="let product of element.products">
                <tr>
                  <td>
                    <b>{{ product.name }}</b> ({{ 'product' | translate }})
                  </td>
                  <td>{{ product.number }}</td>
                  <td>{{ product.amount | currency: currency }}</td>
                </tr>
                <tr *ngFor="let variant of product.variants">
                  <td style="color: #aaaaaa">- {{ variant.name }} ({{ 'variant' | translate }})</td>
                  <td style="color: #aaaaaa">{{ variant.number }}</td>
                  <td style="color: #aaaaaa">
                    {{ variant.amount | currency: currency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card fxLayout="row" fxLayoutWrap="wrap" *ngIf="selectedInsightsType === 'PRODUCT'">
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'discount_numeric' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_discount_numeric' | translate }} {{ 'number_of_discounts' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceDiscountNumeric"
        matSort
        #discountNumeric="matSort"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{ 'name' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let discount" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="discount.name === '<empty>'">Not branded</b>
              <b *ngIf="discount.name !== '<empty>'">{{ discount.name }}</b>
              <span *ngIf="discount.products" style="font-size: 10px"> ({{ 'click_details' | translate }}) </span>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'amount' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let discount">
            <h5 class="m-0">{{ discount.amount | currency: currency }}</h5>
            <span
              *ngIf="dataCategoriesToCompare.length > 0"
              [ngClass]="
                compareService.handleCompare(discount, dataDiscountToCompareNumeric, 'amount', 'name').includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{ compareService.handleCompare(discount, dataDiscountToCompareNumeric, 'amount', 'name') }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'number' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let discount">
            <h5 class="m-0">{{ discount.number }}</h5>
            <span
              *ngIf="dataCategoriesToCompare.length > 0"
              [ngClass]="
                compareService.handleCompare(discount, dataDiscountToCompareNumeric, 'amount', 'name').includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{ compareService.handleCompare(discount, dataDiscountToCompareNumeric, 'number', 'name') }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl"
        ></mat-row>
      </mat-table>

      <ng-template #tpl let-element>
        <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
          <div class="method-details">
            <table class="table">
              <tbody *ngFor="let product of element.products">
                <tr>
                  <td>
                    <b>{{ product.name }}</b> ({{ 'product' | translate }})
                  </td>
                  <td>{{ product.number }}</td>
                  <td>{{ product.amount | currency: currency }}</td>
                </tr>
                <tr *ngFor="let variant of product.variants">
                  <td style="color: #aaaaaa">- {{ variant.name }} ({{ 'variant' | translate }})</td>
                  <td style="color: #aaaaaa">{{ variant.number }}</td>
                  <td style="color: #aaaaaa">
                    {{ variant.amount | currency: currency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>
