<div
  fxLayout="column"
  fxLayoutWrap="wrap"
  fxLayoutAlign="center center"
  class="full-height btn-block"
  *ngIf="isLoading"
>
  <mat-spinner></mat-spinner>
</div>
<div fxFlex="100" [hidden]="isLoading">
  <mat-card>
    <div class="export" *ngIf="shouldPerformAction('export')">
      <button mat-raised-button color="primary" (click)="export()">
        {{ 'export_customer' | translate }}
      </button>
    </div>
    <mat-card-header>
      <div fxLayout="row" fxFlex="100">
        <div fxFlex="50%" fxFlexAlign="center">
          <mat-card-title class="cap">{{ type | translate }}</mat-card-title>
        </div>
        <div fxFlex="50%">
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
            <div *ngIf="shouldPerformAction('search')">
              <mat-form-field>
                <input
                  matInput
                  [formControl]="searchField"
                  placeholder="{{ 'search' | translate }} {{ type | translate }}"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>
            <div *ngIf="shouldPerformAction('delete')" fxLayoutAlign="end center">
              <button
                mat-raised-button
                (click)="openDialog('', 'delete_selected')"
                [disabled]="!selection.length"
              >
                {{ 'delete_selected_items' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container *ngFor="let column of columns" matColumnDef="{{ column }}">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [ngClass]="{ 'double-size': column === 'actions', 'half-size': column === 'select' }"
          >
            <mat-checkbox
              *ngIf="column === 'select' && editRight"
              (change)="selectAllItems($event)"
              [checked]="selection.length === 5"
            >
            </mat-checkbox>
            <span class="cap" *ngIf="column !== 'select'">{{
              column.split('_').join(' ') | translate
            }}</span>
          </mat-header-cell>
          <!-- Default columns -->
          <mat-cell
            *matCellDef="let element"
            class="clickable"
            [ngClass]="{ 'double-size': column === 'actions', 'half-size': column === 'select' }"
            (click)="itemSelected(element._id, column)"
          >
            <mat-checkbox
              *ngIf="column === 'select' && editRight"
              (change)="selectItem($event, element._id)"
              [checked]="itemsIsChecked(element._id)"
            >
            </mat-checkbox>
            <img
              src="{{ element.image }}"
              width="60"
              height="60"
              *ngIf="column === 'image'"
              class="radius"
              (error)="img.src = errorImg"
              #img
            />
            <h5 *ngIf="column === 'name'" class="ellps">{{ element[column] | translate }}</h5>
            <p
              *ngIf="
                column !== 'name' &&
                column !== 'image' &&
                column !== 'actions' &&
                column !== 'price' &&
                column !== 'total' &&
                column !== 'color' &&
                column !== 'invoice_paid' &&
                column !== 'isPaid' &&
                column !== 'currency' &&
                column !== 'city' &&
                column !== 'total_visits' &&
                column !== 'total_value' &&
                column !== 'value_remaining' &&
                column !== 'total_uses' &&
                column !== 'uses_remaining' &&
                type !== 'delivery-notes'
              "
              class="ellps"
            >
              {{ element[column] }}
            </p>
            <p *ngIf="column === 'total_uses' && element[column] > 0" class="ellps">
              {{ element[column] }}
            </p>
            <p *ngIf="column === 'total_uses' && element[column] === 0" class="ellps">
              {{ 'infinite' | translate }}
            </p>
            <p *ngIf="column === 'uses_remaining' && element[column] > 0" class="ellps">
              {{ element[column] }}
            </p>
            <p
              *ngIf="
                column === 'uses_remaining' && element[column] === 0 && element['total_uses'] === 0
              "
              class="ellps"
            >
              {{ 'infinite' | translate }}
            </p>
            <p *ngIf="column === 'color'" [ngStyle]="{ color: element[column] }">
              {{ element[column] }}
            </p>
            <p
              *ngIf="
                column === 'price' ||
                column === 'total' ||
                column === 'total_value' ||
                column === 'value_remaining'
              "
            >
              {{
                element[column]
                  | currency: (type === 'invoices' ? 'EUR' : user?.extras?.currency?.code || 'EUR')
              }}
            </p>
            <p *ngIf="column === 'invoice_paid' && element.payments.length">
              {{ element.payments[0].date }}
            </p>
            <p *ngIf="column === 'isPaid'">
              {{ element.complete ? ('Yes' | translate) : ('No' | translate) }}
            </p>
            <p *ngIf="column === 'currency'">
              {{ element.currency.code }} ({{ element.currency.symbol }})
            </p>
            <p *ngIf="column === 'city'">
              {{ element.address.city }}
            </p>
            <p *ngIf="column === 'total_visits'">
              {{ element.total_visits }}
            </p>
            <p *ngIf="column === 'customer' && type === 'delivery-notes'">
              {{ element.CustomerId }}
            </p>
            <p *ngIf="column === 'Date' && type === 'delivery-notes'">
              {{ parseDate(element.Date) }}
            </p>
            <p *ngIf="column === 'TotalAmountReceipt' && type === 'delivery-notes'">
              {{ element.TotalAmountReceipt }}
            </p>
            <p *ngIf="column === 'receipt-id' && type === 'delivery-notes'">
              {{ element.ReceiptId }}
            </p>
            <!-- Actions columns -->
            <div fxLayout="row" *ngIf="column === 'actions'">
              <button
                mat-icon-button
                matTooltip="{{ 'delete_item' | translate }}"
                matTooltipPosition="above"
                class="action-button"
                (click)="openDialog(element._id, 'delete')"
                [disabled]="!deleteRight"
                *ngIf="shouldPerformAction('delete', element)"
              >
                <mat-icon class="action-button-icon">delete</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Download item"
                matTooltipPosition="above"
                class="action-button"
                (click)="downloadPdf(element)"
                *ngIf="shouldPerformAction('downloadPdf')"
              >
                <mat-icon class="action-button-icon">save_alt</mat-icon>
              </button>
              <button
                class="pay-button"
                mat-raised-button
                (click)="openPaymentInfoModal(element)"
                *ngIf="
                  shouldPerformAction('payInvoice') &&
                  element.paid !== element.total &&
                  element.total > element.paid &&
                  element.code.split('-').length < 2 &&
                  !element.awaiting
                "
              >
                {{ 'pay' | translate }}
              </button>
              <span class="in-progress" *ngIf="element.awaiting && !element.complete" fxFlex="25">
                In progress ...
              </span>

              <button
                mat-icon-button
                matTooltip="{{ 'change_visibility' | translate }}"
                matTooltipPosition="above"
                class="action-button"
                *ngIf="shouldPerformAction('visibility')"
              >
                <mat-icon
                  class="action-button-icon"
                  *ngIf="element.visible"
                  (click)="visibilityResource(element)"
                  >visibility</mat-icon
                >
                <mat-icon
                  class="action-button-icon"
                  *ngIf="!element.visible"
                  (click)="visibilityResource(element)"
                  >visibility_off</mat-icon
                >
              </button>
              <button
                mat-icon-button
                matTooltip="Send email"
                matTooltipPosition="above"
                class="action-button"
                *ngIf="shouldPerformAction('send')"
              >
                <mat-icon class="action-button-icon" (click)="sendEmailResource(element)">
                  email
                </mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="resourceCount"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20]"
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
