import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { FormControl } from '@angular/forms'
import { RequestService } from './../../services/request.service'
import { ResourceDialogComponent } from './resource-dialog.component'
import { StoresService } from '../../services/stores.service'
import { EmployeesService } from '../../services/employees.service'
import { PaymentInfoModalComponent } from '../payment-info-modal/payment-info-modal.component'
import { SavePaymentMethodComponent } from '../save-payment-method/save-payment-method.component'
import { Store } from './../../model/store.model'
import { AuthService } from '../../services/auth.service'
import { AccessRightsService } from '../../services/access_rights.service'
import { uniqBy } from 'lodash'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector: 'resource-table',
  templateUrl: 'resource-table.component.html',
  styleUrls: ['resource-table.component.scss']
})
export class ResourceTableComponent implements OnInit, OnChanges {
  public errorImg = 'assets/images/countr_icon.svg'

  //  Input properties
  @Input()
  type: string
  @Input()
  columns: string[]
  @Input()
  actions: string[]
  @Input()
  shouldResourceRefresh: boolean
  // Output events
  @Output()
  sendClickItem = new EventEmitter<string>()
  @Output()
  customAction = new EventEmitter<string>()

  public selection = []

  private resource: any
  public resourceCount: number
  private skip: number
  public isLoading: boolean
  public user: any
  private canDelete: boolean
  public deleteRight: boolean
  public editRight: boolean
  // Mat table properties
  public dataSource: MatTableDataSource<any>
  @ViewChild(MatPaginator)
  paginator: MatPaginator
  @ViewChild(MatSort)
  sort: MatSort
  public searchField: FormControl

  constructor(
    private request: RequestService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private storesService: StoresService,
    private employeesService: EmployeesService,
    private authService: AuthService,
    private accessRightsService: AccessRightsService
  ) {
    this.initResourceTable()
    this.user = this.authService.getUser()
    this.canDelete = true
  }

  ngOnInit(): void {
    this.deleteRight = this.accessRightsService.canDelete(this.user, this.type)
    this.editRight = this.accessRightsService.canEdit(this.user, this.type)
    this.getResourceCount()
    this.getResource()
    this.searchField = new FormControl()
    this.searchField.valueChanges.pipe(debounceTime(500)).subscribe(term => {
      this.applyFilter(term)
    })
  }

  ngOnChanges(): void {
    if (this.shouldResourceRefresh) {
      this.initResourceTable()
      this.getResourceCount()
      this.getResource()
    }
  }

  initResourceTable() {
    this.resource = []
    this.resourceCount = 0
    this.skip = 0
    this.isLoading = true
  }

  getResourceCount() {
    if(this.type!=='delivery-notes') {
    this.request
      .getResource(this.type === 'invoices' ? 'me/invoices' : this.type + '/count')
      .then((count: number) => {
        this.resourceCount = count
      })
    }
  }

  getResource() {
    let opts={}
    if(this.type==='delivery-notes') {
      opts = {
        merchantId: this.user._id,
        status: 1
      }
    }else{
      opts = {
        limit: 100,
        sort: this.type === 'stores' ? 'name' : '-updated_at',
        skip: this.skip
      }
    }

    this.request
      .getResource(this.type === 'invoices' ? 'me/invoices' : this.type, opts)
      .then((resource: any) => {
        if (this.type === 'products') {
          resource = this.productsTemplate(resource)
        } else if (this.type === 'devices') {
          resource = this.devicesTemplate(resource)
        }

        resource = this.fixDates(resource)

        if(this.type==='delivery-notes') {
          this.resource = uniqBy([...this.resource, ...resource], 'Id')
        } else {
          this.resource = uniqBy([...this.resource, ...resource], '_id')
        }

        this.dataSource = new MatTableDataSource<any>(this.resource)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
          if (typeof data[sortHeaderId] === 'string') {
            return data[sortHeaderId].toLowerCase()
          }
          return data[sortHeaderId]
        }
        this.isLoading = false
      })
  }

  onPageChange(event: PageEvent) {
    if (this.resource.length < this.resourceCount) {
      this.skip += 10
      this.getResource()
    }
  }

  parseDate(dateString){
    const date = new Date(dateString)
    return new Date(dateString).toLocaleString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  applyFilter(value: string) {
    if (value && value.length >= 3) {
      const opts = {
        limit: 10,
        sort: '-updated_at',
        text: value
      }
      this.request.searchResource(this.type, opts).then((resource: any) => {
        if (this.type === 'products' && resource.length) {
          resource = this.productsTemplate(resource)
        }

        resource = this.fixDates(resource)
        this.resource = resource
        this.dataSource = new MatTableDataSource<any>(this.resource)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
        this.dataSource.filter = value.trim().toLowerCase()
      })
    } else if (!value.length) {
      this.getResource()
    }
  }

  fixDates(resource) {
    resource.forEach(res => {
      res.created_at = new Date(res.created_at).toLocaleDateString()
      res.updated_at = new Date(res.updated_at).toLocaleDateString()
      if (this.type === 'invoices') {
        res.invoice_date = new Date(res.invoice_date).toLocaleDateString()
        if (res.payments.length && res.payments[0].date) {
          res.payments[0].date = new Date(res.payments[0].date).toLocaleDateString()
          res.pay = res.payments[0].date
        }
      }
    })

    return resource
  }

  productsTemplate(products) {
    products.forEach(product => {
      product.categories_string = product.categories
        .map(category => (category ? category.name : 'Uncategorized'))
        .join(', ')
      product.tax_string = product.tax.name
    })

    return products
  }

  devicesTemplate(devices) {
    devices.forEach(device => {
      device.store = this.storesService.getStore(device.store).name
    })

    return devices
  }

  deleteResource(id: string) {
    if (this.type === 'stores') {
      this.request.deleteResource(this.type + '/' + id)
      setTimeout(() => {
        this.snackBar.open(this.type + ' deleted.', 'Close', {
          duration: 5000
        })
        this.initResourceTable()
        this.getResourceCount()
        this.getResource()
        this.storesService.fetchStores()
      }, 2000)
    } else {
      this.request.deleteResource(this.type + '/' + id).then(resource => {
        this.snackBar.open(this.type + ' deleted.', 'Close', {
          duration: 5000
        })
        if (this.type === 'employees') {
          this.employeesService.fetchEmployees()
        }

        this.initResourceTable()
        this.getResourceCount()
        this.getResource()
      })
    }
  }

  visibilityResource(item: any) {
    this.request
      .patchResource(this.type + '/' + item._id, { visible: !item.visible })
      .then(resource => {
        this.snackBar.open(this.type + ' visibility changed.', 'Close', {
          duration: 5000
        })
        this.initResourceTable()
        this.getResourceCount()
        this.getResource()
      })
  }

  checkValidityToDelete(id) {
    const store = this.storesService.getStore(id)

    if (!store.devices.length) {
      this.canDelete = true
    } else {
      this.canDelete = false
    }
  }

  checkEmployeeValidityToDelete(id: string) {
    const stores = this.storesService.getStores()
    let canBeDeleted = true

    stores.forEach(store => {
      const isPresent = store.employees.some(emp => emp === id)

      if (isPresent && store?.options?.fiscalTransactions) {
        canBeDeleted = false
      }

      this.canDelete = canBeDeleted
    })
  }

  openDialog(id: string, type: string, name?: string): void {
    if (this.type === 'stores' && type === 'delete') {
      this.checkValidityToDelete(id)
    }

    if (type === 'delete' && this.type === 'employees') {
      this.checkEmployeeValidityToDelete(id)
    }

    const dialogRef = this.dialog.open(ResourceDialogComponent, {
      width: '400px',
      data: { id, type, name, itemType: this.type, canDelete: this.canDelete }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (type === 'delete') {
          this.deleteResource(id)
        } else if (type === 'delete_selected') {
          this.deleteSelected()
        }
      }
    })
  }

  removeKeys(obj: any) {
    const keys = [
      '_id',
      'product_id',
      'category_id',
      'addon_id',
      'addonGroup_id',
      'tax_id',
      'store_id',
      'device_id',
      'created_at',
      'updated_at',
      'categories_string',
      'tax_string'
    ]

    keys.forEach(key => {
      delete obj[key]
    })

    return obj
  }

  deleteSelected() {
    const promises = []
    this.selection.forEach(selected => {
      promises.push(this.request.deleteResource(this.type + '/' + selected))
    })

    Promise.all(promises).then(result => {
      this.snackBar.open(this.selection.length + ' ' + this.type + ' deleted.', 'Close', {
        duration: 5000
      })

      if (this.type === 'employees') {
        this.employeesService.fetchEmployees()
      }
      this.initResourceTable()
      this.getResourceCount()
      this.getResource()
      this.selection = []
    })
  }

  itemSelected(id: string, column: string) {
    if (column === 'select' || column === 'actions') {
      return
    }

    this.sendClickItem.emit(id)
  }

  selectAllItems(event: any) {
    if (event.checked) {
      const pag = this.dataSource.paginator
      const initialIndex = pag.pageSize * pag.pageIndex
      const lastIndex = pag.pageSize * (pag.pageIndex + 1) - 1

      for (let index = initialIndex; index <= lastIndex; index++) {
        this.selection.push(this.resource[index]._id)
      }
    } else {
      this.selection = []
    }
  }

  selectItem(event: any, id: string) {
    if (event.checked) {
      this.selection.push(id)
    } else {
      const index = this.selection.indexOf(id)
      this.selection.splice(index, 1)
    }
  }

  itemsIsChecked(id: string) {
    return this.selection.indexOf(id) >= 0
  }

  shouldPerformAction(action: string, store?: Store): boolean {
    if (action === 'delete' && this.type === 'stores' && !!store && !!store.devices) {
      if (store.extras && store.extras.createdBy) {
        return !store.extras.createdBy.length
      } else return !store.devices.length
    }

    return this.actions.indexOf(action) >= 0
  }

  sendEmailResource(element) {
    this.customAction.emit(element._id)
  }

  downloadPdf(elem) {
    this.request.getResource('me/invoices/' + elem._id + '/pdf').then((invoice: any) => {
      const pdf = 'data:application/pdf;base64,' + invoice.pdfBase64
      const name = invoice.pdfName
      const dlnk = document.createElement('a')
      dlnk.setAttribute('download', name)
      dlnk.href = pdf
      dlnk.click()
    })
  }

  openPaymentInfoModal(elem) {
    if (this.user.billing_info.payment_saved) {
      this.request.getResource('me/metadata').then((data: any) => {
        if (data && data[0] && data[0].payment_details) {
          this.dialog.open(PaymentInfoModalComponent, {
            width: '800px',
            height: '400px',
            data: { invoices: elem, payment_details: data[0].payment_details }
          })
        } else {
          this.dialog.open(SavePaymentMethodComponent, {
            width: '600px',
            data: { invoices: elem }
          })
        }
      })
    } else {
      this.dialog.open(SavePaymentMethodComponent, {
        width: '600px',
        data: { invoices: elem }
      })
    }
  }

  export() {
    this.customAction.emit()
  }
}
