import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

@Component({
  selector: 'auto-complete-filter',
  templateUrl: 'auto-complete-filter.component.html'
})
export class AutoCompleteFilter implements OnInit {
  public myControl = new FormControl()
  public filteredOptions: Observable<string[]>
  @Input() options: any[]
  @Input() key: string
  @Input() key_sub: string
  @Input() title: string
  @Input() initOption: any[]
  @Input() disabled: boolean
  @Output() sendingResult = new EventEmitter()

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    )

    let initialValue = !!this.initOption ? this.initOption[this.key] : this.options[0][this.key]

    if (!!this.key_sub) {
      initialValue += ` - ${
        !!this.initOption ? this.initOption[this.key_sub] : this.options[0][this.key_sub]
      }`
    }

    this.myControl.setValue(initialValue)
  }

  private _filter(value: any) {
    const filterValue =
      typeof value === 'string' ? value.toLowerCase() : value[this.key].toLowerCase()

    return this.options.filter(option => option[this.key].toLowerCase().includes(filterValue))
  }

  public selectOption(option: any) {
    this.myControl.setValue(
      `${option[this.key]}${!!this.key_sub ? ' - ' + option[this.key_sub] : ''}`
    )
    this.sendingResult.emit(option)
  }
}
