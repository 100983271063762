import { Injectable } from '@angular/core'

const colours = {
  black: '#000000',
  grey: '#CCCCCC',
  darkgrey: '#666666',
  darkestgrey: '#333333',
  white: '#FFFFFF',
  darkblue: '#34495E',
  greyblue: '#B6C2CD',
  lightblue: '#00ADEE',
  blue: '#136F8C',
  lightgreen: '#1BBC9B',
  darkgreen: '#26A560',
  purple: '#CA87E6',
  pink: '#F89BBA',
  red: '#E26A6A',
  yellow: '#FFD65C',
  brown: '#B58930'
}

@Injectable()
export class UtilsService {
  constructor() {}

  colourNameToHex(colour: string) {
    if (!colour || colour === null) {
      return colours.greyblue
    }

    if (colour.indexOf('#') >= 0) {
      return colour
    }

    if (typeof colours[colour.toLowerCase()] !== 'undefined') {
      return colours[colour.toLowerCase()]
    } else {
      return colours.greyblue
    }
  }

  handleListUpdate(value, l, id?) {
    const list = l || []

    if (!id && value?._id) {
      const existing = list.find(v => v._id === value._id)
      const result = existing?._id ? list.filter(v => v._id !== value._id) : [...list, value]
      return result || []
    } else if (id && value) {
      const existing = list.find(v => v === value)
      const result = existing ? list.filter(v => v !== value) : [...list, value]
      return result || []
    } else return []
  }
}
