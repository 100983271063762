<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div
  class="user-profile"
  style="
    background: url(assets/images/background/online-store.png) no-repeat;
    background-size: 80% 90%;
    background-position: center;
  "
>
  <!-- User profile image -->
  <div class="profile-img">
    <img *ngIf="accountLogo.length" class="countr-dark-color" src="{{ accountLogo }}" alt="user" />
    <img
      *ngIf="!accountLogo.length"
      class="countr-dark-color"
      src="assets/images/countr_icon.svg"
      alt="user"
    />
  </div>
  <!-- User profile text-->
  <!-- ============================================================== -->
  <!-- Profile - style you can find in header.scss -->
  <!-- ============================================================== -->
  <div class="profile-text">
    <a [matMenuTriggerFor]="sdprofile" class="">
      {{ username }} <i class="ti-angle-down font-12 m-l-5"></i
    ></a>
  </div>
  <mat-menu #sdprofile="matMenu">
    <button mat-menu-item (click)="settings()" *ngIf="!isAltUser">
      <mat-icon>settings</mat-icon> {{ 'settings' | translate }}
    </button>
    <!-- <button mat-menu-item><mat-icon>account_box</mat-icon> Profile</button> -->
    <!-- <button mat-menu-item><mat-icon>notifications_off</mat-icon> Disable notifications</button> -->
    <button mat-menu-item (click)="signout()">
      <mat-icon>exit_to_app</mat-icon> {{ 'sign_out' | translate }}
    </button>
  </mat-menu>
</div>
<mat-nav-list appAccordion>
  <mat-list-item
    appAccordionLink
    *ngFor="let menuitem of menuItems.getMenuitem()"
    routerLinkActive="selected"
    group="{{ menuitem.state }}"
  >
    <a
      class=""
      appAccordionToggle
      [routerLink]="['/', menuitem.state]"
      *ngIf="
        menuitem.type === 'link' &&
        menuitem.state !== 'extensions' &&
        menuitem.state !== 'employees' &&
        menuitem.state !== 'old-dashboard' &&
        menuitem.state !== 'table-layout' &&
        menuitem.state !== 'customer-groups' &&
        menuitem.state !== 'giftcards' &&
        menuitem.state !== 'helpdesk' &&
        menuitem.state !== 'vouchers' &&
        menuitem.state !== 'customers' &&
        menuitem.state !== 'desktop' &&
        !userReporter()
      "
      (click)="tracking(menuitem.name)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a>
    <a
      class=""
      appAccordionToggle
      [routerLink]="['/', menuitem.state]"
      *ngIf="
        (menuitem.state === 'table-layout' ||
          menuitem.state === 'helpdesk' ||
          menuitem.state === 'vouchers' ||
          menuitem.state === 'customers' ||
          menuitem.state === 'desktop' ||
          menuitem.state === 'customer-groups' ||
          menuitem.state === 'giftcards') &&
        checkGrants(menuitem.state)
      "
      (click)="tracking(menuitem.name)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a>

    <a
      class=""
      appAccordionToggle
      [routerLink]="['/', menuitem.state]"
      *ngIf="menuitem.state === 'overview' && userReporter()"
      (click)="tracking(menuitem.name)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a>

    <a
      class=""
      appAccordionToggle
      [routerLink]="['/', menuitem.state]"
      *ngIf="menuitem.state === 'extensions' && !userAlt() && !userReporter() && !isAltUser"
      (click)="tracking(menuitem.name)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a>

    <a
      class=""
      appAccordionToggle
      [routerLink]="['/', menuitem.state]"
      *ngIf="menuitem.state === 'employees' && checkGrants('employees')"
      (click)="tracking(menuitem.name)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a>

    <!-- <a
      href="http://dashboard.countrhq.com"
      target="_blank"
      class=""
      appAccordionToggle
      *ngIf="menuitem.state === 'old-dashboard'"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a> -->

    <a
      class=""
      appAccordionToggle
      href="{{ menuitem.state }}"
      *ngIf="menuitem.type === 'extLink'"
      (click)="tracking(menuitem.name)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a>
    <a
      class=""
      appAccordionToggle
      href="{{ menuitem.state }}"
      target="_blank"
      *ngIf="menuitem.type === 'extTabLink'"
      (click)="tracking(menuitem.name)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a>
    <a
      class=""
      appAccordionToggle
      href="javascript:;"
      *ngIf="menuitem.type === 'sub' && menuitem.state !== 'account' && !userReporter()"
      (click)="tracking(menuitem.name)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>

    <a
      class=""
      appAccordionToggle
      href="javascript:;"
      *ngIf="
        menuitem.type === 'sub' && menuitem.state === 'account' && !userAlt() && !userReporter()
      "
      (click)="tracking(menuitem.name)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>

    <a
      class=""
      appAccordionToggle
      href="javascript:;"
      *ngIf="menuitem.type === 'sub' && menuitem.state === 'sales-history' && userReporter()"
      (click)="tracking(menuitem.name)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>

    <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
      <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="selected">
        <a
          *ngIf="
            childitem.state !== 'categories' &&
            childitem.state !== 'taxes' &&
            childitem.state !== 'addons' &&
            childitem.state !== 'addon-groups' &&
            childitem.state !== 'catalog-order' &&
            childitem.state !== 'business-rules' &&
            childitem.state !== 'devices' &&
            childitem.state !== 'integrations' &&
            childitem.state !== 'invoices' &&
            childitem.state !== 'permissions' &&
            childitem.state !== 'payment-methods' &&
            childitem.state !== 'stock' &&
            childitem.state !== 'import-export'
          "
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <!-- <a
            *ngIf="childitem.state === 'stock'"
            [routerLink]="['/', menuitem.state, childitem.state]"
            class="relative"
            routerLinkActive="selected"
            (click)="tracking(childitem.name)"
            >{{ childitem.name | translate }}</a
          > -->
        <!-- <a
            *ngIf="childitem.state === 'import-export' && !isAltUser"
            [routerLink]="['/', menuitem.state, childitem.state]"
            class="relative"
            routerLinkActive="selected"
            (click)="tracking(childitem.name)"
            >{{ childitem.name | translate }}</a
          > -->
        <a
          *ngIf="childitem.state === 'categories' && checkGrants('categories')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'taxes' && checkGrants('taxes')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'addons' && checkGrants('addons')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'addon-groups' && checkGrants('addon-groups')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'catalog-order' && checkGrants('catalog-order')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'business-rules' && checkGrants('business-rules')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'stock' && checkGrants('stock')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'import-export' && checkGrants('import_export')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'devices' && checkGrants('devices')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'integrations' && checkGrants('integrations')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'payment-methods' && checkGrants('payment-method')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'invoices' && checkGrants('invoices')"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
        <a
          *ngIf="childitem.state === 'permissions' && !isAltUser && hasAltusers"
          [routerLink]="['/', menuitem.state, childitem.state]"
          class="relative"
          routerLinkActive="selected"
          (click)="tracking(childitem.name)"
          >{{ childitem.name | translate }}</a
        >
      </mat-list-item>
    </mat-nav-list>
    <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
      <span>{{ menuitem.name | translate }}</span>
    </div>
  </mat-list-item>
</mat-nav-list>
