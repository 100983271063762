import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Store } from '../model/store.model'

@Injectable()
export class StoresService {
  private stores: Store[]
  private count: number

  constructor(private request: RequestService) {}

  fetchStores(): void {
    this.request.getResource('stores', { limit: 100, sort: 'name' }).then((stores: Store[]) => {
      this.stores = stores.sort(this.storesSort)
      this.count = stores.length
    })
  }

  getStores(): Store[] {
    return Object.assign([], this.stores)
  }

  getStoresCount(): number {
    return this.count
  }

  getStore(id: string): Store {
    return { ...this.stores.find(store => store._id === id) }
  }

  setStore(store: Store): Promise<Store> {
    const index = this.stores.findIndex(st => st._id === store._id)
    if (index >= 0) {
      return this.request.patchResource('stores/' + store._id, store).then((updated: Store) => {
        this.stores[index] = updated
        return Promise.resolve(updated)
      })
    } else {
      return this.request.postResource('stores', store).then((updated: Store) => {
        this.stores = [...[updated], ...this.stores]
        return Promise.resolve(updated)
      })
    }
  }

  deleteStore(id: string): Promise<Store> {
    const index = this.stores.findIndex(t => t._id === id)
    this.stores.splice(index, 1)
    return this.request.deleteResource('stores/' + id).then((store: Store) => {
      return Promise.resolve(store)
    })
  }

  sendEmailTestReceipt(store: any): Promise<Store> {
    return this.request.postResource('me/test-receipt', store).then((updated: Store) => {
      return Promise.resolve(updated)
    })
  }

  storesSort(a, b) {
    // Use toUpperCase() to ignore character casing
    const storeA = a.name.toUpperCase()
    const storeB = b.name.toUpperCase()

    let comparison = 0
    if (storeA > storeB) {
      comparison = 1
    } else if (storeA < storeB) {
      comparison = -1
    }
    return comparison
  }
}
