import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, Router, NavigationEnd } from '@angular/router'
import { filter } from 'rxjs/operators'

@Injectable()
export class LoadingGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // id = 1 is the first route in the stack
        if (event.id === 1) {
          const parent = route.data.parentRoute
          this.router.navigate([parent])
        }
      })

    return true
  }
}
