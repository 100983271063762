import { Component, OnInit, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'resource-dialog',
  templateUrl: 'resource-dialog.component.html'
})
export class ResourceDialogComponent implements OnInit {
  public title: string
  public text: string
  public showInput: boolean
  public modalText: string

  constructor(
    public dialogRef: MatDialogRef<ResourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.showInput = false
  }

  ngOnInit() {
    switch (this.data.type) {
      case 'delete':
        this.title = 'delete_item'
        this.text = 'want_delete_item'
        break
      case 'delete_selected':
        this.title = 'Delete all selected items'
        this.text = 'want_delete_all_items'
        break
      case 'duplicate':
        this.title = 'duplicate_item'
        this.text = 'want_duplicate_item'
        this.showInput = true
        break
      default:
        this.title = ''
        this.text = ''
        break
    }

    switch (this.data.itemType) {
      case 'employees':
        this.modalText = 'employee_unable_delete'
        break
      case 'stores':
        this.modalText = 'store_unable_delete'
        break
      default:
        this.modalText = ''
        break
    }
  }

  cancel(): void {
    this.dialogRef.close(false)
  }

  confirm(): void {
    if (this.data.name) {
      this.dialogRef.close(this.data.name)
    } else {
      this.dialogRef.close(true)
    }
  }
}
