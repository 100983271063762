import { Component, AfterViewInit, OnChanges, Input, ViewChild } from '@angular/core'
import { Report } from '../../model/report.model'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { ProductsService } from '../../services/products.service'

import { log } from 'util'

@Component({
  selector: 'app-product-sellers',
  templateUrl: './product-sellers.component.html',
  styleUrls: ['./product-sellers.component.css']
})
export class ProductSellersComponent implements OnChanges, AfterViewInit {
  @Input()
  report: Report
  @Input()
  currency: string
  @ViewChild(MatSort) sort: MatSort

  private topProducts: any = []
  dataSource: MatTableDataSource<any>
  public displayedColumns = ['product', 'amount', 'revenue']

  constructor(private productService: ProductsService) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort
  }

  ngOnChanges() {
    this.toplist()
  }

  toplist() {
    if (this.report && this.report.content) {
      let report = this.report
      let currency = this.currency
      let toplist = []

      if (report.content && report.content[currency]) {
        let toplist_details = ((report.content[currency].toplists || {}).products || {}).details
        for (var key in toplist_details) {
          if (toplist_details.hasOwnProperty(key)) {
            toplist.push(toplist_details[key])
          }
        }
        toplist = toplist.sort(function sort(a, b) {
          return b.amount > a.amount ? 1 : b.amount < a.amount ? -1 : 0
        })
        toplist = toplist.slice(0, 10)
        toplist.forEach((item, index) => {
          toplist[index].revenue = toplist[index].total.toFixed(2)
        })
        this.topProducts = toplist
        this.dataSource = new MatTableDataSource(this.topProducts)
      }
    }
  }
}
