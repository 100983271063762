<!-- report of the previous period -->
<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap="wrap" class="compare-button">
  <button mat-flat-button (click)="lastPeriodReport()" color="primary">Last {{ newPeriod }} report</button>
  <div class="divider"></div>
  <button mat-stroked-button (click)="openDialog(period, dateObj, device, employee, store)" color="primary">
    {{ 'create_compare_report' | translate }}
  </button>
</div>
<div
  fxLayout="row"
  fxLayoutWrap="wrap"
  class="change-currency"
  *ngIf="newCurrency && newCurrency.length > 1 && !selectedCurrency"
>
  <h4>{{ 'select' | translate }} {{ 'currency' | translate }}</h4>
  <mat-form-field>
    <mat-select placeholder="Select Currency">
      <mat-option
        *ngFor="let currency of newCurrency"
        (onSelectionChange)="changeSelectedCurrency($event)"
        [value]="currency"
      >
        {{ currency.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div fxLayout="column" fxLayoutWrap="wrap" *ngIf="!currentReport">
  <p *ngIf="isLastPeriod">
    <b>"{{ 'last_per' | translate }} {{ newPeriod | translate }} " {{ 'no_per_data' | translate }}</b>
  </p>
  <div fxFlex.gt-md="100" fxLayoutAlign="center center" class="no-report-image">
    <img src="assets/images/call-report-icon-3.png" alt="" class="reports-logo" />
  </div>
  <div fxFlex.gt-md="100" fxLayoutAlign="center center" class="no-report-header">
    <h2>{{ 'no_data_for_this_period' | translate }}</h2>
  </div>

  <div fxFlex.gt-md="100" fxLayoutAlign="center center" class="no-report-text">
    <h4>{{ 'report_select_other_period' | translate }}</h4>
  </div>
</div>
<data-table-sales
  *ngIf="insightsType === 'SALES' && currentReport"
  [report]="currentReport"
  [currency]="currency"
  [selectedDay]="selectedDay"
  [compareReport]="true"
></data-table-sales>
<data-table-product
  *ngIf="insightsType === 'PRODUCT' && currentReport"
  [report]="currentReport"
  [currency]="currency"
  [selectedDay]="selectedDay"
  [compareReport]="true"
></data-table-product>
<data-table-store
  *ngIf="insightsType === 'STORE' && currentReport"
  [report]="currentReport"
  [currency]="currency"
  [selectedDay]="selectedDay"
  [compareReport]="true"
></data-table-store>
