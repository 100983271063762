export enum NotificationType {
  INFO = 'round-info',
  DANGER = 'round-danger',
  SUCCESS = 'round-success',
  PRIMARY = 'round-primary'
}

export enum NotificationIcon {
  USER = 'ti-user',
  LINK = 'ti-link',
  CALENDAR = 'ti-calendar',
  SETTINGS = 'ti-settings'
}

export interface Notification {
  round: NotificationType
  icon: NotificationIcon
  title: string
  subject: string
  time?: string
  action: any
}
