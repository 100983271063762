<mat-card>
  <mat-card-header>
    <mat-card-title
      ><i class="material-icons"> trending_down </i>
      {{ 'non_selling_products_this_period' | translate }}</mat-card-title
    >
  </mat-card-header>
  <mat-divider></mat-divider>
  <!-- <mat-card-content> -->
  <table mat-table [dataSource]="dataSource" style="width: 100%">
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>
        <h4>{{ 'product' | translate }}</h4>
      </th>
      <td mat-cell *matCellDef="let element">
        <b>{{ element.object.name }}</b>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- </mat-card-content> -->
</mat-card>
