import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../services/auth.service'
import { RequestService } from '../../../services/request.service'
import { InvoiceService } from './../../../services/invoices.service'
import { Notification, NotificationType, NotificationIcon } from '../../../model/notification.model'
import { MatDialog } from '@angular/material/dialog'
import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { GoLivePreAuthModalComponent } from '../go-live/go-live-pre-auth/go-live-pre-auth-modal.component'
import { GoToPaymentComponent } from '../go-live/go-to-payment/go-to-payment.component'
import { BusinessInformationModalComponent } from '../go-live/business-information-modal/business-information-modal.component'
import { PricingTablesComponent } from '../../../auth/sign-up/pricing-tables/pricing-tables.component'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./../full.component.scss']
})
export class AppHeaderComponent implements OnInit {
  public notifications: Notification[] = []
  private trialSubscription: any
  private gettingLive: boolean
  public trialDays: string
  private user: any
  public checkTypeUser: any

  constructor(
    private authService: AuthService,
    private invoiceService: InvoiceService,
    private dialog: MatDialog,
    private request: RequestService
  ) {}

  ngOnInit() {
    this.checkTypeUser = this.authService.getUser().__t
    const trial = this.authService.getTrialEvent()
    
    if (trial) {
      const trialExpires = this.authService.getTrialExpireDate()
      dayjs.extend(relativeTime)
      this.trialDays = dayjs(trialExpires).fromNow()
      const notObjTrial: Notification = {
        round: NotificationType.INFO,
        icon: NotificationIcon.USER,
        title: 'Go Live',
        subject: 'trial_expires',
        action: () => {
          this.openDialog()
        }
        // time: '10:00 AM'
      }
      this.notifications.push(notObjTrial)
    }
}

  async openDialog() {
    let invoices = this.invoiceService.getInvoices()
    await this.request.getResource('me').then(users => {
      if (users) {
        return (this.user = users)
      }
    })
    if (
      this.user &&
      this.user.billing_info &&
      this.user.billing_info.registration &&
      !invoices.length &&
      !this.gettingLive
    ) {
      const dialogRef = this.dialog.open(PricingTablesComponent, {
        width: '60%'
      })

      dialogRef.afterClosed().subscribe(extension => {
        if (extension && extension._id) {
          const dialogRefGoLive = this.dialog.open(GoLivePreAuthModalComponent, {
            width: '60%',
            data: { extension: extension }
          })
        }
      })
      // dialogRef.afterClosed().subscribe(result => {
      //   this.gettingLive = result.getPackage
      // })
    } else if (invoices.length || this.gettingLive) {
      if (!invoices.length) {
        await this.invoiceService.fetchInvoices()
        invoices = this.invoiceService.getInvoices()
        const dialogRef = this.dialog.open(GoToPaymentComponent, {
          width: '70%',
          data: { invoices: invoices }
        })
      } else {
        const dialogRef = this.dialog.open(GoToPaymentComponent, {
          width: '70%',
          data: { invoices: invoices }
        })
      }
    } else {
      const dialogRef = this.dialog.open(BusinessInformationModalComponent, {
        width: '70%',
        data: { user: this.user }
      })
    }
  }
}
