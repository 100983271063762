import { Component, Input, OnChanges, ViewChild, AfterViewInit } from '@angular/core'
import { Report } from '../../model/report.model'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'

@Component({
  selector: 'sales-period',
  templateUrl: 'sales-period.component.html'
})
export class SalesPeriodComponent implements OnChanges, AfterViewInit {
  @Input()
  report: Report
  @Input()
  currency: string
  @Input()
  type: string
  @ViewChild(MatSort) sort: MatSort
  @ViewChild('methods') methods: MatSort

  public initialReportDate: string
  public finalReportDate: string
  public transactionMethods: any[]
  public title: string

  constructor() {
    this.transactionMethods = []
    this.title = ''
  }
  public displayedColumnsReports = ['name', 'number', 'amount']
  dataTransactionMethods: MatTableDataSource<any>

  ngAfterViewInit() {
    if (this.dataTransactionMethods) this.dataTransactionMethods.sort = this.methods
  }

  ngOnChanges() {
    if (this.report) {
      this.initialReportDate = new Date(this.report.start_date).toLocaleDateString()
      this.finalReportDate = new Date(this.report.end_date).toLocaleDateString()
      this.extractReportData()
    }

    if (this.type) {
      this.title = this.getTitle(this.type)
    }
  }

  getTitle(type: string): string {
    if (type.indexOf('transaction') >= 0) {
      return 'transactions'
    } else if (type.indexOf('payment') >= 0) {
      return 'payments'
    } else if (type.indexOf('refund') >= 0) {
      return 'refunds'
    } else {
      return ''
    }
  }

  extractReportData() {
    let amount = 0
    let number = 0
    this.transactionMethods = []

    if (
      this.report.content &&
      this.report.content[this.currency] &&
      this.report.content[this.currency].tables[this.type]
    ) {
      // Getting all payments methods and its values
      Object.keys(this.report.content[this.currency].tables[this.type].details).forEach(key => {
        if (key !== 'names') {
          const details = this.report.content[this.currency].tables[this.type].details[key]
          if (['card', 'online'].indexOf(key) < 0) {
            this.transactionMethods.push({
              name: key,
              amount: details.amount,
              number: details.number
            })
          } else {
            Object.keys(details.details).forEach(card => {
              this.transactionMethods.push({
                name: card === 'MANUAL_CARD_PAYMENT' ? 'Manual card payment' : card,
                amount: details.details[card].amount,
                number: details.details[card].number
              })
            })
          }
        }
      })
    }
    this.dataTransactionMethods = new MatTableDataSource(this.transactionMethods)
  }

  handleTotale(value) {
    let result = 0
    this.transactionMethods.forEach(method => {
      if (method.name !== 'total cash after tips and change') result = result + method[value]
    })
    return result
  }
}
