<mat-card
  fxLayout="row"
  fxLayoutWrap="wrap"
  *ngIf="selectedInsightsType === 'STORE' && !this.employeeReport && !this.customerReport"
>
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'busiest_hours' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_busiest_hours' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceBusiestHours"
        matSort
        #busiestHours="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'time' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let hour" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="hour.name === '<empty>'">Unknown</b>
              <b *ngIf="hour.name !== '<empty>'">{{ hour.time }}:00</b>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let hour">
            <h5 class="m-0">{{ hour.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceBusiestHoursToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(hour, dataSourceBusiestHoursToCompare, 'amount', 'time')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  hour,
                  dataSourceBusiestHoursToCompare,
                  'amount',
                  'time'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let hour">
            <h5 class="m-0">{{ hour.number }}</h5>
            <span
              *ngIf="dataSourceBusiestHoursToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(hour, dataSourceBusiestHoursToCompare, 'number', 'time')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  hour,
                  dataSourceBusiestHoursToCompare,
                  'number',
                  'time'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl3"
        ></mat-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<div
  *ngIf="
    selectedInsightsType === 'STORE' &&
    !this.compareReport &&
    !this.employeeReport &&
    !this.customerReport &&
    this.selectedDay === 'average' &&
    !this.isDayReport
  "
>
  <mat-card
    fxLayout="row"
    fxLayoutWrap="wrap"
    *ngFor="let day of dataSourceBusiestHoursDays.filteredData"
  >
    <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      <mat-expansion-panel hideToggle expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> {{ 'busiest_hours' | translate }} {{ day.day }} </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

        <mat-table
          #table
          [dataSource]="day.hours"
          matSort
          #busiestHoursDay="matSort"
          matSortActive="time"
          matSortDirection="desc"
          matSortDisableClear
        >
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
              'time' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let hour" [ngClass]="'customWidthClass'">
              <h5 class="m-0 cap">
                <b *ngIf="hour.name === '<empty>'">Unknown</b>
                <b *ngIf="hour.name !== '<empty>'">{{ hour.hour + utcOffset }}:00</b>
              </h5>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef>{{ 'amount' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let hour">
              <h5 class="m-0">{{ hour.amount | currency: currency }}</h5>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef>{{ 'number' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let hour">
              <h5 class="m-0">{{ hour.number }}</h5>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsReports"
            matRipple
            class="element-row"
            [cdkDetailRow]="row"
            [cdkDetailRowTpl]="tpl3"
          ></mat-row>
        </mat-table>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
</div>

<mat-card
  fxLayout="row"
  fxLayoutWrap="wrap"
  *ngIf="selectedInsightsType === 'STORE' && (this.employeeReport || this.compareReport)"
>
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'employee' | translate }} {{ 'transactions' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_employee_transactions' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceEmployeeInsightsTrans"
        matSort
        #employeeTransactions="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="employee.name === '<empty>'">Unknown</b>
              <b *ngIf="employee.name !== '<empty>'">{{ employee.name }}</b>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee">
            <h5 class="m-0">{{ employee.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceEmployeeInsightsTransToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(
                    employee,
                    dataSourceEmployeeInsightsTransToCompare,
                    'amount',
                    'name'
                  )
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  employee,
                  dataSourceEmployeeInsightsTransToCompare,
                  'amount',
                  'name'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee">
            <h5 class="m-0">{{ employee.number }}</h5>
            <span
              *ngIf="dataSourceEmployeeInsightsTransToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(
                    employee,
                    dataSourceEmployeeInsightsTransToCompare,
                    'number',
                    'name'
                  )
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  employee,
                  dataSourceEmployeeInsightsTransToCompare,
                  'number',
                  'name'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl3"
        ></mat-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card
  fxLayout="row"
  fxLayoutWrap="wrap"
  *ngIf="selectedInsightsType === 'STORE' && (this.employeeReport || this.compareReport)"
>
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'employee' | translate }} {{ 'reps_waste' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_employee_reps_waste' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceEmployeeInsightsRepsWaste"
        matSort
        #employeeReps="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="employee.name === '<empty>'">Unknown</b>
              <b *ngIf="employee.name !== '<empty>'">{{ employee.name }}</b>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee">
            <h5 class="m-0">{{ employee.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceEmployeeInsightsRepsWasteToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(
                    employee,
                    dataSourceEmployeeInsightsRepsWasteToCompare,
                    'amount',
                    'name'
                  )
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  employee,
                  dataSourceEmployeeInsightsRepsWasteToCompare,
                  'amount',
                  'name'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee">
            <h5 class="m-0">{{ employee.number }}</h5>
            <span
              *ngIf="dataSourceEmployeeInsightsRepsWasteToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(
                    employee,
                    dataSourceEmployeeInsightsRepsWasteToCompare,
                    'number',
                    'name'
                  )
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  employee,
                  dataSourceEmployeeInsightsRepsWasteToCompare,
                  'number',
                  'name'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl3"
        ></mat-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card
  fxLayout="row"
  fxLayoutWrap="wrap"
  *ngIf="selectedInsightsType === 'STORE' && (this.employeeReport || this.compareReport)"
>
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'employee' | translate }} {{ 'refunds' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_employee_refunds' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceEmployeeInsightsRefs"
        matSort
        #employeeRefunds="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="employee.name === '<empty>'">Unknown</b>
              <b *ngIf="employee.name !== '<empty>'">{{ employee.name }}</b>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee">
            <h5 class="m-0">{{ employee.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceEmployeeInsightsRefsToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(
                    employee,
                    dataSourceEmployeeInsightsRefsToCompare,
                    'amount',
                    'name'
                  )
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  employee,
                  dataSourceEmployeeInsightsRefsToCompare,
                  'amount',
                  'name'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee">
            <h5 class="m-0">{{ employee.number }}</h5>
            <span
              *ngIf="dataSourceEmployeeInsightsRefsToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(
                    employee,
                    dataSourceEmployeeInsightsRefsToCompare,
                    'number',
                    'name'
                  )
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  employee,
                  dataSourceEmployeeInsightsRefsToCompare,
                  'number',
                  'name'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl3"
        ></mat-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card
  fxLayout="row"
  fxLayoutWrap="wrap"
  *ngIf="selectedInsightsType === 'STORE' && (this.employeeReport || this.compareReport)"
>
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'employee' | translate }} {{ 'tips' | translate }} </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_employee_tips' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceEmployeeInsightsTips"
        matSort
        #employeeTips="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="employee.name === '<empty>'">Unknown</b>
              <b *ngIf="employee.name !== '<empty>'">{{ employee.name }}</b>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'amount' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee">
            <h5 class="m-0">{{ employee.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceEmployeeInsightsTipsToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(
                    employee,
                    dataSourceEmployeeInsightsTipsToCompare,
                    'amount',
                    'name'
                  )
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  employee,
                  dataSourceEmployeeInsightsTipsToCompare,
                  'amount',
                  'name'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let employee">
            <h5 class="m-0">{{ employee.number }}</h5>
            <span
              *ngIf="dataSourceEmployeeInsightsTipsToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(
                    employee,
                    dataSourceEmployeeInsightsTipsToCompare,
                    'number',
                    'name'
                  )
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  employee,
                  dataSourceEmployeeInsightsTipsToCompare,
                  'number',
                  'name'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl3"
        ></mat-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card
  fxLayout="row"
  fxLayoutWrap="wrap"
  *ngIf="selectedInsightsType === 'STORE' && (this.customerReport || this.compareReport)"
>
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'customer' | translate }} {{ 'transactions' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_customer_transactions' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceCustomerInsightsTran"
        matSort
        #customerTransactions="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let customer" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="customer.type === '<empty>'">Unknown</b>
              <b *ngIf="customer.type !== '<empty>'">{{ customer.type | translate }}</b>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'average_amount_spent' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let customer">
            <h5 class="m-0">{{ customer.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceCustomerInsightsTranToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(
                    customer,
                    dataSourceCustomerInsightsTranToCompare,
                    'amount',
                    'type'
                  )
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  customer,
                  dataSourceCustomerInsightsTranToCompare,
                  'amount',
                  'type'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let customer">
            <h5 class="m-0">{{ customer.number }}</h5>
            <span
              *ngIf="dataSourceCustomerInsightsTranToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(
                    customer,
                    dataSourceCustomerInsightsTranToCompare,
                    'number',
                    'type'
                  )
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  customer,
                  dataSourceCustomerInsightsTranToCompare,
                  'number',
                  'type'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl3"
        ></mat-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<mat-card
  fxLayout="row"
  fxLayoutWrap="wrap"
  *ngIf="selectedInsightsType === 'STORE' && (this.customerReport || this.compareReport)"
>
  <mat-accordion class="Transactions" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-expansion-panel hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'customer' | translate }} {{ 'refunds' | translate }}
        </mat-panel-title>
        <mat-panel-description class="hide-text">
          {{ 'see_customer_refunds' | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>

      <mat-table
        #table
        [dataSource]="dataSourceCustomerInsightsRef"
        matSort
        #customerRefunds="matSort"
        matSortActive="number"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{
            'name' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let customer" [ngClass]="'customWidthClass'">
            <h5 class="m-0 cap">
              <b *ngIf="customer.type === '<empty>'">Unknown</b>
              <b *ngIf="customer.type !== '<empty>'">{{ customer.type | translate }}</b>
            </h5>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'average_amount_refunded' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let customer">
            <h5 class="m-0">{{ customer.amount | currency: currency }}</h5>
            <span
              *ngIf="dataSourceCustomerInsightsRefToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(customer, dataSourceCustomerInsightsRefToCompare, 'amount', 'type')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  customer,
                  dataSourceCustomerInsightsRefToCompare,
                  'amount',
                  'type'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'number' | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let customer">
            <h5 class="m-0">{{ customer.number }}</h5>
            <span
              *ngIf="dataSourceCustomerInsightsRefToCompare.length > 0"
              [ngClass]="
                compareService
                  .handleCompare(customer, dataSourceCustomerInsightsRefToCompare, 'number', 'type')
                  .includes('-')
                  ? 'decrease-persent'
                  : 'increase-persent'
              "
              #classResult
              >{{
                compareService.handleCompare(
                  customer,
                  dataSourceCustomerInsightsRefToCompare,
                  'number',
                  'type'
                )
              }}</span
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsReports"
          matRipple
          class="element-row"
          [cdkDetailRow]="row"
          [cdkDetailRowTpl]="tpl3"
        ></mat-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>
