import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../services/auth.service'
import { User } from '../../../model/user.model'

@Component({
    selector: 'pop-up-box',
    templateUrl: './pop-up-box.component.html',
    styleUrls: ['./../full.component.scss']
})

export class PopUpBox implements OnInit {
    user: User
    public showMessage: boolean
    constructor(private authService:AuthService) {
        this.showMessage = false
    }

    ngOnInit() {
        this.user = this.authService.getUser()
    }
}