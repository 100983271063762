import { Component, Input, OnChanges } from '@angular/core'
import { Report } from '../../model/report.model'
import { log } from 'util'

@Component({
  selector: 'report-compare',
  templateUrl: 'report-compare.component.html'
})
export class ReportCompareComponent implements OnChanges {
  @Input()
  current: Report
  @Input()
  last: Report
  @Input()
  period: string
  @Input()
  error: string
  @Input()
  initial: string
  @Input()
  final: string
  private income: number
  private lastIncome: number
  public currency: string
  public diff: number
  public diffIncome: number

  constructor() {
    this.income = 0.0
    this.diff = 0
    this.currency = ''
  }

  ngOnChanges() {
    if (this.current) {
      this.currency = Object.keys(this.current.content)[0]
      this.income = this.current.content[this.currency].tables.revenue_summary.summary.amount

      if (this.last && this.last.content) {
        this.lastIncome =
          Object.keys(this.last.content).length > 0
            ? this.last.content[this.currency].tables.revenue_summary.summary.amount
            : 0
        this.initial = new Date(this.initial).toLocaleDateString()
        this.diffIncome = this.income - this.lastIncome
        this.diff = this.calculateDiff()
      }
    }

    if (this.error) {
      console.log(this.error)
    }
  }

  calculateDiff() {
    return parseFloat(((this.income / this.lastIncome) * 100).toFixed(2))
  }
}
