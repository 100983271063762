import { Component, Input, OnInit, OnDestroy, Inject, ViewEncapsulation } from '@angular/core'
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'
import { DOCUMENT } from '@angular/common'

@Component({
  selector: 'app-spinner',
  template: `<div class="preloader" *ngIf="isSpinnerVisible" [ngClass]="{'preloader-dark': isDarkMode}">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
    </div>`,
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit, OnDestroy {
  public isSpinnerVisible = true
  private isDarkMode = false

  @Input()
  public backgroundColor = 'rgba(0, 115, 170, 0.69)'

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isSpinnerVisible = false
        }
      },
      () => {
        this.isSpinnerVisible = false
      }
    )
  }

  ngOnInit(): void {
    let d = localStorage.getItem('countr-dashboard:theme')
    this.isDarkMode = d === 'dark' ? true : false
  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false
  }
}
