<div class="go-to-payment-container">
  <div class="go-to-payment-title">
    <h4>{{ 'procced_paymet' | translate }}</h4>
  </div>
  <mat-divider></mat-divider>
  <div *ngIf="selectedPackage[0].slug === 'live-advanced-transaction' && !extensions.length">
    <p><span style="color: red; font-weight: 700"> {{ 'important_note' | translate}}</span> {{'transaction_note' | translate}}</p>
  </div>
  <div class="payment-content" *ngIf="selectedPackage.length || data.invoices">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="100">
        <p>
          {{ 'selected_package' | translate }}: <b>{{ selectedPackage[0].name }}</b
          ><br />
          <span class="small-text"
            >{{ 'description' | translate }}:
            <b>{{ selectedPackage[0].infos.description }}</b></span
          >
        </p>
        <div class="extensions">
          <p>
            {{ 'selected_ext' | translate }}:
            <span class="small-text" *ngFor="let ext of extensions">
              <li>
                <b>{{ ext.name }}</b>
              </li></span
            >
          </p>
        </div>
        <p *ngIf="data.invoices">
          {{ 'price' | translate }}: <b>{{ total_price }}</b> /
          <b>{{ data.invoices[0].type }}</b> ({{ 'excluse_vat' | translate }})
        </p>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions>
    <!-- <button mat-button (click)="onNoClick()">{{ 'no_thanks' | translate }}</button> -->
    <button mat-button (click)="pay()" cdkFocusInitial>{{ 'procced_paymet' | translate }}</button>
  </div>
</div>
