import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams, HttpParameterCodec } from '@angular/common/http'
import { RequestService } from './request.service'
import { environment } from '../../environments/environment'
import { Token } from './../model/token.model'
import { User, BillingInfo } from '../model/user.model'
import UAParser from 'ua-parser-js'

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key)
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value)
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key)
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value)
  }
}

@Directive()
@Injectable()
export class AuthService {
  private auth: boolean
  private user: User
  private token: string
  @Output() private userIsLoggedIn = new EventEmitter<User>()

  constructor(private http: HttpClient) {
    this.auth = false
  }

  hasToken() {
    return localStorage.getItem('countr-dashboard:access_token') !== null
  }

  getToken() {
    return !!this.token ? this.token : localStorage.getItem('countr-dashboard:access_token')
  }

  setToken(token: string) {
    this.token = token
    localStorage.setItem('countr-dashboard:access_token', token)
  }

  clearToken() {
    this.token = null
  }

  getRefreshToken() {
    return localStorage.getItem('countr-dashboard:refresh_token')
  }

  setRefreshToken(token: string) {
    localStorage.setItem('countr-dashboard:refresh_token', token)
  }

  isAuth() {
    return this.auth
  }

  getUser(): User {
    return { ...this.user }
  }

  isAltUser(): boolean {
    return this.user.__t === 'Altuser'
  }

  getUserEvent() {
    return this.userIsLoggedIn
  }

  getTrialEvent() {
    const user = this.getUser()
    const result =  user.trial_expires_at && user.trial_expires_at !== null
    return result
  }

  userName(user): string {
    const contact = user.contact || {}
    return `${contact.first_name || ''} ${contact.middle_name || ''} ${
      contact.last_name || ''
    }`.trim()
  }

  getTrialExpireDate(): Date {
    const user = this.getUser()
    return new Date(user.trial_expires_at)
  }

  userTraits() {
    const user = this.getUser()
    const uaParser = new UAParser(navigator.userAgent).getResult()
    return {
      _id: user._id,
      __t: user.__t,
      email: user.email,
      username: user.username,
      name: this.userName(user),
      signed_up: user.created_at,
      last_seen: user.last_login,
      ecommerce_type: user.integration || 'none',
      signup_source: user.signup_source,
      // merchant_id: user.merchant_id,
      language: navigator.language,
      // phone: (user.contact || {}).phone || '',
      trial_expires_at: user.trial_expires_at,
      // organization: (user.billing_info || {}).organization || '',
      // altuser: user.altuser,
      // ...window.__analytics__ua__ || {}
      ...uaParser
    }
  }

  updateBillingInfo(billing: BillingInfo) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getToken())
    headers.set('Content-Type', 'application/json')

    return this.http
      .patch(`${environment.api_server}me`, billing, { headers })
      .toPromise()
      .then(
        (bill: BillingInfo) => {
          return Promise.resolve(true)
        },
        error => {
          return Promise.reject(false)
        }
      )
  }

  setPaymentSaved(type) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getToken())
    headers.set('Content-Type', 'application/json')

    return this.http
      .patch(`${environment.api_server}me`, { 'billing_info.payment_saved': type }, { headers })
      .toPromise()
      .then(
        (bill: BillingInfo) => {
          return Promise.resolve(true)
        },
        error => {
          return Promise.reject(false)
        }
      )
  }

  checkLogin() {
    if (this.hasToken()) {
      const token = this.getToken()
      this.setToken(token)

      return this.http
        .get(`${environment.api_server}me`)
        .toPromise()
        .then(
          (user: User) => {
            this.auth = true
            if (user && !user.extras) {
              user.extras = {
                currency: {}
              }
            }
            this.user = user
            this.userIsLoggedIn.emit(user)
            const trial = user.trial_expires_at && user.trial_expires_at !== null
            if (user && user.__t === 'Merchant') {
              if (user.extras && user.extras.support_message) {
                window.analytics &&
                  window.analytics.identify(user._id, this.userTraits(), {
                    integrations: { All: false, Mixpanel: true, Intercom: false }
                  })
              } else {
                window.analytics &&
                  window.analytics.identify(user._id, this.userTraits(), {
                    integrations: { All: false, Mixpanel: true, Intercom: true }
                  })
              }
            } else if (user && user.__t === 'Altuser') {
              window.analytics &&
                window.analytics.identify(user._id, this.userTraits(), {
                  integrations: { All: false, Mixpanel: true, Intercom: false }
                })
            } else if (user && user.__t === 'Reseller') {
              this.auth = false
              this.userIsLoggedIn.emit(null)
            }
            return Promise.resolve(true)
          },
          error => {
            this.auth = false
            return Promise.reject(false)
          }
        )
    } else {
      this.auth = false
      return Promise.reject({
        status: 'no_token',
        message: 'Auto login failed! Please try to login with your credentials.'
      })
    }
  }

  login(username: string, password: string): any {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    const params = new HttpParams({ encoder: new CustomEncoder() })
      .set('username', username)
      .set('password', password)
      .set('client_id', environment.client_id)
      .set('client_secret', environment.client_secret)
      .set('grant_type', environment.grant_type)

    return this.http
      .post(`${environment.api_server}oauth/token`, params, { headers })
      .toPromise()
      .then(
        (token: Token) => {
          this.auth = true
          this.setToken(token.access_token)
          this.setRefreshToken(token.refresh_token)

          // adding user to the service
          return this.http
            .get(`${environment.api_server}me`)
            .toPromise()
            .then((user: User) => {
              this.user = user
              this.userIsLoggedIn.emit(user)

              if (user && user.__t === 'Merchant') {
                window.analytics &&
                  window.analytics.identify(user._id, this.userTraits(), {
                    integrations: { All: false, Mixpanel: true, Intercom: true }
                  })
              } else if (user && user.__t === 'Altuser') {
                window.analytics &&
                  window.analytics.identify(user._id, this.userTraits(), {
                    integrations: { All: false, Mixpanel: true, Intercom: false }
                  })
              } else if (user && user.__t === 'Reseller') {
                this.auth = false
                this.userIsLoggedIn.emit(null)
              }
              return Promise.resolve(token)
            })
        },
        error => {
          this.auth = false
          return Promise.reject(error)
        }
      )
  }

  signUp(body) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')

    const credentials = {
      username: body.email,
      password: body.password,
      signup_source: 'dashboard'
    }

    return this.http.post(`${environment.api_server}merchants`, { body }, { headers }).toPromise()
  }

  signout() {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('countr-dashboard:access_token')
      localStorage.removeItem('countr-dashboard:refresh_token')
      this.auth = false
      this.user = undefined
      this.userIsLoggedIn.emit(undefined)
      window.analytics && window.analytics.reset()
      return resolve(true)
    })
  }
}
