import { Component, OnChanges, ViewChild, AfterViewInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { Report } from '../../../../model/report.model'
import { Input } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { CompareReportService } from '../../../../services/compare-reports.service'
import { MatSort } from '@angular/material/sort'

@Component({
  selector: 'data-table-product',
  templateUrl: './data-table-product.component.html',
  styleUrls: ['../data-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class DataTableProductComponent implements OnChanges, AfterViewInit {
  @Input()
  report: Report
  @Input()
  isReportReady: boolean
  @Input()
  currency: string
  @Input()
  insightsType: string
  @Input()
  employeeReport: boolean
  @Input()
  customerReport: boolean
  @Input()
  compareReport: boolean
  @Input()
  selectedDay: any
  @ViewChild(MatSort) sort: MatSort
  @ViewChild('categories') categories: MatSort
  @ViewChild('brands') brands: MatSort
  @ViewChild('discount') discount: MatSort
  @ViewChild('discountNumeric') discountNumeric: MatSort

  constructor(public compareService: CompareReportService) {}
  public displayedColumnsReports = ['name', 'number', 'amount']
  public displayedColumnsTaxes = ['name', 'amount', 'price']
  private data: any = []
  private dataCategories: any = []
  public dataCategoriesToCompare: any = []
  public dataBrands: any = []
  public dataBrandsToCompare: any = []
  public dataProductDiscount: any = []
  public dataProductDiscountNumeric: any = []
  public dataDiscountToCompare: any = []
  public dataDiscountToCompareNumeric: any = []
  private dataChannels: any = []
  private names: any = []
  public initialReportDate: string
  public finalReportDate: string
  public displayedColumns = ['revenue', 'amount']
  public selectedInsightsType: string
  public totalRepsAndWaste: any
  private utcOffset: number
  private isAverage: boolean
  private isDayReport: boolean

  dataSourceCategory: MatTableDataSource<any>
  dataSourceBrand: MatTableDataSource<any>
  dataSourceDiscount: MatTableDataSource<any>
  dataSourceDiscountNumeric: MatTableDataSource<any>
  expandedElement: any
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow')

  ngAfterViewInit() {
    setTimeout(() => this.ngAfterViewInitUtil(), 0)
  }

  ngAfterViewInitUtil() {
    if (this.dataSourceCategory) this.dataSourceCategory.sort = this.categories
    if (this.dataSourceBrand) this.dataSourceBrand.sort = this.brands
    if (this.dataSourceDiscount) this.dataSourceDiscount.sort = this.discount
    if (this.dataSourceDiscountNumeric) this.dataSourceDiscountNumeric.sort = this.discountNumeric
    if (!this.compareReport) {
      this.compareService.setReportDates(this.report)
      this.compareService.setCategotyReport(this.dataSourceCategory.filteredData)
      this.compareService.setBrandReport(this.dataSourceBrand.filteredData)
      this.compareService.setProductDiscountReport(this.dataSourceDiscount.filteredData)
      this.compareService.setProductDiscountReportNumeric(
        this.dataSourceDiscountNumeric.filteredData
      )
    } else {
      const startDate = this.compareService.getReportStartDate()
      const endDate = this.compareService.getReportEndDate()
      if (startDate !== this.report.start_date && endDate !== this.report.end_date) {
        this.dataCategoriesToCompare = this.compareService.getCompareCategoryReport()
        this.dataBrandsToCompare = this.compareService.getCompareBrandReport()
        this.dataDiscountToCompare = this.compareService.getProductDiscountReport()
        this.dataDiscountToCompareNumeric = this.compareService.getProductDiscountReportNumeric()
      }
    }
  }

  ngOnChanges() {
    let reportType = localStorage.getItem('countr_report_type')
    if (reportType) {
      this.selectedInsightsType = reportType
    }

    this.createCategories()
    this.createBrands()
    this.createProductDiscount()
    this.createProductDiscountNumeric()

    this.initialReportDate = new Date(this.report.start_date).toLocaleDateString()
    this.finalReportDate = new Date(this.report.end_date).toLocaleDateString()
  }

  showRowDetails(row: any, event?) {
    if (row === this.expandedElement) {
      this.expandedElement = null
    } else {
      this.expandedElement = row
    }
  }

  createCategories() {
    if (this.report.content && this.report.content[this.currency].tables.product_by_category) {
      const category = this.report.content[this.currency].tables.product_by_category.details
      category.id = Object.keys(category)
      const categories = []
      Object.keys(category).forEach(categoryId => {
        if (categoryId !== 'id') {
          categories.push({
            id: categoryId,
            name: category[categoryId].name,
            number: category[categoryId].summary.number,
            amount: category[categoryId].summary.amount,
            details: category[categoryId].details
          })
        }
      })
      categories.forEach(category => {
        category.products = []
      })

      categories.forEach(category => {
        Object.keys(category.details).forEach(detail => {
          category.products.push({
            name: category.details[detail].name,
            amount: category.details[detail].summary.amount,
            number: category.details[detail].summary.number,
            details: category.details[detail].details
          })
        })
      })

      categories.forEach(category => {
        category.products.forEach(product => {
          product.variants = []
        })
      })

      categories.forEach(category => {
        category.products.forEach(product => {
          Object.keys(product.details).forEach(detail => {
            product.variants.push({
              name: product.details[detail].name,
              amount: product.details[detail].amount,
              number: product.details[detail].number
            })
          })

          for (var i = product.variants.length - 1; i >= 0; --i) {
            if (product.variants[i].name == 'Default') {
              product.variants.splice(i, 1)
            }
          }
        })
      })
      this.dataCategories = categories
      const rows = []
      this.dataCategories.forEach(element => {
        rows.push(element)
      })
      this.dataSourceCategory = new MatTableDataSource(rows)
    }
  }

  createBrands() {
    if (this.report.content && this.report.content[this.currency].tables.product_by_brand) {
      const brand = this.report.content[this.currency].tables.product_by_brand.details
      brand.name = Object.keys(brand)
      const brands = []

      Object.keys(brand).forEach(brandName => {
        if (brandName !== 'name') {
          brands.push({
            name: brandName,
            number: brand[brandName].summary.number,
            amount: brand[brandName].summary.amount,
            details: brand[brandName].details
          })
        }
      })
      brands.forEach(brand => {
        brand.products = []
      })

      brands.forEach(brand => {
        Object.keys(brand.details).forEach(detail => {
          brand.products.push({
            name: brand.details[detail].name,
            amount: brand.details[detail].summary.amount,
            number: brand.details[detail].summary.number,
            details: brand.details[detail].details
          })
        })
      })

      brands.forEach(brand => {
        brand.products.forEach(product => {
          product.variants = []
        })
      })

      brands.forEach(brand => {
        brand.products.forEach(product => {
          Object.keys(product.details).forEach(detail => {
            product.variants.push({
              name: product.details[detail].name,
              amount: product.details[detail].amount,
              number: product.details[detail].number
            })
          })

          for (let i = product.variants.length - 1; i >= 0; --i) {
            if (product.variants[i].name === 'Default') {
              product.variants.splice(i, 1)
            }
          }
        })
      })
      this.dataBrands = brands
      const rows = []
      this.dataBrands.forEach(element => {
        rows.push(element)
      })
      this.dataSourceBrand = new MatTableDataSource(rows)
    }
  }

  createProductDiscount() {
    if (
      this.report.content &&
      this.report.content[this.currency].tables.product_by_discount_percentage
    ) {
      const discount = this.report.content[this.currency].tables.product_by_discount_percentage
        .details
      discount.name = Object.keys(discount)
      const discounts = []

      Object.keys(discount).forEach(discountName => {
        if (discountName !== 'name') {
          discounts.push({
            name: `${(Number(discountName) * 100).toFixed(2)}%`,
            number: discount[discountName].summary.number,
            amount: discount[discountName].summary.amount,
            details: discount[discountName].details
          })
        }
      })
      discounts.forEach(discount => {
        discount.products = []
      })

      discounts.forEach(discount => {
        Object.keys(discount.details).forEach(detail => {
          discount.products.push({
            name: discount.details[detail].name,
            amount: discount.details[detail].summary.amount,
            number: discount.details[detail].summary.number,
            details: discount.details[detail].details
          })
        })
      })

      discounts.forEach(discount => {
        discount.products.forEach(product => {
          product.variants = []
        })
      })

      discounts.forEach(discount => {
        discount.products.forEach(product => {
          Object.keys(product.details).forEach(detail => {
            product.variants.push({
              name: product.details[detail].name,
              amount: product.details[detail].amount,
              number: product.details[detail].number
            })
          })

          for (let i = product.variants.length - 1; i >= 0; --i) {
            if (product.variants[i].name === 'Default') {
              product.variants.splice(i, 1)
            }
          }
        })
      })

      const sortedDisounts = discounts.sort(function (a, b) {
        return Number(a.name.replace('%', '')) - Number(b.name.replace('%', ''))
      })

      this.dataProductDiscount = sortedDisounts
      const rows = []
      this.dataProductDiscount.forEach(element => {
        rows.push(element)
      })
      this.dataSourceDiscount = new MatTableDataSource(rows)
    }
  }

  createProductDiscountNumeric() {
    if (
      this.report.content &&
      this.report.content[this.currency].tables.product_by_discount_numeric
    ) {
      const discount = this.report.content[this.currency].tables.product_by_discount_numeric.details
      discount.name = Object.keys(discount)
      const discounts = []

      Object.keys(discount).forEach(discountName => {
        if (discountName !== 'name') {
          discounts.push({
            name: `${this.currency} ${Number(discountName).toFixed(2)}`,
            number: discount[discountName].summary.number,
            amount: discount[discountName].summary.amount,
            details: discount[discountName].details
          })
        }
      })
      discounts.forEach(discount => {
        discount.products = []
      })

      discounts.forEach(discount => {
        Object.keys(discount.details).forEach(detail => {
          discount.products.push({
            name: discount.details[detail].name,
            amount: discount.details[detail].summary.amount,
            number: discount.details[detail].summary.number,
            details: discount.details[detail].details
          })
        })
      })

      discounts.forEach(discount => {
        discount.products.forEach(product => {
          product.variants = []
        })
      })

      discounts.forEach(discount => {
        discount.products.forEach(product => {
          Object.keys(product.details).forEach(detail => {
            product.variants.push({
              name: product.details[detail].name,
              amount: product.details[detail].amount,
              number: product.details[detail].number
            })
          })

          for (let i = product.variants.length - 1; i >= 0; --i) {
            if (product.variants[i].name === 'Default') {
              product.variants.splice(i, 1)
            }
          }
        })
      })

      const sortedDisounts = discounts.sort(function (a, b) {
        return (
          Number(a.name.replace(this ? this.currency : 'EUR', '')) -
          Number(b.name.replace(this ? this.currency : 'EUR', ''))
        )
      })

      this.dataProductDiscountNumeric = sortedDisounts
      const rows = []
      this.dataProductDiscountNumeric.forEach(element => {
        rows.push(element)
      })
      this.dataSourceDiscountNumeric = new MatTableDataSource(rows)
    }
  }
}
