import { Component, OnInit, OnChanges, Input } from '@angular/core'
import { Report } from '../../model/report.model'
import { MatTableDataSource } from '@angular/material/table'
import { DataSource } from '@angular/cdk/table'

@Component({
  selector: 'app-not-sold-products',
  templateUrl: './not-sold-products.component.html',
  styleUrls: ['./not-sold-products.component.css'],
})
export class NotSoldProductsComponent implements OnChanges {
  @Input()
  report: Report
  @Input()
  currency: string
  private lowProducts: any = []
  dataSource: MatTableDataSource<any>
  public displayedColumns = ['product']

  constructor() {}

  ngOnChanges() {
    this.notSoldList()
  }

  notSoldList() {
    if (this.report && this.report.content) {
      let report = this.report
      let currency = this.currency
      let notSoldList = []

      if (report.content && report.content[currency]) {
        let notSoldList_details = ((report.content[currency].not_sold || {}).products || {}).details
        for (var key in notSoldList_details) {
          if (notSoldList_details.hasOwnProperty(key)) {
            notSoldList.push(notSoldList_details[key])
          }
        }
        notSoldList = notSoldList.sort(function sort(a, b) {
          return a.name - b.name
        })
        this.lowProducts = notSoldList
        this.dataSource = new MatTableDataSource(this.lowProducts)
      }
    }
  }
}
