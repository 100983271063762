import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class TranslateService {
  data: any = {}
  private language: string

  constructor(private http: HttpClient) {}

  initTranslateService() {
    this.checkLanguage()
  }

  use(lang: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang || 'en'}.json`

      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {})
          resolve(this.data)
        },
        error => {
          this.data = {}
          resolve(this.data)
        }
      )
    })
  }

  setLanguage(lang) {
    localStorage.setItem('countr-dashboard:language', lang)
    this.use(lang)
  }

  checkLanguage() {
    let l = localStorage.getItem('countr-dashboard:language')
    if (l !== null) {
      this.language = l
      this.use(this.language)
    } else {
      this.use('en')
    }
  }

  getLanguage() {
    return this.language
  }
}
