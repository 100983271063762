import { Injectable } from '@angular/core'
import { RequestService } from './request.service'

@Injectable()
export class GiftcardService {
    private giftcards: any[]
    private count: number

    constructor(private request: RequestService) {}

    fetchGiftcards(): Promise<any[]> {
        return this.request.getResource('giftcards', { limit: 100 }).then((giftcards: any[]) => {
            this.giftcards = giftcards
            this.count = giftcards.length
            return this.giftcards
        })
    }

    getGiftcards(): any[] {
        return this.giftcards
    }

    getGiftcard(id: string): Promise<any> {
        return this.request.getResource(`giftcards/${id}`) as Promise<any>
    }
    
    setGiftcard(giftcard: any): Promise<any> {
        return this.request.postResource('giftcards', giftcard)
    }

    deleteGiftcard(id: any) {
        return this.request.deleteResource('giftcards/' + id)
    }

    createVoucher(): any {
        return {
          __t: 'Giftcard',
          activity: [],
          identifiers: [],
          is_active: true,
          start_date: new Date(),
          expiry_date: new Date(),
          title: '',
          total_value: 1,
          value_remaining: 1
        }
      }
}