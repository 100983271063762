import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Category } from '../model/product.model'

// import { Store, select } from '@ngrx/store'
// import * as CategoriesStore from './../store/actions'

@Injectable()
export class CategoriesService {
  private categories: Category[]
  private count: number

  constructor(private request: RequestService /*private store: Store<any>*/) {
    // this.store.select('categories').subscribe(state => (this.categories = state.categories))
  }

  fetchCategories(): void {
    this.request.getResource('categories', { limit: 500 }).then((categories: Category[]) => {
      this.categories = categories
      this.count = categories.length
      // categories.forEach(category => {
      //   this.store.dispatch(new CategoriesStore.AddCategory(category))
      // })
    })
  }

  getCategories(): Category[] {
    return Object.assign([], this.categories)
  }

  getCategoriesCount(): number {
    return this.count
  }

  getCategory(id: string): Category {
    return { ...this.categories.find(category => category._id === id) }
  }

  setCategory(category: Category): Promise<Category> {
    let index = this.categories.findIndex(cat => cat._id === category._id)
    if (index >= 0) {
      return this.request
        .patchResource('categories/' + category._id, category)
        .then((updated: Category) => {
          this.categories[index] = updated
          return Promise.resolve(updated)
        })
    } else {
      return this.request.postResource('categories', category).then((updated: Category) => {
        this.categories = [...[updated], ...this.categories]
        return Promise.resolve(updated)
      })
    }
  }

  deleteCategory(id: string): Promise<Category> {
    let index = this.categories.findIndex(cat => cat._id === id)
    this.categories.splice(index, 1)
    return this.request.deleteResource('categories/' + id).then((category: Category) => {
      return Promise.resolve(category)
    })
  }
}
