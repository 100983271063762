import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Device } from '../model/device.model'

@Injectable()
export class DevicesService {
  private devices: Device[]
  private count: number

  constructor(private request: RequestService) {}

  fetchDevices(): void {
    this.request.getResource('devices', { limit: 100 }).then((devices: Device[]) => {
      this.devices = devices
      this.count = devices.length
    })
  }

  getDevices(): Device[] {
    return Object.assign([], this.devices)
  }

  getDevicesCount(): number {
    return this.count
  }

  async getDevice(id: string) {
    let device: any = this.devices.find(device => device._id === id)
    if (!device) {
      device = await this.request.getResource('devices/' + id)
      this.devices = [...[device], ...this.devices]
    }
    return device
  }

  setDevice(device: Device): Promise<Device> {
    let index = this.devices.findIndex(dv => dv._id === device._id)
    if (device._id) {
      return this.request.patchResource('devices/' + device._id, device).then((updated: Device) => {
        if (index >= 0) {
          this.devices[index] = updated
        } else {
          this.devices = [...[updated], ...this.devices]
        }
        return Promise.resolve(updated)
      })
    } else {
      return this.request.postResource('devices', device).then((updated: Device) => {
        this.devices = [...[updated], ...this.devices]
        return Promise.resolve(updated)
      })
    }
  }

  deleteDevice(id: string): Promise<Device> {
    let index = this.devices.findIndex(dv => dv._id === id)
    this.devices.splice(index, 1)
    return this.request.deleteResource('devices/' + id).then((device: Device) => {
      return Promise.resolve(device)
    })
  }

  fetchDesktopDevices(): Promise<Device[]> {
    return this.request
      .getResource('devices', { limit: 20, 'filter[name]': 'desktop' })
      .then((devices: Device[]) => {
        return Promise.resolve(devices)
      })
  }
}
