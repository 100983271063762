<div *ngIf="data.canDelete">
  <h1 mat-dialog-title>{{ title | translate }}</h1>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutWrap="wrap" *ngIf="data.itemType === 'devices'">
      <div fxFlex="100">
        <p>
          <span style="color: red; font-weight: 700; text-decoration:underline">{{
            'important' | translate
          }}</span>
          {{ 'store_device_delete_msg' | translate }}
        </p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutWrap="wrap">
      <div fxFlex="100">
        <p>{{ text | translate }}</p>
      </div>
      <div fxFlex="100" *ngIf="showInput">
        <mat-form-field>
          <input matInput [value]="data.name" (keyup)="data.name = $event.target.value" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <button mat-button (click)="cancel()" fxFlex="48">{{ 'cancel' | translate }}</button>
    <button mat-button (click)="confirm()" fxFlex="48">{{ 'confirm' | translate }}</button>
  </div>
</div>
<div *ngIf="!data.canDelete">
  <p>
    {{ modalText | translate }}
  </p>
  <div mat-dialog-actions fxLayout="row">
    <button mat-button (click)="cancel()" fxFlex="48">Ok</button>
  </div>
</div>
