<div class="select-subscription-container">
  <div class="subs-title">
    <h4>{{ 'subs_to_ext' | translate }}</h4>
  </div>
  <mat-divider></mat-divider>
  <div class="subs-content">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="100">
        <mat-form-field>
          <mat-select placeholder="{{ 'select_package' | translate }}">
            <mat-option
              *ngFor="let extension of packageSelection"
              (onSelectionChange)="extensionSelected($event)"
              [value]="extension"
            >
              {{ extension.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="100">
        <p>
          {{ 'subscription_msg' | translate }}
        </p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" *ngIf="selectedPackage">
      <div fxFlex="100">
        <h4>{{ selectedPackage.name }}</h4>
        <p>
          {{ selectedPackage.infos.description }}
        </p>
        <p>{{ 'price' | translate }}: {{ selectedPackage.price[selectedPeriod] }}</p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="100">
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel *ngFor="let ext of selectExtension" hideToggle>
            <mat-expansion-panel-header [ngStyle]="{ 'border-bottom': '1px solid' }">
              <mat-checkbox (change)="checked(ext, $event)"></mat-checkbox>
              <mat-panel-title [ngStyle]="{ height: '20px' }">
                {{ ext.name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ ext.infos.description }}</p>
            <p>
              <b>{{ 'price' | translate }}:</b>{{ ext.price[selectedPeriod] }}
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <h4>{{ 'period' | translate }}</h4>
        <div class="select-sub-period">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedPeriod">
            <mat-radio-button (change)="selectPeriod($event)" value="month" checked>{{
              'month' | translate
            }}</mat-radio-button>
            <mat-radio-button (change)="selectPeriod($event)" value="year" *ngIf="hasAnnualPrice">{{
              'year' | translate
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div fxFlex="50">
        <h4>{{ 'total' | translate }}</h4>
        <p *ngIf="totalPrice.length">
          <b
            ><span class="big-text">{{ reduceTotal() }}</span></b
          >
          &nbsp; <span class="small-text">({{ 'excluse_vat' | translate }})</span>
        </p>
        <p *ngIf="selectedPeriod === 'year' && totalPrice.length">
          <span class="medium-text"
            >{{ 'instead' | translate }} <b>{{ totalYearPrice() }}</b>
          </span>
          &nbsp;
          <span class="small-text">({{ 'excluse_vat' | translate }})</span>
        </p>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div class="save-payment">
        <mat-checkbox [(ngModel)]="payment_saved" [disabled]=disableCheckBox></mat-checkbox>
        {{ 'save_payment' | translate }}
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{ 'cancel' | translate }}</button>
        <button mat-button (click)="goLive()" [disabled]="!selectedPackage" cdkFocusInitial>
          {{ 'confirm' | translate }}
        </button>
        <!-- <button mat-button *ngIf="!selectedPackage" cdkFocusInitial disabled>
          {{ 'confirm' | translate }}
        </button> -->
      </div>
    </div>
  </div>
</div>
