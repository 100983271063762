export enum VoucherType {
  COUPON = 'Coupon',
  TICKET = 'Ticket',
  GIFTCARD = 'Giftcard'
}

export interface Voucher {
  _id?: string
  __t: string
  categories: string[]
  products: string[]
  variants: string[]
  deleted?: boolean
  title: string
  expiry_date?: Date
  start_date: Date
  total_uses: number
  uses_remaining: number
  discount: Discount
  is_active: boolean
  scope: Scope
  merchant?: string
  code?: string
  identifiers?: Identifier[]
  activity: Activity[]
  created_at?: Date
  updated_at?: Date
  last_used?: Date
}

export enum Scope {
  ITEM = 'item',
  CART = 'cart'
}

export interface Discount {
  numeric?: number
  percentage?: number
}

export interface Identifier {
  code_type?: string
  code_value?: string
}

export interface Activity {
  date: Date
  type: string
}
