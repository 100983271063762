import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Subscription } from '../model/subscription.model'

@Injectable()
export class SubscriptionService {
  private subscriptions: Subscription[]

  constructor(private request: RequestService) {}

  fetchSubscriptions(): void {
    this.request.getResource('me/subscriptions').then((subscriptions: Subscription[]) => {
      this.subscriptions = subscriptions
    })
  }

  getSubscriptions() {
    return Object.assign([], this.subscriptions)
  }
}
