<mat-card>
  <!-- <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center" class="mat-form-field title">
      <div fxFlex.gt-xs="50"> -->
  <mat-card-header>
    <mat-card-title>{{ 'select_store' | translate }}</mat-card-title>
  </mat-card-header>
  <!-- </div> -->
  <mat-card-content
    fxLayout="row"
    fxLayoutWrap="wrap"
    fxLayoutGap="15px"
    fxLayoutAlign="start center"
  >
    <div fxFlex.gt-xs="100">
      <mat-form-field>
        <mat-select placeholder="{{ 'all_stores' | translate }}" [(value)]="selectedStore">
          <mat-option [value]="'all_stores'" (onSelectionChange)="changeSelectedStore($event)">{{
            'all_stores' | translate
          }}</mat-option>
          <mat-option
            *ngFor="let store of stores"
            [value]="store"
            (onSelectionChange)="changeSelectedStore($event)"
          >
            {{ store.name }} ({{ store.currency?.code }} - {{ store.currency?.symbol }})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- {{ selectedStore?.name }} -->
  </mat-card-content>
</mat-card>
