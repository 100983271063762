import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http'
import { Router } from '@angular/router'
import { AuthService } from '../services/auth.service'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req.clone({})

    if (
      authReq.url.indexOf('countr') >= 0 ||
      (environment.local && authReq.url.indexOf('localhost:3030') >= 0)
    ) {
      const token = this.auth.getToken()
      if (!!token) {
        authReq = req.clone({
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
        })

        // Don't set content type for FormData
        if (!(req.body && req.body instanceof FormData)) {
          authReq.headers.set('Content-Type', 'application/json')
        }
      }
    }
    return next.handle(authReq).pipe(
      tap(
        (event: HttpResponse<any>) => {},
        (event: HttpEvent<any>) => {
          if (event instanceof HttpErrorResponse) {
            if (event.status === 401) {
              localStorage.clear()
              this.auth.clearToken()
              this.router.navigate(['auth/login'])
            }
          }
        }
      )
    )
  }
}
