import { Component, OnInit, Input } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BusinessInformationModalComponent } from './business-information-modal/business-information-modal.component'
import { SelectSubscriptionModalComponent } from './select-subscription-modal/select-subscription-modal.component'
import { GoToPaymentComponent } from './go-to-payment/go-to-payment.component'
import { InvoiceService } from '../../../services/invoices.service'
import { RequestService } from '../../../services/request.service'
import { User } from './../../../model/user.model'
import { Invoice } from '../../../model/invoice.model'

@Component({
  selector: 'app-go-live',
  templateUrl: './go-live.component.html',
  styleUrls: ['./go-live.component.css']
})
export class GoLiveComponent implements OnInit {
  @Input()
  trialRemaing
  @Input()
  getUser: User
  private invoices: Invoice[]
  private gettingLive: boolean
  private user: any

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private invoiceService: InvoiceService,
    private request: RequestService
  ) {
    this.gettingLive = false
  }

  ngOnInit() {
    this.invoices = this.invoiceService.getInvoices()
  }

  openDialog(): void {
    window.analytics && window.analytics.track('dashboard:go-live:buton')
    this.invoices = this.invoiceService.getInvoices()
    this.request.getResource('me').then(user => {
      if(user) {
        this.user = user
        if (this.user.billing_info.registration && !this.invoices.length && !this.gettingLive) {
          const dialogRef = this.dialog.open(SelectSubscriptionModalComponent, {
            width: '70%',
            data: { getPackage: this.gettingLive }
          })
          dialogRef.afterClosed().subscribe(result => {
            this.gettingLive = result.getPackage
          })
        } else if (this.invoices.length || this.gettingLive) {
          if (!this.invoices.length) {
            this.invoiceService.fetchInvoices()
            setTimeout(() => {
              this.invoices = this.invoiceService.getInvoices()
              const dialogRef = this.dialog.open(GoToPaymentComponent, {
                width: '70%',
                data: { invoices: this.invoices }
              })
            }, 2800)
          } else {
            const dialogRef = this.dialog.open(GoToPaymentComponent, {
              width: '70%',
              data: { invoices: this.invoices }
            })
          }
        } else {
          const dialogRef = this.dialog.open(BusinessInformationModalComponent, {
            width: '70%',
            data: { user: this.user }
          })
        }
      }
    })
  }
}
