import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Cart } from './../model/cart.model'

@Injectable()
export class CartsService {
  constructor(private request: RequestService) {}

  getCarts() {
    return this.request.getResource('carts', { limit: 100 }).then((carts: Cart[]) => {
      return carts
    })
  }

  getOpenCartsFrom(type: string, id: string) {
    return this.request
      .getResource('carts', { limit: 100, sort: '-updated_at' })
      .then((carts: Cart[]) => {
        return carts.filter(cart => cart[type] === id && cart.items.length > 0)
      })
  }
}
