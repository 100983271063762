import { Component, OnInit, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { RequestService } from '../../services/request.service'
import { DialogCreateReportComponent } from './dialog-create-report/dialog-create-report.component'
import { Report } from '../../model/report.model'

@Component({
  selector: 'app-compare-reports',
  templateUrl: './compare-reports.component.html',
  styleUrls: ['./compare-reports.component.css']
})
export class CompareReportsComponent implements OnInit {
  @Input()
  newPeriod: string
  @Input()
  newDate: Date
  @Input()
  oldReportCurrencies: any[]
  @Input()
  insightsType: string
  @Input()
  selectedDay: any

  public period: string
  public currency: string
  private showReport: boolean
  private initialDate: Date
  public currentReport: Report
  private errorCurrentReport: string
  private finalDate: string
  public device: any[]
  public dateObj: any[]
  public employee: any[]
  public store: any[]
  private endDate: Date
  public newCurrency: any[]
  public selectedCurrency: boolean
  public isLastPeriod: boolean

  constructor(public dialog: MatDialog, private request: RequestService) {
    this.showReport = false
    this.period = 'day'
    this.initialDate = new Date()
    this.initialDate.setHours(0, 0, 0, 0)
    this.currency = 'EUR'
    this.isLastPeriod = false
  }

  ngOnInit() {}

  openDialog(period: string, dateObj?: any, device?: any, employee?: any, store?: any) {
    this.selectedCurrency = false
    const dialogRef = this.dialog.open(DialogCreateReportComponent, {
      width: '1250px',
      data: { period, dateObj: {}, device, employee, store }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.period = result.period
      this.device = result.device
      this.employee = result.employee
      this.store = result.store
      this.dateObj = result.dateObj
      this.getReports(result.period, result.dateObj, result.device, result.employee, result.store)
    })
  }

  lastPeriodReport() {
    const period = this.newPeriod
    this.isLastPeriod = true
    const getFunc = period === 'day' || period === 'week' ? 'getDate' : 'getMonth'
    const setFunc = period === 'day' || period === 'week' ? 'setDate' : 'setMonth'
    const param = period === 'day' || period === 'month' ? 1 : period === 'week' ? 7 : 4
    const date = new Date(this.newDate)
    this.initialDate = new Date(date[setFunc](date[getFunc]() - param))
    this.getReports(period, this.initialDate)
  }

  getReports(period: string, dateObj?: any, device?: any, employee?: any, store?: any) {
    this.currentReport = null
    this.showReport = false
    let url = `reports/generate/`
    const opts = {
      date: new Date(),
      start_date: new Date(),
      end_date: new Date(),
      utc_offset: new Date().getTimezoneOffset(),
      devices: device !== undefined && device.length > 0 ? [device[0]] : [],
      employees: employee !== undefined && employee.length > 0 ? [employee[0]] : [],
      stores: store !== undefined && store.length > 0 ? [store[0]] : [],
      type: this.insightsType.toLowerCase()
    }

    if (dateObj && dateObj.start) {
      this.initialDate = dateObj.start
    }

    if (period === 'specific') {
      opts.start_date = dateObj.start_date
      opts.end_date = dateObj.end_date
      delete opts.date
    } else {
      opts.date = dateObj && dateObj.start ? dateObj.start : this.initialDate
      delete opts.start_date
      delete opts.end_date
      url += period
    }

    this.request.postResource(url, opts).then(
      (report: Report) => {
        if (Object.keys(report.content).length > 0) {
          this.showReport = true
          this.currentReport = report
          this.errorCurrentReport = null
          this.isLastPeriod = false
          this.finalDate = new Date(report.start_date).toLocaleDateString()
          this.getCurrencies()
        }
      },
      error => {
        console.log(error)
        this.currentReport = null
        this.errorCurrentReport = 'No reports found for this period'
        this.showReport = false
      }
    )
  }

  changeSelectedCurrency(event) {
    if (event.isUserInput) {
      this.selectedCurrency = true
      this.currency = event.source.value.value
      this.getReports(this.period, this.dateObj, this.device, this.employee, this.store)
    }
  }

  getCurrencies() {
    const report = this.currentReport
    const content = report.content || {}
    if (Object.keys(content).length === 1) {
      this.currency = Object.keys(content)[0]
      this.selectedCurrency = true
    }

    // get all keys
    this.newCurrency = Object.keys(content).map(k => ({ text: k, value: k }))
  }
}
