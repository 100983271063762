import { Injectable } from '@angular/core'
import { Router, RouterEvent, NavigationEnd } from '@angular/router'

@Injectable()
export class RouterService {
    private previousUrl: string
    private currentUrl: string

  constructor(private router : Router) {
      this.currentUrl = this.router.url
      this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
              this.previousUrl = this.currentUrl
              this.currentUrl = event.url
          }
      })
  }

  getPreviousUrl(){
    return this.previousUrl;
  }
}
