import { Component, OnChanges, ViewChild, AfterViewInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { Report } from '../../../../model/report.model'
import { Input } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { CompareReportService } from '../../../../services/compare-reports.service'

import { MatSort } from '@angular/material/sort'

@Component({
  selector: 'data-table-store',
  templateUrl: './data-table-store.component.html',
  styleUrls: ['../data-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class DataTableStoreComponent implements OnChanges, AfterViewInit {
  @Input()
  report: Report
  @Input()
  isReportReady: boolean
  @Input()
  currency: string
  @Input()
  insightsType: string
  @Input()
  employeeReport: boolean
  @Input()
  customerReport: boolean
  @Input()
  compareReport: boolean
  @Input()
  selectedDay: any
  @ViewChild(MatSort) sort: MatSort
  @ViewChild('busiestHours') busiestHours: MatSort
  @ViewChild('busiestHoursDay') busiestHoursDay: MatSort
  @ViewChild('employeeTransactions') employeeTransactions: MatSort
  @ViewChild('employeeReps') employeeReps: MatSort
  @ViewChild('employeeRefunds') employeeRefunds: MatSort
  @ViewChild('employeeTips') employeeTips: MatSort
  @ViewChild('customerTransactions') customerTransactions: MatSort
  @ViewChild('customerRefunds') customerRefunds: MatSort

  constructor(public compareService: CompareReportService) {}
  public displayedColumnsReports = ['name', 'number', 'amount']
  public displayedColumnsTaxes = ['name', 'amount', 'price']
  private data: any = []
  private dataTransactions: any = []
  private dataPayments: any = []
  private dataCategories: any = []
  private dataRefunds: any = []
  private dataTaxes: any = []
  private dataBrands: any = []
  private dataChannels: any = []
  private names: any = []
  public initialReportDate: string
  public finalReportDate: string
  public displayedColumns = ['revenue', 'amount']
  public selectedInsightsType: string
  public totalRepsAndWaste: any
  public utcOffset: number
  private isAverage: boolean
  public isDayReport: boolean
  public dataSourceBusiestHoursToCompare: any = []
  public dataSourceEmployeeInsightsTransToCompare: any = []
  public dataSourceEmployeeInsightsRepsWasteToCompare: any = []
  public dataSourceEmployeeInsightsRefsToCompare: any = []
  public dataSourceEmployeeInsightsTipsToCompare: any = []
  public dataSourceCustomerInsightsTranToCompare: any = []
  public dataSourceCustomerInsightsRefToCompare: any = []

  dataSource: MatTableDataSource<any>
  dataSourceBusiestHours: MatTableDataSource<any>
  dataSourceBusiestHoursDays: MatTableDataSource<any>
  dataSourceEmployeeInsightsTrans: MatTableDataSource<any>
  dataSourceEmployeeInsightsRefs: MatTableDataSource<any>
  dataSourceEmployeeInsightsTips: MatTableDataSource<any>
  dataSourceEmployeeInsightsRepsWaste: MatTableDataSource<any>
  dataSourceCustomerInsightsTran: MatTableDataSource<any>
  dataSourceCustomerInsightsRef: MatTableDataSource<any>
  expandedElement: any
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow')

  ngAfterViewInit() {
    setTimeout(() => this.ngAfterViewInitUtil(), 0)
  }

  ngAfterViewInitUtil() {
    this.dataSourceBusiestHours.sort = this.busiestHours
    if (!this.compareReport) this.dataSourceBusiestHoursDays.sort = this.busiestHoursDay

    this.dataSourceEmployeeInsightsTrans.sort = this.employeeTransactions
    this.dataSourceEmployeeInsightsRepsWaste.sort = this.employeeReps
    this.dataSourceEmployeeInsightsRefs.sort = this.employeeRefunds
    this.dataSourceEmployeeInsightsTips.sort = this.employeeTips
    this.dataSourceCustomerInsightsTran.sort = this.customerTransactions
    this.dataSourceCustomerInsightsRef.sort = this.customerRefunds

    if (!this.compareReport) {
      this.compareService.setReportDates(this.report)
      this.compareService.setBusiestHours(this.dataSourceBusiestHours.filteredData)
      this.compareService.setEmployeeTransactions(this.dataSourceEmployeeInsightsTrans.filteredData)
      this.compareService.setEmployeeRepsAndWaste(
        this.dataSourceEmployeeInsightsRepsWaste.filteredData
      )
      this.compareService.setEmployeeRefunds(this.dataSourceEmployeeInsightsRefs.filteredData)
      this.compareService.setEmployeeTips(this.dataSourceEmployeeInsightsTips.filteredData)
      this.compareService.setCustomerTransactions(this.dataSourceCustomerInsightsTran.filteredData)
      this.compareService.setCustomerRefunds(this.dataSourceCustomerInsightsRef.filteredData)
    } else {
      const startDate = this.compareService.getReportStartDate()
      const endDate = this.compareService.getReportEndDate()
      if (startDate !== this.report.start_date && endDate !== this.report.end_date) {
        this.dataSourceBusiestHoursToCompare = this.compareService.getCompareBusiestHours()
        this.dataSourceEmployeeInsightsTransToCompare = this.compareService.getEmployeeTransactions()
        this.dataSourceEmployeeInsightsRepsWasteToCompare = this.compareService.getEmployeeRepsAndWaste()
        this.dataSourceEmployeeInsightsRefsToCompare = this.compareService.getEmployeeRefunds()
        this.dataSourceEmployeeInsightsTipsToCompare = this.compareService.getEmployeeTips()
        this.dataSourceCustomerInsightsTranToCompare = this.compareService.getCustomerTransactions()
        this.dataSourceCustomerInsightsRefToCompare = this.compareService.getCustomerRefunds()
      }
    }
  }

  ngOnChanges() {
    let reportType = localStorage.getItem('countr_report_type')
    if (reportType) {
      this.selectedInsightsType = reportType
    }

    this.createBusiestHours()
    this.createEmployeeInsights()
    this.createCustomerInsights()

    this.initialReportDate = new Date(this.report.start_date).toLocaleDateString()
    this.finalReportDate = new Date(this.report.end_date).toLocaleDateString()
  }

  showRowDetails(row: any, event?) {
    if (row === this.expandedElement) {
      this.expandedElement = null
    } else {
      this.expandedElement = row
    }
  }

  createBusiestHours() {
    let data = []
    const DAY_NAMES = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

    let utc_offset = -Number(localStorage.getItem('countr_report_utc_offset')) / 60
    this.utcOffset = utc_offset

    const selected = this.report.content[this.currency].graphs.busiest_hours.find(
      day => day.day === this.selectedDay
    )

    if (selected) {
      let tableRows = Array.apply(null, { length: 24 }).map(Number.call, Number)

      tableRows.forEach(row => {
        if (selected.hours) {
          let entry = selected.hours.find(e => e.hour + utc_offset === row)

          if (entry) {
            data.push({
              time: row,
              amount: entry.amount,
              number: entry.number
            })
          }
        }
      })
    }
    if (!this.compareReport) {
      let reportsDays = this.report.content[this.currency].graphs.busiest_hours.filter(
        day => day.day !== 'average'
      )
      let averageDaysData: Array<any>

      averageDaysData = Object.values(
        reportsDays.reduce((c, { hours, labelDay }) => {
          if (labelDay)
            c[labelDay.split(',')[1]] = c[labelDay.split(',')[1]] || {
              day: labelDay.split(',')[1],
              hours: []
            }
          if (labelDay) c[labelDay.split(',')[1]].hours.push(...hours)
          if (labelDay)
            c[labelDay.split(',')[1]].hours = c[labelDay.split(',')[1]].hours.reduce(function (
              r,
              e
            ) {
              let val = r.find(({ hour }) => hour === e.hour)
              if (!val) {
                val = {
                  hour: e.hour,
                  amount: 0,
                  number: 0
                }
                r.push(val)
              }
              val.amount = e.amount + val.amount
              val.number = e.number + val.number
              r.sort((a, b) => parseFloat(a.hour) - parseFloat(b.hour))
              return r
            },
            [])

          return c
        }, {})
      )
      let formatedSort = []
      DAY_NAMES.forEach(day => {
        const selectedDay = averageDaysData.find(
          data => data.day && day.includes(data.day.replace(/\s+/g, ''))
        )

        if (selectedDay) {
          selectedDay.day = day
          formatedSort.push(selectedDay)
        }
      })

      this.dataSourceBusiestHoursDays = new MatTableDataSource(formatedSort)
      if (averageDaysData.length > 1) {
        this.isDayReport = false
      } else this.isDayReport = true
    }

    this.dataSourceBusiestHours = new MatTableDataSource(data)
  }

  createEmployeeInsights() {
    let dataTrans = []
    let dataRefs = []
    let dataTips = []
    let dataRepsWaste = []

    if (this.report.content) {
      Object.keys(this.report.content[this.currency].tables.employee_insights.details).map(key => {
        const keyValue = this.report.content[this.currency].tables.employee_insights.details[key]
        dataTrans.push({
          name: keyValue.name ? keyValue.name : 'Without employee name',
          amount: keyValue.transactions_amount,
          number: keyValue.transactions_number
        })
        dataRefs.push({
          name: keyValue.name ? keyValue.name : 'Without employee name',
          amount: keyValue.refunds_amount,
          number: keyValue.refunds_number
        })
        dataTips.push({
          name: keyValue.name ? keyValue.name : 'Without employee name',
          amount: keyValue.tips_amount,
          number: keyValue.tips_number
        })
        dataRepsWaste.push({
          name: keyValue.name ? keyValue.name : 'Without employee name',
          amount: keyValue.reps_waste_amount,
          number: keyValue.reps_waste_number
        })
      })
      this.dataSourceEmployeeInsightsTrans = new MatTableDataSource(dataTrans)
      this.dataSourceEmployeeInsightsRefs = new MatTableDataSource(dataRefs)
      this.dataSourceEmployeeInsightsTips = new MatTableDataSource(dataTips)
      this.dataSourceEmployeeInsightsRepsWaste = new MatTableDataSource(dataRepsWaste)
    }
  }

  createCustomerInsights() {
    const dataTrans = []
    const dataRef = []

    if (this.report.content) {
      Object.keys(this.report.content[this.currency].tables.customer_insights.details).map(key => {
        const keyValue = this.report.content[this.currency].tables.customer_insights.details[key]
        dataTrans.push({
          type: key,
          amount: keyValue.transactions_amount_avg,
          number: keyValue.transactions_number
        })
        dataRef.push({
          type: key,
          amount: keyValue.refunds_amount_avg,
          number: keyValue.refunds_number
        })
      })
      this.dataSourceCustomerInsightsTran = new MatTableDataSource(dataTrans)
      this.dataSourceCustomerInsightsRef = new MatTableDataSource(dataRef)
    }
  }
}
