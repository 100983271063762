import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Extension } from './../model/extension.model'
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ExtensionsService {
  private extensions: Extension[]
  private allExtenstions: Extension[]

  constructor(private request: RequestService, private snackBar: MatSnackBar) {
    this.extensions = []
  }

  fetchGetAllExtension(): void {
    this.request.getResource('extensions').then((extensions: Extension[]) => {
      this.allExtenstions = extensions
    })
  }

  getAllExtensions(): Extension[] {
    let systemExtensions = []
    this.allExtenstions.map(extension => {
      if (extension.tags && extension.tags.includes('system')) {
        systemExtensions.push(extension)
      }
    })
    return systemExtensions
  }

  getExtensions(): Extension[] {
    let systemExtensions = []
    this.allExtenstions.map(extension => {
      if (
        extension.tags.includes('active') && extension.tags.includes('live')
      ) {
        systemExtensions.push(extension)
      }
    })
    return systemExtensions
  }

  fetchExtensions(): void {
    this.request.getResource('me/extensions').then((extensions: Extension[]) => {
      this.extensions = extensions
    })
  }

  userHasExtension(extensionSlug: string): boolean {
    return this.extensions.findIndex(e => e.slug === extensionSlug) >= 0
  }

  removeExtension(id) {
    this.request.deleteResource('me/subscriptions/' + id, {}).then(deleted => {
      if (deleted) {
        this.snackBar.open(
          'You have successfully remove this extension. This extension will be removed next billing cycle',
          'Close',
          {
            duration: 5000
          }
        )
        location.reload()
      }
    })
  }
}
