<div class="max-w-90">
  <h5>{{ 'select' | translate }} {{ 'device' | translate }}</h5>
  <mat-form-field>
    <mat-select #select multiple placeholder="{{ 'all_devices' | translate }}">
      <button mat-button (click)="changeSelectedDevice(null)" class="select-all-btn">
        {{ 'all_devices' | translate }}
      </button>
      <!-- <mat-option [value]="null">All devices </mat-option> -->
      <mat-option
        *ngFor="let device of devices"
        [value]="device"
        (onSelectionChange)="changeSelectedDevice($event)"
      >
        {{ device.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<!-- {{ selectedDevice?.name }} -->
