import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Addon } from '../model/addon.model'

@Injectable()
export class AccessRightsService {
  constructor(private request: RequestService) {}

  newAddon(): Addon {
    return {
      name: '',
      price: 0,
      cost_price: 0,
      groups: [],
      local_prices: [],
      store_availability: [],
      options: {
        sold_by_weight: false
      }
    }
  }

  canCreate(user, type) {
    if (user.__t !== 'Altuser') {
      return true
    } else {
      return user?.access_rights[0]?.grants[type]?.create
    }
  }

  canEdit(user, type) {
    if (user.__t !== 'Altuser') {
      return true
    } else {
      return user?.access_rights[0]?.grants[type]?.edit
    }
  }

  canRead(user, type) {
    if (user.__t !== 'Altuser') {
      return true
    } else {
      return user?.access_rights[0]?.grants[type]?.read
    }
  }

  canDelete(user, type) {
    if (user.__t !== 'Altuser') {
      return true
    } else {
      type = this.formatDataType(type)
      return user?.access_rights[0]?.grants[type]?.delete
    }
  }

  formatDataType = (type: string) => {
    if (type === 'addongroups') {
      type = 'addon-groups'
    } else if (type === 'me/rules') {
      type = 'business-rules'
    } else if (type === 'customergroups') {
      type = 'customer-groups'
    }

    return type
  }

  haveGrants(user, readRight, editRight, createRight?) {
    if (user?.__t === 'Merchant') {
      return true
    } else if (user?.__t === 'Altuser' && (readRight || editRight || createRight)) {
      return true
    } else if (user?.__t === 'Altuser' && !readRight && !editRight && !createRight) {
      return false
    }
  }
}
