import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ExtensionsService } from '../../../services/extensions.service'

@Component({
  selector: 'pricing-tables',
  templateUrl: './pricing-tables.component.html',
  styleUrls: ['./pricing-tables.component.css']
})
export class PricingTablesComponent implements OnInit {
  public extensions: any[]
  constructor(
    public dialogRef: MatDialogRef<PricingTablesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private extensionService: ExtensionsService,
  ) {}

  ngOnInit() {
    this.extensions = this.extensionService.getExtensions()
  }
  onNoClick(): void {
    this.dialogRef.close()
  }

  setSelectedExtension(extension) {
    this.dialogRef.close(extension)
  }
}
