import * as dayjs from 'dayjs'
import isoWeek from "dayjs/plugin/isoWeek";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'

export class CompareReportService {
  private report_start_date: Date
  private report_end_date: Date
  private compareCategory: any
  private compareBrand: any
  private compareBusiestHours: any
  private compareEmployeeTransactions: any
  private compareEmployeeRepsAndWaste: any
  private compareEmployeeRefs: any
  private compareEmployeeTips: any
  private compareCustomerTransactions: any
  private compareCustomerRefunds: any
  private comparePayments: any
  private compareChannels: any
  private compareRepsAndWaste: any
  private compareRevenue: any
  private compareTax: any
  private compareRefund: any
  private compareTransaction: any
  private compareTotalPayments: any
  private compareTotalTransactions: any
  private compareTotalRefunds: any
  private compareTotalTaxes: any
  private compareTotalRepsAndWaste: any
  private reportGraphType: string
  private specificReport: boolean
  private compareTotalDiscount: any
  private compareDiscount: any
  private compareProductDiscount: any
  private compareDiscountNumeric: any
  private compareDiscountTotalNumeric: any
  private compareProductDiscountNumeric: any

  setReportDates(report) {
    this.report_start_date = report.start_date
    this.report_end_date = report.end_date
  }

  setCategotyReport(data) {
    if (data.length) {
      this.compareCategory = data
    }
  }

  setBrandReport(data) {
    if (data.length) {
      this.compareBrand = data
    }
  }

  setBusiestHours(data) {
    if (data.length) {
      this.compareBusiestHours = data
    }
  }

  setEmployeeTransactions(data) {
    if (data.length) {
      this.compareEmployeeTransactions = data
    }
  }

  setEmployeeRepsAndWaste(data) {
    if (data.length) {
      this.compareEmployeeRepsAndWaste = data
    }
  }

  setEmployeeRefunds(data) {
    if (data.length) {
      this.compareEmployeeRefs = data
    }
  }

  setEmployeeTips(data) {
    if (data.length) {
      this.compareEmployeeTips = data
    }
  }

  setCustomerTransactions(data) {
    if (data.length) {
      this.compareCustomerTransactions = data
    }
  }

  setCustomerRefunds(data) {
    if (data.length) {
      this.compareCustomerRefunds = data
    }
  }

  setPaymentsReport(data) {
    if (data.length) {
      this.comparePayments = data
    }
  }

  setChannelsReport(data) {
    if (data) {
      this.compareChannels = data
    }
  }

  setRepsAndWasteReport(data) {
    if (data) {
      this.compareRepsAndWaste = data
    }
  }

  setTransactionReport(data) {
    if (data) {
      this.compareTransaction = data
    }
  }

  setRefundReport(data) {
    if (data) {
      this.compareRefund = data
    }
  }
  setTaxReport(data) {
    if (data) {
      this.compareTax = data
    }
  }

  setRevenueReport(data) {
    if (data) {
      this.compareRevenue = data
    }
  }

  setTotalTransactions(total) {
    if (total) {
      this.compareTotalTransactions = total
    }
  }

  setTotalPayments(total) {
    if (total) {
      this.compareTotalPayments = total
    }
  }

  setTotalRefunds(total) {
    if (total) {
      this.compareTotalRefunds = total
    }
  }

  setTotalTaxes(total) {
    if (total) {
      this.compareTotalTaxes = total
    }
  }

  setTotalRepsAndWaste(total) {
    if (total) {
      this.compareTotalRepsAndWaste = total
    }
  }

  setReportGraphType(type) {
    this.reportGraphType = type
  }

  setSpecificReport(report) {
    this.specificReport = report
  }

  setTotalDiscount(total) {
    if (total) {
      this.compareTotalDiscount = total
    }
  }

  setDisountReport(data) {
    if (data) {
      this.compareDiscount = data
    }
  }

  setProductDiscountReport(data) {
    if (data) {
      this.compareProductDiscount = data
    }
  }

  setDiscountReportNumeric(data) {
    if (data) {
      this.compareDiscountNumeric = data
    }
  }

  setTotalDiscountNumeric(total) {
    if (total) {
      this.compareDiscountTotalNumeric = total
    }
  }

  setProductDiscountReportNumeric(data) {
    if (data) {
      this.compareProductDiscountNumeric = data
    }
  }

  getProductDiscountReportNumeric() {
    return this.compareProductDiscountNumeric
  }

  getTotalDiscountNumeric() {
    return this.compareDiscountTotalNumeric
  }

  getDiscountReportNumeric() {
    return this.compareDiscountNumeric
  }

  getProductDiscountReport() {
    return this.compareProductDiscount
  }

  getDisountReport() {
    return this.compareDiscount
  }

  getTotalDiscount() {
    return this.compareTotalDiscount
  }

  getSpecificReport() {
    return this.specificReport
  }

  getReportGraphType() {
    return this.reportGraphType
  }

  getTotalRepsAndWaste() {
    return this.compareTotalRepsAndWaste
  }

  getTotalTaxes() {
    return this.compareTotalTaxes
  }

  getTotalRefunds() {
    return this.compareTotalRefunds
  }

  getTotalTransactions() {
    return this.compareTotalTransactions
  }

  getTotalPayments() {
    return this.compareTotalPayments
  }

  getTransactionReport() {
    return this.compareTransaction
  }

  getRefundReport() {
    return this.compareRefund
  }

  getRevenueReport() {
    return this.compareRevenue
  }

  getTaxReport() {
    return this.compareTax
  }
  getRepsAndWasteReport() {
    return this.compareRepsAndWaste
  }

  getChannelReport() {
    return this.compareChannels
  }

  getPaymentreport() {
    return this.comparePayments
  }

  getCustomerRefunds() {
    return this.compareCustomerRefunds
  }

  getCustomerTransactions() {
    return this.compareCustomerTransactions
  }

  getEmployeeTips() {
    return this.compareEmployeeTips
  }

  getEmployeeRefunds() {
    return this.compareEmployeeRefs
  }

  getEmployeeRepsAndWaste() {
    return this.compareEmployeeRepsAndWaste
  }

  getEmployeeTransactions() {
    return this.compareEmployeeTransactions
  }

  getCompareBusiestHours() {
    return this.compareBusiestHours
  }

  getCompareCategoryReport() {
    return this.compareCategory
  }

  getCompareBrandReport() {
    return this.compareBrand
  }

  getReportStartDate() {
    return this.report_start_date
  }

  getReportEndDate() {
    return this.report_end_date
  }

  handleCompare(row, list, value, key) {
    if (row && list && value && key) {
      const selected = list.find(product => product[key] === row[key])

      let rowValue
      let listValue
      if (selected && selected[key]) {
        if (row[value] === undefined || selected[value] === undefined) {
          if (value === 'amount') {
            rowValue = Math.abs(row.total[0].totalAmount)
            listValue = Math.abs(selected.total[0].totalAmount)
          } else if (value === 'number') {
            rowValue = Math.abs(row.total[0].totalNumber)
            listValue = Math.abs(selected.total[0].totalNumber)
          }
        } else {
          rowValue = Math.abs(row[value])
          listValue = Math.abs(selected[value])
        }

        if (listValue === 0 && rowValue !== listValue) {
          return '(new)'
        } else if (rowValue > listValue) {
          const increase = rowValue - listValue
          const result = (increase / listValue) * 100
          return `(+${result.toFixed(0)} %)`
        } else if (rowValue === listValue) {
          return `(0 %)`
        } else {
          const decrease = listValue - rowValue
          const result = (decrease / listValue) * 100
          return `(-${result.toFixed(0)} %)`
        }
      } else {
        return '(new)'
      }
    } else {
      return '(new)'
    }
  }

  getDatesBetweenDates(fromDate, toDate) {
    const fromYear = fromDate.getFullYear()
    const fromMonth = fromDate.getMonth()
    const toYear = toDate.getFullYear()
    const toMonth = toDate.getMonth()
    const months = []
    for (let year = fromYear; year <= toYear; year++) {
      let month = year === fromYear ? fromMonth : 0
      const monthLimit = year === toYear ? toMonth : 11
      for (; month <= monthLimit; month++) {
        months.push(dayjs(`${month + 1}-${year}`).format('MMM-YYYY'))
      }
    }
    return months
  }

  generateSpecificLabels(store, chartLabels, reportType, utc_offset, amount) {
    dayjs.extend(customParseFormat)
    dayjs.extend(weekOfYear)
    dayjs.extend(isoWeek);
    const list = new Array(chartLabels.length).fill(0)

    store.details.forEach(transaction => {
      const transactionIndex = chartLabels.findIndex(function (item) {
        if (transaction.dateString && reportType === 'day') {
          return item.indexOf(dayjs(transaction.dateString).format('DD-MMM-YY')) >= 0
        } else if (transaction.dateString && reportType === 'week') {
          const week = dayjs(transaction.dateString).week()
          const fixedWeek = week < 10 ? `0${week}` : `${week}`
          return (
            item.indexOf(
              dayjs(transaction.dateString).format('WW of YYYY').replace('WW', fixedWeek)
            ) >= 0
          )
        } else if (transaction.dateString && reportType === 'month') {
          return item.indexOf(dayjs(transaction.dateString).format('MMM-YY')) >= 0
        } else if (transaction.dateString && reportType === 'quarter') {
          return item.indexOf(transaction.dateString) !== -1
        } else if (reportType && reportType === 'year') {
          return item.toString().indexOf(transaction.date) !== -1
        } else if (list.length === 24 && !transaction.dateString) {
          return item.indexOf(`${transaction.date + utc_offset}`) !== -1
        } else {
          return item.indexOf(`${transaction.date}`) !== -1
        }
      })
      list[transactionIndex] = amount ? transaction.amount : transaction.number
    })
    return list
  }

  formatOveralGraphs(reportList, chartLabels, reportType) {
    const list = new Array(chartLabels.length).fill(0)
    dayjs.extend(customParseFormat)
    dayjs.extend(weekOfYear)
    dayjs.extend(isoWeek);

    reportList.details.forEach(transaction => {
      if(!transaction) return
      const transactionIndex = chartLabels.findIndex(function (item) {
        if (transaction.date_stirng && reportType === 'day') {
          return item.indexOf(dayjs(transaction.date_stirng).format('DD-MMM-YY')) >= 0
        } else if (transaction.date_stirng && reportType === 'week') {
          // const week = dayjs(transaction.date_stirng).week()
          const week = transaction.date_stirng.split('-')[0]
          const fixedWeek = week < 10 ? `0${week}` : `${week}`
          const weekString = 'Week ' + fixedWeek + ' of ' + transaction.date_stirng.split('-')[1]
          return (
            item.indexOf(
              // dayjs(transaction.date_stirng).format('WW of YYYY').replace('WW', fixedWeek)
              weekString
            ) >= 0
          )
        } else if (transaction.date_stirng && reportType === 'month') {
          return item.indexOf(dayjs(transaction.date_stirng, 'M-YYYY').format('MMM-YY')) >= 0
        } else if (transaction.date_stirng && reportType === 'quarter') {
          return item.indexOf(transaction.date_stirng) !== -1
        } else if (reportType && reportType === 'year') {
          return item.toString().indexOf(transaction.date_stirng) !== -1
        }
      })
      list[transactionIndex] = transaction.amount
    })
    return list
  }
}
