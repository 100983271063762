import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Tax } from '../model/product.model'

@Injectable()
export class TaxesService {
  private taxes: Tax[]
  private count: number

  constructor(private request: RequestService) {}

  fetchTaxes(): void {
    this.request.getResource('taxes', { limit: 100 }).then((taxes: Tax[]) => {
      this.taxes = taxes
      this.count = taxes.length
    })
  }

  getTaxes(): Tax[] {
    return Object.assign([], this.taxes)
  }

  getTaxesCount(): number {
    return this.count
  }

  getTax(id: string): Tax {
    return { ...this.taxes.find(tax => tax._id === id) }
  }

  setTax(tax: Tax): Promise<Tax> {
    const index = this.taxes.findIndex(t => t._id === tax._id)
    if (index >= 0) {
      return this.request.patchResource('taxes/' + tax._id, tax).then((updated: Tax) => {
        this.taxes[index] = updated
        return Promise.resolve(updated)
      })
    } else {
      return this.request.postResource('taxes', tax).then((updated: Tax) => {
        this.taxes = [...[updated], ...this.taxes]
        return Promise.resolve(updated)
      })
    }
  }

  deleteTax(id: string): Promise<Tax> {
    const index = this.taxes.findIndex(t => t._id === id)
    this.taxes.splice(index, 1)
    return this.request.deleteResource('taxes/' + id).then((tax: Tax) => {
      return Promise.resolve(tax)
    })
  }
}
