/*
 *ngFor="let el of elements | sortBy:'propertyName'"
 *ngFor="let el of elements | sortBy:'propertyName':'desc'"
 */
import { Pipe, PipeTransform } from '@angular/core'
import { orderBy } from 'lodash'

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(arr: any[], column: string = '', order: string = 'asc'): any[] {
    // empty array or undefined
    if (!arr) {
      return arr
    }

    // 1 element array
    if (arr.length <= 1) {
      return arr
    }

    // array of strings
    if (!column || column === '') {
      if (order === 'asc') {
        return arr.sort()
      } else {
        return arr.sort().reverse()
      }
    }

    return orderBy(arr, [column], [order])
  }
}
