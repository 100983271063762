<div class="subs-extension-container">
  <div class="subs-extension-title">
    <h1 mat-dialog-title>
      <font class="cap">Subscribe to extensions</font>
    </h1>
  </div>
  <mat-divider></mat-divider>
  <div class="subs-content">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="100">
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel *ngFor="let ext of data.allExtensions" hideToggle>
            <mat-expansion-panel-header [ngStyle]="{ 'border-bottom': '1px solid' }">
              <mat-checkbox
                (change)="checked(ext, $event)"
                [checked]="checkExtensionInList(ext)"
              ></mat-checkbox>
              <mat-panel-title [ngStyle]="{ height: '20px' }">
                {{ ext.name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ ext.infos.description }}</p>
            <p><b>Price:</b>{{ ext.price[selectedPeriod] }}</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <h4>Period</h4>
        <div class="select-sub-period">
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button (change)="selectPeriod($event)" value="month" checked
              >Month</mat-radio-button
            >
            <mat-radio-button (change)="selectPeriod($event)" value="year">Year</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div fxFlex="50">
        <h4>Total</h4>
        <p *ngIf="totalPrice.length">
          <b
            ><span class="big-text">{{ reduceTotal() }}</span></b
          >
          &nbsp; <span class="small-text">(exclusive of VAT)</span>
        </p>
        <p *ngIf="selectedPeriod === 'year' && totalPrice.length">
          <span class="medium-text"
            >instead of <b>{{ totalYearPrice() }}</b>
          </span>
          &nbsp;
          <span class="small-text">(exclusive of VAT)</span>
        </p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{ 'cancel' | translate }}</button>
        <button mat-button *ngIf="selectedExtension.length" (click)="confirm()" cdkFocusInitial>
          Confirm
        </button>
        <button mat-button *ngIf="!selectedExtension.length" cdkFocusInitial disabled>
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
