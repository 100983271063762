import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core'
import { RequestService } from '../../services/request.service'
import { StoresService } from '../../services/stores.service'
import { Store } from './../../model/store.model'
import { MatSelect } from '@angular/material/select'
import { MatOption } from '@angular/material/core'
@Component({
  selector: 'app-select-store',
  templateUrl: './select-store.component.html',
  styleUrls: ['./select-store.component.css']
})
export class SelectStoreComponent implements OnInit {
  public stores: Store[]
  public selectedStore: any
  @Output()
  sendSelectedStore = new EventEmitter<{ store: Store; index: number }>()
  @ViewChild('select') select: MatSelect

  constructor(private request: RequestService, private storesService: StoresService) {
    this.stores = []
  }

  ngOnInit() {
    this.getStores()
  }

  getStores() {
    this.request.getResource('stores', { sort: 'name' }).then((stores: Store[]) => {
      this.stores = stores.sort(this.storesService.storesSort)
    })
  }

  changeSelectedStore(event) {
    if (event.isUserInput && event?.source?.value && event.source.value !== 'all_stores') {
      this.selectedStore = event.source.value
      let index = this.stores.findIndex(s => s._id === this.selectedStore._id)
      this.sendSelectedStore.emit({ store: this.selectedStore, index: index })
    } else if (event === 'all_stores') {
      this.selectedStore = null
      this.sendSelectedStore.emit({ store: null, index: null })
      this.select.options.forEach((item: MatOption) => item.deselect())
      this.select.close()
    }
  }
}
