import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { AuthService } from './auth.service'
import { Settings } from './../model/settings.model'

@Injectable()
export class SettingsService {
  private settings: Settings

  constructor(private request: RequestService, private authService: AuthService) {}

  initSettings() {
    let user = this.authService.getUser()

    if (user.extras && user.extras.dashboard_settings) {
      this.settings = user.extras.dashboard_settings
    } else {
      this.createSettingsObj()
    }
  }

  createSettingsObj() {
    this.settings = {
      theme: 'light'
    }
  }
}
