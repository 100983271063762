import { Component, OnInit, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service'
import { RequestService } from '../../services/request.service'

import { PaymentInfoModalComponent } from '../payment-info-modal/payment-info-modal.component'
import { SavePaymentMethodComponent } from '../save-payment-method/save-payment-method.component'

@Component({
  selector: 'app-subscribe-extension-modal',
  templateUrl: './subscribe-extension-modal.component.html',
  styleUrls: ['./subscribe-extension-modal.component.css']
})
export class SubscribeExtensionModalComponent implements OnInit {
  public selectedPeriod: string
  public selectedExtension: any

  public totalPrice: any[]
  private yearNoDiscount: any[]
  private user: any

  constructor(
    public dialogRef: MatDialogRef<SubscribeExtensionModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private authService: AuthService,
    private request: RequestService
  ) {
    this.totalPrice = []
    this.yearNoDiscount = []
    this.selectedPeriod = 'month'
    this.selectedExtension = []
    if (this.data.extension) {
      this.selectedExtension.push(this.data.extension)
      this.totalPrice.push(this.data.extension.price[this.selectedPeriod])
      this.yearNoDiscount.push(this.data.extension.price['month'] * 12)
    }
    this.user = authService.getUser()
  }

  ngOnInit() {}

  checkExtensionInList(ext) {
    if (ext._id === this.data.extension._id) {
      return true
    } else {
      return false
    }
  }

  selectPeriod(event) {
    if (this.selectedExtension) {
      this.selectedExtension.map(ext => {
        let index = this.totalPrice.indexOf(ext.price[this.selectedPeriod])
        this.totalPrice[index] = ext.price[event.value]
      })
    }
    this.selectedPeriod = event.value
  }

  checked(sub, event) {
    if (event.checked) {
      this.selectedExtension.push(sub)
      this.totalPrice.push(sub.price[this.selectedPeriod])
      this.yearNoDiscount.push(sub.price['month'] * 12)
    } else {
      let index = this.selectedExtension.indexOf(sub)
      this.selectedExtension.splice(index, 1)
      let indexTotalPrice = this.totalPrice.indexOf(sub.price[this.selectedPeriod])
      this.totalPrice.splice(indexTotalPrice, 1)
      let indexTotalYear = this.yearNoDiscount.indexOf(sub.price['month'] * 12)
      this.yearNoDiscount.splice(indexTotalYear, 1)
    }
  }

  reduceTotal() {
    if (this.totalPrice.length) {
      let reducer = (accumulator, currentValue) => accumulator + currentValue
      let price = this.totalPrice.reduce(reducer)
      return price
    }
  }

  totalYearPrice() {
    if (this.yearNoDiscount.length) {
      let reducer = (accumulator, currentValue) => accumulator + currentValue
      let price = this.yearNoDiscount.reduce(reducer)
      return price
    }
  }

  confirm() {
    let extensions = this.selectedExtension
    extensions.map(ext => {
      ext.price = ext.price[this.selectedPeriod]
    })
    this.request.getResource('me/metadata').then((data: any) => {
      if (data) {
        const dialogRefPaymentInfo = this.dialog.open(PaymentInfoModalComponent, {
          disableClose: true,
          width: data[0].payment_details ? '800px' : '660px',
          height: data[0].payment_details ? '400px' : '320px',
          data: {
            period: this.selectedPeriod,
            payment_details: data[0].payment_details,
            extensions: extensions
          }
        })
      }
    })
  }
  onNoClick(): void {
    this.dialogRef.close()
  }
}
