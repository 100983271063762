<mat-card>
  <mat-card-header>
    <mat-card-title
      ><i class="material-icons"> trending_down </i> Bottom-selling products (by volume) this period ( Top 10 ) (
      Products with 0 sales are not included ! )</mat-card-title
    >
  </mat-card-header>
  <mat-divider></mat-divider>
  <!-- <mat-card-content> -->
  <table
    mat-table
    [dataSource]="dataSource"
    style="width: 100%"
    matSort
    matSortActive="amount"
    matSortDirection="desc"
    matSortDisableClear
  >
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>
        <h4>{{ 'product' | translate }}</h4>
      </th>
      <td mat-cell *matCellDef="let element">
        <b>{{ element.object.name }}</b>
      </td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <h4>{{ 'number_sold' | translate }}</h4>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
    </ng-container>
    <ng-container matColumnDef="revenue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <h4>{{ 'revenue' | translate }}</h4>
      </th>
      <td mat-cell *matCellDef="let element">{{ currency }} {{ element.revenue }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- </mat-card-content> -->
</mat-card>
