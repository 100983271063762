import { PipeTransform, Injectable, Pipe } from '@angular/core'

@Pipe({
  name: 'orderByName'
})
@Injectable()
export class OrderByName implements PipeTransform {
  transform(array: Array<string>, args: string): Array<string> {
    if (!array || array.length === 0) {
      return null
    }

    array.sort((a: any, b: any) => {
      const str1 = a.name ? a.name.toLowerCase() : ''
      const str2 = b.name ? b.name.toLowerCase() : ''

      if (str1 < str2) {
        return -1
      } else if (str1 > str2) {
        return 1
      } else {
        return 0
      }
    })

    return array
  }
}
