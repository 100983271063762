<div class="max-w-90">
  <h5>{{ 'select' | translate }} {{ 'employee' | translate }}</h5>
  <mat-form-field>
    <mat-select #select multiple placeholder="{{ 'all_employees' | translate}}">
      <button mat-button (click)="changeSelectedEmployee(null)" class="select-all-btn">
        {{ 'all_employees' | translate }}
      </button>
      <mat-option
        *ngFor="let employee of employees"
        [value]="employee"
        (onSelectionChange)="changeSelectedEmployee($event)"
      >
        {{ employee.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
