<form>
  <mat-form-field>
    <input
      type="text"
      [placeholder]="title"
      matInput
      [readonly]="disabled"
      [formControl]="myControl"
      [matAutocomplete]="auto"
      (keydown.enter)="$event.stopPropagation()"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOptions | async | orderByName"
        [value]="option"
        (click)="selectOption(option)"
        (onSelectionChange)="selectOption(option)"
        [disabled]="disabled"
      >
        {{ option[key] }} <font *ngIf="!!key_sub">- {{ option[key_sub] }}</font>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
