import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { RequestService } from '../../services/request.service'
import { StoresService } from '../../services/stores.service'
import { Store } from './../../model/store.model'

@Component({
  selector: 'store-selection',
  templateUrl: 'store-selection.component.html'
})
export class StoreSelectionComponent implements OnInit {
  public stores: Store[]
  public selectedStore: any
  @Output()
  sendSelectedStore = new EventEmitter<{ store: Store; index: number }>()

  constructor(private request: RequestService, private storesService: StoresService) {
    this.stores = []
  }

  ngOnInit() {
    this.getStores()
  }

  getStores() {
    this.request.getResource('stores', { sort: 'name' }).then((stores: Store[]) => {
      this.stores = stores.sort(this.storesService.storesSort)
      this.selectedStore = 'all_stores'
      // this.sendSelectedStore.emit(0)
    })
  }

  changeSelectedStore(event) {
    if (event.isUserInput) {
      if (event.source.value === 'all_stores') {
        // this.selectedStore = null
        this.sendSelectedStore.emit({ store: null, index: null })
      } else {
        this.selectedStore = event.source.value
        const index = this.stores.findIndex(s => s._id === this.selectedStore._id)
        this.sendSelectedStore.emit({ store: this.selectedStore, index: index })
      }
    }
  }
}
