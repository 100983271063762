<div class="max-w-90">
  <h5>{{ 'select_store' | translate }}</h5>
  <mat-form-field>
    <mat-select #select multiple placeholder="{{ 'all_stores' | translate }}" [(value)]="selectedStore">
      <button mat-button (click)="changeSelectedStore('all_stores')" class="select-all-btn">
        {{ 'all_stores' | translate }}
      </button>
      <mat-option
        *ngFor="let store of stores"
        [value]="store"
        (onSelectionChange)="changeSelectedStore($event)"
      >
        {{ store.name }} ({{ store.currency.code }} - {{ store.currency.symbol }})
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
