<div class="payment-info-container">
  <div class="payment-info-title">
    <h1 mat-dialog-title>
      <font class="cap">{{ 'payment_details' | translate }}</font>
    </h1>
  </div>
  <mat-divider></mat-divider>
  <div
    mat-dialog-content
    class="payment-info-content"
    *ngIf="
      data.payment_details &&
      data.payment_details.method &&
      data.payment_details.method === 'creditcard' &&
      user.billing_info.payment_saved
    "
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <p>
          <b>{{ 'card_name' | translate }}</b>
        </p>
      </div>
      <div fxFlex="50">
        <p>{{ data.payment_details.cardHolder }}</p>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <p>
          <b>{{ 'card_number' | translate }}:</b>
        </p>
      </div>
      <div fxFlex="50">
        <p>**** **** **** {{ data.payment_details.cardNumber }}</p>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <p>
          <b>{{ 'paymnet_method' | translate }}</b>
        </p>
      </div>
      <div fxFlex="50">
        <p>{{ data.payment_details.cardLabel }}</p>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <p>
          <b>{{ 'code_country' | translate }}</b>
        </p>
      </div>
      <div fxFlex="50">
        <p>{{ data.payment_details.cardCountryCode }}</p>
      </div>
    </div>
  </div>
  <div
    mat-dialog-content
    class="payment-info-content"
    *ngIf="
      data.payment_details &&
      data.payment_details.method &&
      data.payment_details.method !== 'creditcard'
    "
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <p>
          <b>{{ 'name' | translate }}:</b>
        </p>
      </div>
      <div fxFlex="50">
        <p>{{ data.payment_details.consumerName }}</p>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="15px"
      *ngIf="
        data.payment_details &&
        data.payment_details.method &&
        data.payment_details.method === 'ideal'
      "
    >
      <div fxFlex="50">
        <p>
          <b>{{ 'method' | translate }}:</b>
        </p>
      </div>
      <div fxFlex="50" *ngIf="data.payment_details && data.payment_details.method">
        <p>{{ data.payment_details.method }}</p>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <p>
          <b>{{ 'account_number' | translate }}:</b>
        </p>
      </div>
      <div fxFlex="50">
        <p>
          **** **** ******{{
            data.payment_details.consumerAccount
              ? data.payment_details.consumerAccount.slice(14)
              : '----'
          }}
        </p>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="15px"
      *ngIf="
        data.payment_details &&
        data.payment_details.method &&
        data.payment_details.method !== 'ideal'
      "
    >
      <div fxFlex="50">
        <p>
          <b>{{ 'paymnet_method' | translate }}:</b>
        </p>
      </div>
      <div fxFlex="50">
        <p>{{ data.payment_details.cardLabel }}</p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <p><b>Bic:</b></p>
      </div>
      <div fxFlex="50">
        <p>{{ data.payment_details.consumerBic }}</p>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="diff-payment" *ngIf="data.payment_details && user.billing_info.payment_saved">
    <mat-checkbox (change)="changeCurringType($event)" [(ngModel)]="changePayment">{{
      'diff_way_pay_msg' | translate
    }}</mat-checkbox>
  </div>

  <div *ngIf="!data.payment_details || !user.billing_info.payment_saved">
    <div >
      <p>Not stored payment method. </p>
      <p>Click confirm to continue to the payment and select your payment method.</p>
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-content class="save-payment-content">
      <mat-checkbox (change)="changePaymentSaved($event)"
      >Do you want to save your preferred payment option for future transactions?</mat-checkbox
      >
    </div>
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div mat-dialog-actions>
      <!-- <button mat-button (click)="onNoClick()">{{ 'cancel' | translate }}</button> -->
      <button mat-button (click)="confirm()" cdkFocusInitial>
        {{ 'confirm' | translate }}
      </button>
    </div>
  </div>
</div>
