import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Addon } from '../model/addon.model'
import { AddonGroup } from '../model/addonGroup.model'
import { omit } from 'lodash'

@Injectable()
export class AddonsService {
  private addons: Addon[]
  private count: number
  private addonGroups: AddonGroup[]
  private groupsCount: number

  constructor(private request: RequestService) {}

  newAddon(): Addon {
    return {
      name: '',
      price: 0,
      cost_price: 0,
      groups: [],
      local_prices: [],
      store_availability: [],
      options: {
        sold_by_weight: false
      }
    }
  }

  fetchAddons(): void {
    this.request.getResource('addons', { limit: 500 }).then((addons: Addon[]) => {
      this.addons = addons
      this.count = addons.length
    })
    this.request.getResource('addongroups', { limit: 500 }).then((groups: AddonGroup[]) => {
      this.addonGroups = groups
      this.groupsCount = groups.length
    })
  }

  getAddons(): Addon[] {
    return Object.assign([], this.addons)
  }

  getAddonsGroups(): AddonGroup[] {
    return Object.assign([], this.addonGroups)
  }

  getAddonsCount(): number {
    return this.count
  }

  getAddonsGroupCount(): number {
    return this.groupsCount
  }

  getAddon(id: string): Addon {
    return { ...this.addons.find((addon) => addon._id === id) }
  }

  getAddonsGroup(id: string): AddonGroup {
    return { ...this.addonGroups.find((group) => group._id === id) }
  }

  setAddon(addon: Addon): Promise<Addon> {
    // Removing store name form local prices obj
    if (addon.local_prices && addon.local_prices.length) {
      addon.local_prices = addon.local_prices.map((local) => omit(local, 'storeName'))
    }

    const index = this.addons.findIndex((a) => a._id === addon._id)
    if (index >= 0) {
      return this.request.patchResource('addons/' + addon._id, addon).then((updated: Addon) => {
        this.addons[index] = updated
        return Promise.resolve(updated)
      })
    } else {
      return this.request.postResource('addons', addon).then((updated: Addon) => {
        this.addons = [...[updated], ...this.addons]
        return Promise.resolve(updated)
      })
    }
  }

  setAddonGroup(group: AddonGroup): Promise<AddonGroup> {
    const index = this.addonGroups.findIndex((a) => a._id === group._id)
    if (index >= 0) {
      return this.request.patchResource('addongroups/' + group._id, group).then((updated: AddonGroup) => {
        this.addonGroups[index] = updated
        return Promise.resolve(updated)
      })
    } else {
      return this.request.postResource('addongroups', group).then((updated: AddonGroup) => {
        this.addonGroups = [...[updated], ...this.addonGroups]
        return Promise.resolve(updated)
      })
    }
  }

  deleteAddon(id: string): Promise<Addon> {
    const index = this.addons.findIndex((t) => t._id === id)
    this.addons.splice(index, 1)
    return this.request.deleteResource('addons/' + id).then((addon: Addon) => {
      return Promise.resolve(addon)
    })
  }

  deleteAddonGroup(id: string): Promise<Addon> {
    const index = this.addonGroups.findIndex((t) => t._id === id)
    this.addonGroups.splice(index, 1)
    return this.request.deleteResource('addongroups/' + id).then((group: Addon) => {
      return Promise.resolve(group)
    })
  }
}
