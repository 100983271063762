import { Component, OnChanges, Input, ViewChild } from '@angular/core'
import { Report } from './../../model/report.model'
import { BaseChartDirective } from 'ng2-charts'
import { CompareReportService } from '../../services/compare-reports.service'

@Component({
  selector: 'report-graph',
  templateUrl: 'report-graph.component.html'
})
export class ReportGraphComponent implements OnChanges {
  @Input()
  report: Report
  @Input()
  currency: string
  public initialReportDate: string
  public finalReportDate: string
  public showGraph: boolean
  public lineChartData: Array<any>
  public lineChartLabels: Array<any>
  public lineChartOptions: any
  public lineChartColors: Array<any>
  public lineChartLegend
  public lineChartType
  @ViewChild(BaseChartDirective)
  chart: BaseChartDirective

  constructor(private compareService: CompareReportService) {
    this.showGraph = false
    this.lineChartData = []
    this.lineChartLabels = []
    this.lineChartOptions = { responsive: true }
    this.lineChartColors = [
      {
        // dark grey
        backgroundColor: 'rgba(38,218,210,0.1)',
        borderColor: '#f07452',
        pointBackgroundColor: '#f3a28d',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#f07452',
        pointHoverBorderColor: '#f76039'
      },
      {
        // dark grey
        backgroundColor: 'rgba(38,218,210,0.1)',
        borderColor: '#369944',
        pointBackgroundColor: '#51b55f',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#369944',
        pointHoverBorderColor: '#19962b'
      },
      {
        // grey
        backgroundColor: 'rgba(25,118,210,0.1)',
        borderColor: '#3696d6',
        pointBackgroundColor: 'rgba(25,118,210,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#3696d6',
        pointHoverBorderColor: 'rgba(25,118,210,0.5)'
      }
    ]
    this.lineChartLegend = true
    this.lineChartType = 'line'
  }

  ngOnChanges() {
    if (this.report) {
      this.initialReportDate = new Date(this.report.start_date).toLocaleDateString()
      this.finalReportDate = new Date(this.report.end_date).toLocaleDateString()
      if (this.report.content[this.currency]) {
        this.extractGraphFromReport()
      } else {
        this.showGraph = false
      }
    } else {
      this.showGraph = false
    }
  }

  extractGraphFromReport() {
    const reportType = this.report.content[this.currency].graphs.stores_transactions.graph_type

    if (!this.report.labels && this.report.start_date && this.report.end_date) {
      this.lineChartLabels = this.getSpecificLabels()
    } else {
      this.lineChartLabels = this.report.labels
    }
    if (reportType) {
      const transactions = this.compareService.formatOveralGraphs(
        this.report.content[this.currency].graphs.transactions,
        this.report.labels,
        reportType
      )

      const sales = this.compareService.formatOveralGraphs(
        this.report.content[this.currency].graphs.sales,
        this.report.labels,
        reportType
      )

      const refunds = this.compareService.formatOveralGraphs(
        this.report.content[this.currency].graphs.refunds,
        this.report.labels,
        reportType
      )

      this.lineChartData = [
        { label: 'refunds', data: refunds },
        { label: 'sales', data: sales },
        { label: 'transactions', data: transactions }
      ]
    }

    if (this.report.content[this.currency]) {
      this.report.content[this.currency].busiest_hours = this.report.content[
        this.currency
      ].graphs.busiest_hours

      if (!reportType) {
        this.lineChartData = []
        let grpahs = {
          refunds: this.report.content[this.currency].graphs.refunds,
          sales: this.report.content[this.currency].graphs.sales,
          transactions: this.report.content[this.currency].graphs.transactions
        }
        // Sorting keys to always have the same color in the graph
        Object.keys(grpahs)
          .sort((a, b) => (a > b ? 1 : a === b ? 0 : -1))
          .forEach((type, index) => {
            this.lineChartData.push({ data: [], label: type })
            this.lineChartColors.push({})
            this.report.content[this.currency].graphs[type].details.forEach(item => {
              if (item === null) {
                this.lineChartData[index].data.push((0).toFixed(2))
              } else {
                this.lineChartData[index].data.push(item.amount.toFixed(2))
              }
            })
          })
      }
      this.showGraph = true
      setTimeout(() => {
        this.chart.chart.data.labels = this.lineChartLabels
        this.chart.chart.update()
      }, 100)
    } else {
      this.showGraph = false
    }
  }

  getDayName(dateStr, locale = 'en') {
    let date = new Date(dateStr)
    return date.toLocaleDateString(locale, { weekday: 'short' })
  }

  getMonthName(dateStr, locale = 'en') {
    let date = new Date(dateStr)
    return date.toLocaleDateString(locale, { month: 'short' })
  }

  getExtPosition(day: number) {
    switch (day) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  getSpecificLabels() {
    let start = new Date(this.report.start_date).getDate()
    let date = JSON.parse(JSON.stringify(this.report.start_date))
    let end = new Date(this.report.end_date).getDate()
    let labels = []

    while (start <= end) {
      let d = new Date(date)
      labels.push(`${d.getDate()}${this.getExtPosition(d.getDate())}, ${this.getDayName(date)}`)
      date = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1)
      start++
    }

    return labels
  }

  // events
  public chartClicked(e: any): void {
    // console.log(e);
  }

  public chartHovered(e: any): void {
    // console.log(e);
  }
}
