import { Injectable } from '@angular/core'
import { RequestService } from './request.service'
import { Customer } from './../model/customer.model'

@Injectable()
export class CustomersService {
  private customers: Customer[]
  private count: number
  private allCustomersCount: any

  constructor(private request: RequestService) {}

  fetchCustomers(): void {
    this.request.getResource('customers', { limit: 100 }).then((customers: Customer[]) => {
      this.customers = customers
      this.count = customers.length
    })
  }

  getCustomersCount(): Promise<number> {
    return this.request.getResource('customers/count').then((count: number) => {
      this.allCustomersCount = count
      return count
    })
  }

  getCustomers(): Customer[] {
    return Object.assign([], this.customers)
  }

  getAllCustomersCount(): number {
    return this.allCustomersCount
  }

  async getCustomer(id: string) {
    return this.request.getResource('customers/' + id)
  }

  setCustomer(customer: Customer): Promise<Customer> {
     // let index = this.customers.findIndex(emp => emp._id === customer._id)
     if (customer && customer._id) {
      return this.request
        .patchResource('customers/' + customer._id, customer)
        .then((updated: Customer) => {
          // this.customers[index] = updated
          return Promise.resolve(updated)
        })
    } else {
      return this.request.postResource('customers', customer).then((updated: Customer) => {
        this.customers = [...[updated], ...this.customers]
        return Promise.resolve(updated)
      })
    }
  }

  deleteCustomer(id: string): Promise<Customer> {
    const index = this.customers.findIndex(dv => dv._id === id)
    this.customers.splice(index, 1)
    return this.request.deleteResource('customers/' + id).then((customer: Customer) => {
      return Promise.resolve(customer)
    })
  }

  getExportCustomerHeader() {
    return [
      'First Name',
      'Last Name',
      'Email',
      'Phone',
      'Birth Date',
      'Permission to Contact',
      'Identifiers',
      'Last Visit',
      'Total Visits',
      'Total Spend',
      'Billing Address',
      'Address Number',
      'Billing City',
      'Billing Zip',
      'Billing State',
      'Billing Country',
      'Shipping Address',
      'Address Number',
      'Shipping City',
      'Shipping Zip',
      'Shipping State',
      'Shipping Country'
    ]
  }

  getExportCustomerObj(data) {
    const download = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        birthDate: '',
        mailingList: '',
        identifiers: '',
        lastVisit: '',
        totalVisit: '',
        totalSpend: '',
        billingAddress: '',
        billingNumber: '',
        billingCity: '',
        billingZip: '',
        billingState: '',
        billingCountry: '',
        shippingAddress: '',
        shippingNumber: '',
        shippingCity: '',
        shippingZip: '',
        shippingState: '',
        shippingCountry: ''
      }

      download.firstName = data.first_name ? data.first_name : '-'
      download.lastName = data.last_name ? data.last_name : '-'
      download.email = data.email
      const phone = data.phone ? data.phone : ' - '
      download.phone = phone
      download.birthDate = data.birth_date ? new Date(data.birth_date).toLocaleDateString() : ' - '
      download.mailingList = data.mailing_list ? data.mailing_list : false
      download.identifiers =
        data.options.identifiers && data.options.identifiers.length
          ? data.options.identifiers[0].code_type +
            ' ' +
            '-' +
            ' ' +
            data.options.identifiers[0].code_value
          : ''
      download.lastVisit =
        data && data.last_visit ? new Date(data.last_visit).toLocaleDateString() : ' '
      download.totalVisit = data.total_visits ? data.total_visits : ' - '
      download.totalSpend = data.total_spend ? data.total_spend : ' - '
      download.billingAddress =
        data.billing && data.billing.address1 ? data.billing.address1 : ' - '
      const billingNumber = data.billing && data.billing.number ? data.billing.number : ' - '
      download.billingNumber = billingNumber
      download.billingCity = data.billing && data.billing.city ? data.billing.city : ' - '
      download.billingZip = data.billing && data.billing.zip ? data.billing.zip : ' - '
      download.billingState = data.billing && data.billing.state ? data.billing.state : ' - '
      download.billingCountry = data.billing && data.billing.country ? data.billing.country : ' - '
      download.shippingAddress =
        data.shipping && data.shipping && data.shipping.address1 ? data.shipping.address1 : ' - '
      const shippingNumber = data.shipping && data.shipping.number ? data.shipping.number : ' - '
      download.shippingNumber = shippingNumber
      download.shippingZip = data.shipping && data.shipping.zip ? data.shipping.zip : ' - '
      download.shippingState = data.shipping && data.shipping.state ? data.shipping.state : ' - '
      download.shippingCountry =
        data.shipping && data.shipping.country ? data.shipping.country : ' - '

      return download
  }
}
