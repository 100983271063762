<mat-card>
  <mat-card-header>
    <mat-card-title
      ><i class="material-icons"> trending_up </i> {{ 'by_volume' | translate }} ( Top 10 )</mat-card-title
    >
  </mat-card-header>
  <mat-divider></mat-divider>
  <!-- <mat-card-content> -->
  <table
    mat-table
    [dataSource]="dataSource"
    style="width: 100%"
    matSort
    matSortActive="amount"
    matSortDirection="desc"
    matSortDisableClear
  >
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>
        <h4>{{ 'product' | translate }}</h4>
      </th>
      <td mat-cell *matCellDef="let element">
        <b>{{ element.object.name }}</b>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <h4>{{ 'number_sold' | translate }}</h4>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
    </ng-container>

    <ng-container matColumnDef="revenue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <h4>{{ 'revenue' | translate }}</h4>
      </th>
      <td mat-cell *matCellDef="let element">{{ currency }} {{ element.revenue }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- </mat-card-content> -->
</mat-card>
