import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(input: any[], field: string, separator: string = ' '): any {
    return [...input].map(item => item[field]).join(separator)
  }
}
