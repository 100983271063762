<div class="select-subscription-container" *ngIf="!isRequested">
    <div class="subs-title">
      <!-- <h4>{{ 'subs_to_ext' | translate }}</h4> -->
      <h4 style="font-size: 25px; margin: 12px;">Go live</h4>
    </div>
    <mat-divider></mat-divider>
      <div class="subs-content" >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <div fxFlex="100">
              <p style="font-size: 18px">By clicking on Proceed I confirm that I would like to Go Live with my account and agree to be invoiced (based on pricing <a href="http://countrhq.com/pricing" target="_blank">  countrhq.com/pricing </a>) for my transactions each month.</p>
          </div>
        </div>
        <mat-divider></mat-divider>
    
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
          <div mat-dialog-actions>
            <button mat-button (click)="proceed()">{{ 'proceed' | translate }}</button>
          </div>
        </div>
      </div>
  </div>

  <div *ngIf="isRequested">
    <div class="subs-content" >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <div fxFlex="100">
          <div class="img-center" fxLayoutAlign="center">
            <img src="./../../../../assets/images/success.png" alt="item-img" #img style="width: 15%"/>
          </div>
            <p style="font-size: 18px">Thank you for wanting to go live with <b>Countr</b>! We received your request to <b>Go Live</b>, we will be back to you within 24 hours.</p>
        </div>
      </div>
      <mat-divider></mat-divider>
  
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <div mat-dialog-actions>
          <button mat-button (click)="onNoClick()">{{ 'close' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
  