<mat-card>
  <mat-card-header>
    <mat-card-title class="cap">
      {{ 'report_graph' | translate }}
      <span *ngIf="!showGraph"> - {{ 'no_reports_found_for_this_period' | translate }}</span>
    </mat-card-title>
    <mat-card-subtitle *ngIf="showGraph"
      >{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-content *ngIf="showGraph">
    <canvas
      baseChart
      height="130"
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      [colors]="lineChartColors"
      [legend]="lineChartLegend"
      [chartType]="lineChartType"
      (chartHover)="chartHovered($event)"
      (chartClick)="chartClicked($event)"
    >
    </canvas>
  </mat-card-content>
</mat-card>
