import { Component, AfterViewInit, OnChanges, Input, ViewChild } from '@angular/core'
import { Report } from '../../model/report.model'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { DataSource } from '@angular/cdk/table'
import { ProductsService } from '../../services/products.service'

@Component({
  selector: 'app-bottom-products-sellers',
  templateUrl: './bottom-products-sellers.component.html',
  styleUrls: ['./bottom-products-sellers.component.css']
})
export class BottomProductsSellersComponent implements OnChanges, AfterViewInit {
  @Input()
  report: Report
  @Input()
  currency: string
  @ViewChild(MatSort) sort: MatSort
  private lowProducts: any = []

  dataSource: MatTableDataSource<any>
  public displayedColumns = ['product', 'amount', 'revenue']

  constructor(private productService: ProductsService) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort
  }

  ngOnChanges() {
    this.bottomList()
  }

  bottomList() {
    if (this.report && this.report.content) {
      let report = this.report
      let currency = this.currency
      let bottomList = []
      if (report.content && report.content[currency]) {
        let bottomList_details = ((report.content[currency].bottomlists || {}).products || {})
          .details
        for (var key in bottomList_details) {
          if (bottomList_details.hasOwnProperty(key)) {
            bottomList.push(bottomList_details[key])
          }
        }
        bottomList = bottomList.sort(function sort(a, b) {
          return b.amount < a.amount ? 1 : b.amount > a.amount ? -1 : 0
        })
        bottomList = bottomList.slice(0, 10)
        bottomList.forEach((item, index) => {
          bottomList[index].revenue = bottomList[index].total.toFixed(2)
        })
        this.lowProducts = bottomList
        this.dataSource = new MatTableDataSource(this.lowProducts)
      }
    }
  }
}
