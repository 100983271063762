<mat-card>
  <mat-card-header>
    <mat-card-title class="cap">{{ title | translate }}</mat-card-title>
    <mat-card-subtitle>{{ initialReportDate }} - {{ finalReportDate }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content *ngIf="transactionMethods.length">
    <!-- <p *ngFor="let transaction of transactionMethods">
      <b>{{ transaction.method | translate }}: </b>{{ transaction.amount | currency: currency }}
    </p> -->
    <mat-table
      #table
      [dataSource]="dataTransactionMethods"
      #methods="matSort"
      matSort
      matSortActive="number"
      matSortDirection="desc"
      matSortDisableClear
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'">{{ 'method' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let data" [ngClass]="'customWidthClass'">
          <h5 class="m-0">{{ data.name }}</h5>
        </mat-cell>

        <mat-footer-cell *matFooterCellDef>
          <h5 class="m-0 cap">
            <b>{{ 'total' | translate }}:</b>
          </h5>
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="number">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'number' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let data">
          <h5 class="m-0">{{ data.number }}</h5>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <h5 class="m-0 cap">
            <b>{{ handleTotale('number') }}</b>
          </h5>
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'amount' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let data">
          <h5 class="m-0">{{ data.amount | currency: currency }}</h5>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <h5 class="m-0 cap">
            <b>{{ handleTotale('amount') | currency: currency }}</b>
          </h5>
        </mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsReports"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsReports"></mat-row>
      <mat-footer-row *matFooterRowDef="displayedColumnsReports"></mat-footer-row>
    </mat-table>
  </mat-card-content>

  <mat-card-content *ngIf="!transactionMethods.length">
    <span>{{ 'no_transactions_in_this_period' | translate }}</span>
  </mat-card-content>
</mat-card>
