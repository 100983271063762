<div [ngClass]="{ dark: isDarkTheme }">
  <h1 mat-dialog-title>{{ data.title | translate }}</h1>
  <div mat-dialog-content>
    <p>{{ data.message | translate }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="warn" (click)="cancel()">{{ 'cancel' | translate }}</button>
    <button mat-button color="primary" (click)="confirm()">{{ 'ok' | translate }}</button>
  </div>
</div>
