import { Routes } from '@angular/router'
import { FullComponent } from './layouts/full/full.component'
import { AltUserGuard } from './auth/altUser.guard'

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/auth',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'overview',
        loadChildren: () => import('./overview/overview.module').then(m => m.OverviewModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'company',
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
      },
      {
        path: 'assortment',
        loadChildren: () => import('./assortment/assortment.module').then(m => m.AssortmentModule)
      },
      {
        path: 'sales-history',
        loadChildren: () =>
          import('./sales-history/sales-history.module').then(m => m.SalesHistoryModule)
      },
      {
        path: 'changes-history',
        loadChildren: () =>
          import('./changes-history/changes-history.module').then(m => m.ChangesHistoryModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
        // canActivate: [AltUserGuard]
      },
      {
        path: 'customer-groups',
        loadChildren: () =>
          import('./customer-groups/customer-groups.module').then(m => m.CustomerGroupsModule)
        // canActivate: [AltUserGuard]
      },
      {
        path: 'employees',
        loadChildren: () => import('./employees/employees.module').then(m => m.EmployeesModule)
        // canActivate: [AltUserGuard]
      },
      {
        path: 'table-layout',
        loadChildren: () =>
          import('./table-layout/table-layout.module').then(m => m.TableLayoutModule)
        // canActivate: [AltUserGuard]
      },
      {
        path: 'extensions',
        loadChildren: () => import('./extensions/extensions.module').then(m => m.ExtensionsModule),
        canActivate: [AltUserGuard]
      },
      {
        path: 'helpdesk',
        loadChildren: () => import('./helpdesk/helpdesk.module').then(m => m.HelpdeskModule),
        canActivate: [AltUserGuard]
      },
      {
        path: 'desktop',
        loadChildren: () => import('./desktop/desktop.module').then(m => m.DesktopModule),
        canActivate: [AltUserGuard]
      },
      {
        path: 'vouchers',
        loadChildren: () => import('./vouchers/vouchers.module').then(m => m.VouchersModule)
        // canActivate: [AltUserGuard]
      },
      {
        path: 'giftcards',
        loadChildren: () => import('./giftcards/giftcards.module').then(m => m.GiftcardsModule),
        canActivate: [AltUserGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AltUserGuard]
      },
      {
        path: 'delivery-notes',
        loadChildren: () => import('./delivery-notes/delivery-notes.module').then(m => m.DeliveryNotesModule)
      },
    ]
  }
]
