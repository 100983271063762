<div class="business-info-container">
  <div class="info-title">
    <h4>{{ 'enter_bus_info' | translate }}</h4>
  </div>
  <mat-divider></mat-divider>
  <div class="info-content">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="100">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'organization' | translate }}*"
            [(ngModel)]="data.user.billing_info.organization"
          />
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'company_registration_no' | translate }}"
            [(ngModel)]="data.user.billing_info.registration"
            required
          />
        </mat-form-field>
      </div>
      <div fxFlex="50" *ngIf="isEu">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'vat_btw_no' | translate }} - e.g. VAT [NL000000000B00]"
            [(ngModel)]="data.user.billing_info.vat"
            required
          />
          <span *ngIf="!checkingVat(data.user.billing_info.vat)" class="error-msg">{{
            'invalid_eu' | translate
          }}</span>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'address' | translate }} 1"
            [(ngModel)]="data.user.billing_info.address1"
            required
          />
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'zip_code' | translate }}"
            [(ngModel)]="data.user.billing_info.zip"
            required
          />
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <div fxFlex="50">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'city' | translate }}"
            [(ngModel)]="data.user.billing_info.city"
            required
          />
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field>
          <mat-select placeholder="{{ 'country' | translate }}" required>
            <mat-option
              *ngFor="let country of countriesStore"
              (onSelectionChange)="checkCountry($event)"
              [value]="country.code"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="error-message" *ngIf="showErrorMessage">
    <p>{{ 'mandatory_fields' | translate }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{ 'cancel' | translate }}</button>
    <button
      mat-button
      (click)="saving(data.user)"
      *ngIf="checkingVat(data.user.billing_info.vat) || !isEu"
      cdkFocusInitial
    >
      {{ 'save' | translate }}
    </button>
    <button
      mat-button
      (click)="saving(data.user)"
      *ngIf="!checkingVat(data.user.billing_info.vat) && isEu"
      disabled
      cdkFocusInitial
    >
      {{ 'save' | translate }}
    </button>
  </div>
</div>
