<mat-card>
  <mat-card-content>
    <h3 class="m-t-0" fxLayout="row" fxLayoutAlign="space-between end">
      <span>Sales comparison</span>
      <span class="font-10 text-right">{{ initial }} - {{ final }}</span>
    </h3>
    <h6 style="position: absolute; top: 30px;" class="font-10 text-muted">
      Income difference between dates
    </h6>
    <div class="text-right">
      <h5 class="text-muted m-0 cap">{{ period }} Income Diff</h5>
      <h2 class="m-t-0">
        <sup>
          <i class="ti-arrow-up text-info" *ngIf="!error && diff >= 100"></i>
          <i class="ti-arrow-down text-danger" *ngIf="!error && diff < 100"></i>
        </sup>
        <span *ngIf="!error">{{ diffIncome | currency: currency }}</span>
        <span *ngIf="!error" class="text-muted font-12"
          >({{ diff >= 100 ? diff : 100 - diff }}%)</span
        >
        <span *ngIf="error">{{ error }}</span>
      </h2>
    </div>
    <mat-progress-bar mode="determinate" [value]="diff"></mat-progress-bar>
  </mat-card-content>
</mat-card>
