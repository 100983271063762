<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="!bottom">
      {{ 'top_sales' | translate }}
      <span *ngIf="!showGraph"> - {{ 'no_top_sales_for_this_period' | translate }}</span>
    </mat-card-title>
    <mat-card-title *ngIf="bottom">
      {{ 'bottom_sellers' | translate }}
      <span *ngIf="!showGraph"> - {{ 'no_top_sales_for_this_period' | translate }}</span>
    </mat-card-title>
    <mat-card-subtitle>5 {{ 'best_selling_products_for_this_period' | translate }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="p-t-0-i" *ngIf="showGraph">
    <canvas
      baseChart
      height="200px"
      [data]="pieChartData"
      [labels]="pieChartLabels"
      chartType="pie"
      (chartHover)="chartHovered($event)"
      (chartClick)="chartClicked($event)"
      [colors]="pieChartBackground"
      [options]="pieChartOptions"
    >
    </canvas>
  </mat-card-content>
</mat-card>
<!-- <product-tile [productTile]="productTile" [color]="productTile.categoryColor" *ngIf="showProductTile"></product-tile> -->
