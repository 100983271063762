import * as $ from 'jquery'
// import 'angular2-navigate-with-data'
import { BrowserModule, Title } from '@angular/platform-browser'
import { NgModule, APP_INITIALIZER } from '@angular/core'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { LocationStrategy, HashLocationStrategy } from '@angular/common'

import { AppRoutes } from './app.routing'

import { AppComponent } from './app.component'

import { FlexLayoutModule } from '@angular/flex-layout'
import { FullComponent } from './layouts/full/full.component'
import { AppHeaderComponent } from './layouts/full/header/header.component'
import { PopUpBox } from './layouts/full/pop-up-box/pop-up-box.component'
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MaterialModule } from './material-module'
import { AuthGuard } from './auth/auth.guard'
import { AltUserGuard } from './auth/altUser.guard'
import { LoadingGuard } from './shared/loading.guard'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
// import { RecaptchaModule } from 'ng-recaptcha'

import { SharedModule } from './shared/shared.module'
import { SpinnerComponent } from './shared/spinner.component'
import { AuthService } from './services/auth.service'
import { RequestService } from './services/request.service'
import { DataService } from './services/data.service'
import { SettingsService } from './services/settings.service'
import { RouterService } from './services/router.service'
import { UtilsService } from './services/utils.service'
import { CategoriesService } from './services/categories.service'
import { TaxesService } from './services/taxes.service'
import { CurrenciesService } from './services/currencies.service'
import { ProductsService } from './services/products.service'
import { StoresService } from './services/stores.service'
import { DevicesService } from './services/devices.service'
import { CompareReportService } from './services/compare-reports.service'
import { EmployeesService } from './services/employees.service'
import { ExtensionsService } from './services/extensions.service'
import { InvoiceService } from './services/invoices.service'
import { AddonsService } from './services/addons.service'
import { AccessRightsService } from './services/access_rights.service'
import { EventService } from './services/event.service'
import { CustomersService } from './services/customers.service'
import { CustomerGroupsService } from './services/customer-groups.service'
import { ReportsService } from './services/reports.service'
import { TranslateService } from './services/translate.service'
import { CartsService } from './services/carts.service'
import { MetadataService } from './services/metadata.service'
import { VouchersService } from './services/vouchers.service'
import { GiftcardService } from './services/giftcards.service'
import { CountrSDKService } from './services/countr-sdk.service'
import { GoLiveComponent } from './layouts/full/go-live/go-live.component'
import { BusinessInformationModalComponent } from './layouts/full/go-live/business-information-modal/business-information-modal.component'
import { SelectSubscriptionModalComponent } from './layouts/full/go-live/select-subscription-modal/select-subscription-modal.component'
import { GoLiveSalesModalComponent } from './layouts/full/go-live/go-live-sales-modal/go-live-sales-modal.component'
import { GoLivePreAuthModalComponent } from './layouts/full/go-live/go-live-pre-auth/go-live-pre-auth-modal.component'
import { SubscriptionService } from './services/subscriptions.service'
import { GoToPaymentComponent } from './layouts/full/go-live/go-to-payment/go-to-payment.component'
import { httpInterceptorProviders } from './http-interceptors'

export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use('en')
}

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    PopUpBox,
    SpinnerComponent,
    AppSidebarComponent,
    GoLiveComponent,
    BusinessInformationModalComponent,
    SelectSubscriptionModalComponent,
    GoLiveSalesModalComponent,
    GoLivePreAuthModalComponent,
    GoToPaymentComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule.forRoot(AppRoutes)
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    Title,
    AuthService,
    AuthGuard,
    AltUserGuard,
    LoadingGuard,
    RequestService,
    DataService,
    SettingsService,
    RouterService,
    ReportsService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
    UtilsService,
    ProductsService,
    CategoriesService,
    TaxesService,
    CurrenciesService,
    StoresService,
    DevicesService,
    EmployeesService,
    CompareReportService,
    ExtensionsService,
    InvoiceService,
    SubscriptionService,
    AddonsService,
    AccessRightsService,
    EventService,
    CustomersService,
    CustomerGroupsService,
    CartsService,
    MetadataService,
    VouchersService,
    GiftcardService,
    CountrSDKService,
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    BusinessInformationModalComponent,
    SelectSubscriptionModalComponent,
    GoLiveSalesModalComponent,
    GoLivePreAuthModalComponent,
    GoToPaymentComponent
  ]
})
export class AppModule {}
