<div class="product-tile" [ngStyle]="{ 'background-color': color, 'border-color': color }">
  <img
    class="product-tile-img"
    src="{{ productTile?.image }}"
    width="96"
    height="70"
    #img
    (error)="img.src = errorImg"
  />
  <span class="product-tile-name">{{ productTile?.name }}</span>
</div>
