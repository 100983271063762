import { Component, OnInit, Inject } from '@angular/core'
import { RequestService } from '../../../../services/request.service'
import { ExtensionsService } from '../../../../services/extensions.service'
import { AuthService } from '../../../../services/auth.service'
import { GoToPaymentComponent } from '../go-to-payment/go-to-payment.component'

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-subscription-modal',
  templateUrl: './select-subscription-modal.component.html',
  styleUrls: ['./select-subscription-modal.component.css']
})
export class SelectSubscriptionModalComponent implements OnInit {
  public selectExtension: any[]
  public packageSelection: any[]
  public selectedPackage: any
  public selectedPeriod: string
  private selectedExtension: any
  public totalPrice: any[]
  private yearNoDiscount: any[]
  public payment_saved: boolean
  private allSubs: any
  public disableCheckBox: boolean
  public hasAnnualPrice: boolean

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private request: RequestService,
    private extensionsService: ExtensionsService,
    public dialogRef: MatDialogRef<SelectSubscriptionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.payment_saved = true
    this.selectedExtension = []
    this.totalPrice = []
    this.yearNoDiscount = []
    this.selectExtension = this.extensionsService.getExtensions()
    this.packageSelection = this.extensionsService.getAllExtensions()
    this.selectedPeriod = 'month'
    this.disableCheckBox = false
    this.hasAnnualPrice = true
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close()
  }
  extensionSelected(event) {
    if (event.isUserInput) {
      if (this.totalPrice.length && this.selectedPackage) {
        const index = this.totalPrice.indexOf(this.selectedPackage.price[this.selectedPeriod])
        this.totalPrice[index] = event.source.value.price[this.selectedPeriod]
        const indexTotalYear = this.yearNoDiscount.indexOf(this.selectedPackage.price['month'] * 12)
        this.yearNoDiscount[indexTotalYear] = event.source.value.price['month'] * 12
        this.selectedPackage = event.source.value
      } else {
        this.selectedPackage = event.source.value
        this.totalPrice.push(event.source.value.price[this.selectedPeriod])
        this.yearNoDiscount.push(event.source.value.price['month'] * 12)
      }
      this.totalYearPrice()
      this.checkTags()
      this.checkHasAnnualPrice()
      this.checkPeriodPackage()
    }
  }
 
  checkTags() {
    this.disableCheckBox = false
    if(this.selectedPackage.tags && this.selectedPackage.tags.length) {
      this.selectedPackage.tags.forEach(tag => {
        if(tag === 'forcePaymentSave') {
          this.disableCheckBox = true
        }
      })
    }
  }
  checkHasAnnualPrice() {
    this.hasAnnualPrice = true
    if(this.selectedPackage && this.selectedPackage.price) {
      if(!this.selectedPackage.price.hasOwnProperty('year')) {
        this.hasAnnualPrice = false
      }
    }
  }
  checked(sub, event) {
    if (event.checked) {
      this.selectedExtension.push(sub)
      this.totalPrice.push(sub.price[this.selectedPeriod])
      this.yearNoDiscount.push(sub.price['month'] * 12)
    } else {
      const index = this.selectedExtension.indexOf(sub)
      this.selectedExtension.splice(index, 1)
      const indexTotalPrice = this.totalPrice.indexOf(sub.price[this.selectedPeriod])
      this.totalPrice.splice(indexTotalPrice, 1)
      const indexTotalYear = this.yearNoDiscount.indexOf(sub.price['month'] * 12)
      this.yearNoDiscount.splice(indexTotalYear, 1)
    }
  }

  selectPeriod(event) {
    if (this.selectedPackage) {
      const index = this.totalPrice.indexOf(this.selectedPackage.price[this.selectedPeriod])
      this.totalPrice[index] = this.selectedPackage.price[event.value]
    }
    if (this.selectedExtension) {
      this.selectedExtension.map(ext => {
        const index = this.totalPrice.indexOf(ext.price[this.selectedPeriod])
        this.totalPrice[index] = ext.price[event.value]
      })
    }
    this.selectedPeriod = event.value
  }

  reduceTotal() {
    if (this.totalPrice.length) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue
      const price = this.totalPrice.reduce(reducer)
      return price
    }
  }

  totalYearPrice() {
    if (this.yearNoDiscount.length) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue
      const price = this.yearNoDiscount.reduce(reducer)
      return price
    }
  }

  checkPeriodPackage() {
    if (this.selectedPackage.slug === 'live-advanced-transaction' && this.selectedPeriod === 'year') {
      this.selectedPeriod = 'month'
    }
    if(this.selectedPackage.slug === 'live-advanced-transaction' && !this.payment_saved) {
      this.payment_saved = true
    }
  }

  goLive() {
    const extensions = this.selectedExtension
    extensions.map(ext => {
      ext.price = ext.price[this.selectedPeriod]
    })
    const getPrice = this.selectedPackage.price[this.selectedPeriod]
    this.request
      .postResource('me/live', {
        live: {
          period: this.selectedPeriod,
          ...this.selectedPackage,
          price: 0.01
        },
        extensions
      })
      .then(
        updated => {
          this.data.getPackage = true
          let invoiceId = ''
          this.allSubs = updated
          this.allSubs.map(subscription => {
            if (subscription.type === 'system') {
              invoiceId = subscription.invoices.pop()
            }
          })
          if (this.payment_saved === true) {
            this.authService.setPaymentSaved(this.payment_saved)
          }
          this.dialogRef.close(this.data)
          const dialogRefPayment = this.dialog.open(GoToPaymentComponent, {
            width: '70%',
            data: { invoice: invoiceId, payment_saved: this.payment_saved, package: this.selectedPackage,
               extensions: this.selectedExtension}
          })
        },
        error => {
          console.log(error)
        }
      )
  }
}
